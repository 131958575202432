/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: 'চুক্তি',
		subTitle: 'লাইসেন্স চুক্তি এবং গোপনীয়তা নীতি',
		textFirst: 'আমি শর্ত গ্রহণ করি',
		textSecond: 'এবং এর শর্ত অনুসারে ব্যক্তিগত তথ্য প্রক্রিয়া করার আমার সম্মতি দিচ্ছি,',
		license: 'লাইসেন্স চুক্তি',
		policy: 'গোপনীয়তা নীতি',
	},
	controlPanel: {
		streamEnded: 'স্ট্রীম শেষ হয়েছে',
		banAlert: 'আপনি চ্যাটে লেখা করতে পারবেন না',
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? 'স্টিকার' : 'ইমোজি',
	},
	accountDeletedScreen: {
		title: 'অ্যাকাউন্ট মুছে ফেলা হয়েছে',
		text: (date: string) => `আপনি ${date} পর্যন্ত আপনার অ্যাকাউন্ট পুনরুদ্ধার করতে পারেন`,
	},
	nameScreen: {
		title: 'চ্যাটে নাম',
		inputPlaceholder: 'নাম লিখুন',
		nameError: 'অনুমোদিত নাম নেই',
	},
	avatarScreen: {
		title: 'অবতার',
		label: 'অন্য নির্বাচন করুন:',
	},
	settingsScreen: {
		title: 'সেটিংস',
		personal: 'ব্যক্তিগত সেটিংস',
		room: 'রুম সেটিংস',
		profileBtn: 'প্রোফাইল',
		languageBtn: 'ভাষা',
		blockedBtn: 'ব্লক করা',
		rules: 'চ্যাট নিয়মাবলী',
		deleteProfile: 'প্রোফাইল মুছুন',
		slowModeBtn: 'মন্দ মোড',
		agora: {
			microphone: 'মাইক্রোফোন',
			playbackDevice: 'শ্রবণ ডিভাইস',
		},
	},
	languageScreen: {
		title: 'ভাষা এবং ইন্টারফেস',
		messagesTranslate: 'বার্তা অনুবাদ',
		translateMessagesMode: 'চ্যাটে বার্তা অনুবাদ করুন',
		translateMessagesTo: 'মেসেজ অনুবাদ করুন:',
	},
	rulesScreen: {
		title: 'চ্যাট নিয়মাবলী',
	},
	profileScreen: {
		title: 'প্রোফাইল',
		selectAnotherPic: 'অন্য অবতার নির্বাচন করুন',
		chatNameLabel: 'চ্যাটে নাম',
		changeName: 'পরিবর্তন করুন',
	},
	blockedScreen: {
		title: 'ব্লক করা',
		emptyListText: 'কোনো ব্লক করা ব্যবহারকারী নেই',
	},
	chatScreen: {
		inputPlaceholder: 'বার্তা...',
		cantWrite: 'আপনি চ্যাটে লিখতে পারবেন না',
		chatSubmenu: {
			hideMessage: 'বার্তা লুকান',
			showMessage: 'বার্তা দেখান',
			removeFromSpeakers: 'স্পিকার থেকে সরান',
			setSpeaker: 'স্পিকার হিসেবে সেট করুন',
			hideOnlyMessage: 'শুধুমাত্র বার্তা লুকান',
			showOnlyMessage: 'শুধুমাত্র বার্তা দেখান',
			reportMessage: 'বার্তার প্রতিবেদন করুন',
			reportUser: 'ব্যবহারকারীর প্রতিবেদন করুন',
			muteSpeaker: 'মাইক্রোফোন বন্ধ করুন',
			blockUser: 'ব্যবহারকারী ব্লক করুন',
			unblockUser: 'ব্যবহারকারীর ব্লক বাতিল করুন',
			reply: 'উত্তর দিন',
			copy: 'অনুলিপি',
			edit: 'সম্পাদনা করুন',
			delete: 'মুছে ফেলুন',
			pinMessage: 'পিন করুন',
			unpinMessage: 'পিন খুলুন',
			goToProfile: 'ব্যবহারকারীর প্রোফাইলে যান',
			replyInThread: 'থ্রেডে উত্তর দিন',
		},
		actionMessage: {
			editing: 'সম্পাদনা',
			reply: 'উত্তর দিন',
		},
		status: {
			top: 'শীর্ষ',
		},
		translating: 'অনুবাদ...',
	},
	chatUsersScreen: {
		title: 'সদস্য',
		edited: 'সম্পাদিত',
		hidden: 'লুকানো',
		unreadMessages: 'অপঠিত বার্তা',
		usersSubmenu: {
			muteSpeaker: {
				title: 'স্পিকার শুনতে অসাধারণ',
				subtitle: 'মাইক্রোফোন বন্ধ করুন',
			},
			setSpeaker: {
				title: 'স্পিকার থেকে সরান',
				subtitle: 'শুধুমাত্র টেক্সট চ্যাট',
			},
			removeFromSpeaker: {
				title: 'স্পিকার হিসেবে সেট করুন',
				subtitle: 'ব্যবহারকারী ধ্বনিতে কথা বলতে পারবে',
			},
			showMessages: {
				title: 'বার্তা দেখান',
				subtitle: 'শুধুমাত্র অ্যাডমিন দেখতে পারবেন',
			},
			hideMessages: {
				title: 'বার্তা লুকান',
				subtitle: 'শুধুমাত্র অ্যাডমিন দেখতে পারবেন',
			},
			blockUser: {
				title: 'ব্যবহারকারী ব্লক করুন',
				subtitle: 'কালোতর তালিকায় যোগ করুন',
			},
			unblockUser: {
				title: 'ব্যবহারকারীর ব্লক বাতিল করুন',
				subtitle: 'ব্যবহারকারী ব্লক বাতিল করুন',
			},
			blockUserByUser: {
				title: 'ব্যবহারকারী ব্লক করুন',
				subtitle: 'ব্যবহারকারীর সমস্ত বার্তা লুকান',
			},
			unblockUserByUser: {
				title: 'ব্যবহারকারী ব্লক বাতিল করুন',
				subtitle: 'ব্যবহারকারীর সমস্ত বার্তা প্রদর্শন করুন',
			},
			reportUser: {
				title: 'ব্যবহারকারীর উপর রিপোর্ট করুন',
				subtitle: 'রিপোর্ট গোপনীয়ভাবে পাঠানো হবে',
			},
		},
		days: {
			today: 'আজ',
			yesterday: 'গতকাল',
		},
		chatCreated: 'চ্যাট তৈরি করা হয়েছে,',
	},
	alerts: {
		btns: {
			unblock: 'আনব্লক',
			block: 'ব্লক',
			send: 'প্রেরণ করুন',
			sendAndBlock: 'প্রেরণ করুন এবং ব্লক করুন',
			show: 'দেখান',
			showAll: 'সব দেখুন',
			cancel: 'বাতিল করুন',
			yes: 'হ্যাঁ',
			hide: 'লুকান',
			hideAll: 'সব লুকান',
			make: 'করুন',
			remove: 'অপসারণ',
			delete: 'মুছে ফেলুন',
			deleteAccount: 'অ্যাকাউন্ট মুছুন',
			close: 'বন্ধ করুন',
			pin: 'পিন করুন',
			unpin: 'পিন খুলুন',
			edit: 'সম্পাদনা করুন',
			end: 'শেষ',
			endAndShare: 'শেষ করুন এবং ভাগাভাগি করুন',
			reset: 'রিসেট',
			rules: 'চ্যাট নিয়মাবলী',
			contactus: 'আমাদের সাথে যোগাযোগ করুন',
		},
		pinMessage: {
			title: 'বার্তা পিন করতে চান?',
		},
		unPinMessage: {
			title: 'বার্তা পিন খুলতে চান?',
		},
		accountRestored: {
			title: 'অ্যাকাউন্ট পুনরুদ্ধার করা হয়েছে',
		},
		closeApp: {
			title: 'আপনি কি সত্যিই বাহিরে যেতে চান?',
		},
		removeMessage: {
			title: 'বার্তা মুছে ফেলতে চান?',
		},
		report: {
			title: 'অভিযোগ প্রেরণ করতে চান?',
			subtitle:
				'আপনি এছাড়াও ব্যবহারকারীকে ব্লক করতে পারেন যাতে আপনি একে অন্যকে মেসেজ দেখতে না পারেন।',
		},
		banUser: {
			title: (name: string) => `${name}কে বন্ধ করুন?`,
			subtitle: (text: string) => `ব্যবহারকারীকে ${text} জন্য বন্ধ করা হবে`,
		},
		unBanUser: {
			title: (name: string) => `${name}কে আনব্লক করুন?`,
		},
		showUserMessages: {
			title: (name: string) => `${name}এর মেসেজ দেখান?`,
			subTitle: 'অন্য ব্যবহারকারীরা এই ব্যবহারকারীর মেসেজ দেখতে পারবেন',
		},
		hideUserMessages: {
			title: (name: string) => `${name}এর মেসেজ লুকান?`,
			subTitle: 'অন্য ব্যবহারকারীরা আর এই ব্যবহারকারীর মেসেজ দেখতে পাবেন না',
		},
		showUserMessage: {
			title: (name: string) => `${name} এর মেসেজ দেখান?`,
			subTitle: 'অন্য ব্যবহারকারীরা এই বার্তা দেখতে পারবে',
		},
		hideUserMessage: {
			title: (name: string) => `${name} এর মেসেজ লুকান?`,
			subTitle: 'অন্য ব্যবহারকারীরা আর এই বার্তা দেখতে পাবেন না',
		},
		blockUserInRoom: {
			title: (name: string) => `${name}কে বন্ধ করুন?`,
			subTitle: 'ব্যবহারকারী এই রুমে অংশগ্রহণ করতে পারবে না',
		},
		unBlockUserInRoom: {
			title: (name: string) => `${name}কে আনব্লক করুন?`,
			subTitle: 'ব্যবহারকারী এই রুমে অংশগ্রহণ করতে পারবে',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `${name}কে বন্ধ করুন?`,
			subTitle: 'আপনি একে অন্যকের মেসেজ দেখতে পাবেন না',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `${name}কে স্পিকার হিসেবে নির্ধারণ করুন?`,
			unTitle: (name: string) => `${name}কে স্পিকার হিসেবে বাতিল করুন?`,
		},
		banInApp: {
			title: 'অ্যাডমিন আপনাকে বন্ধ করেছেন',
			for: 'জন্য',
			subtitle: (bannedUntil: string) => `আপনি বন্ধ হন ${bannedUntil} পর্যন্ত`,
			permanent: 'আপনি চিরকালের জন্য বন্ধ হন',
		},
		banInAppBadNickname: {
			title: 'অ্যাডমিন আপনাকে খারাপ নামে বন্ধ করেছেন',
			subtitle: 'আপনি আপনার প্রোফাইলে যান, নাম পরিবর্তন করুন এবং আবার চ্যাটে যান',
		},
		deleteAccount: {
			title: 'প্রোফাইল মুছুন',
			subTitle: 'সমস্ত তথ্য মুছে যাবে। আপনি ৩০ দিনের মধ্যে প্রোফাইলটি পুনরুদ্ধার করতে পারবেন।',
		},
		pollEdit: {
			title: 'এই পোল সম্পাদনা করতে চান?',
			subTitle: 'ব্যবহারকারীদের উত্তর প্রতিরোধ হবে',
		},
		pollReset: {
			title: 'পোল রিসেট করতে চান?',
			subTitle: 'পোল সংরক্ষণ করা হবে না',
		},
		pollEnd: {
			title: 'আপনি কি সত্যিই পোল শেষ করতে চান?',
		},
		pollDelete: {
			title: 'পোল মুছে ফেলতে চান?',
		},
		agoraCheck: {
			updateIOSText: 'সমস্ত অ্যাপের সরঞ্জাম ব্যবহার করতে iOS সংস্করণ আপডেট করুন।',
			allowAccess: {
				title: 'আপনি কিছু বলতে চান?',
				text: 'অনুগ্রহ করে অ্যাপ সেটিংস খুলুন এবং মাইক্রোফোন এবং স্পিকারে অ্যাক্সেস অনুমোদন করুন।',
			},
			microphoneAndSpeakerText: '14.3 সংস্করণ থেকে মাইক্রোফোন এবং স্পিকার ব্যবহার সহজ।',
			microphoneText: '14.3 সংস্করণ থেকে মাইক্রোফোন এবং স্পিকার ব্যবহার সহজ।',
			btn: 'ঠিক আছে!',
		},
	},
	toasts: {
		reciveComplaint: 'আমরা আপনার অভিযোগ পেয়েছি',
		setSpeakerRoleForUser: 'ব্যবহারকারী স্পিকার হিসেবে নির্ধারণ করা হলো',
		unSpeakerRoleForUser: 'ব্যবহারকারীকে স্পিকার হিসেবে নির্ধারণ থেকে অপসারিত করা হলো',
		messagesShown: 'বার্তা দেখানো হয়েছে',
		messagesHidden: 'বার্তা লুকানো হয়েছে',
		messageShown: 'বার্তা দেখানো হয়েছে',
		messageHidden: 'বার্তা লুকানো হয়েছে',
		userBlocked: 'ব্যবহারকারী বন্ধ করা হয়েছে',
		userUnBlocked: 'ব্যবহারকারীর বন্ধন খোলা হয়েছে',
		youAreSpeaker: 'আপনি স্পিকার! কিছু বলুন',
		youAreNotSpeaker: 'আডমিন আপনাকে স্পিকার হিসেবে নির্ধারণ করেছেন না',
		userIsSpeaker: (name: string) => `${name} এখন স্পিকার`,
		userNotSpeaker: (name: string) => `${name} এখন স্পিকার না`,
		yourUserNameInChat: (name: string) => `আপনার চ্যাটের নাম — ${name}`,
		messageRemoved: 'বার্তা মুছে ফেলা হয়েছে',
		messageCopied: 'টেক্সট কপি করা হয়েছে',
		obscenities: 'পরিবর্তন করার চেষ্টা করুন',
		requestSent: 'অনুরোধ প্রেরিত হয়েছে',
		requestDecline: 'আপনার মাইক্রোফোনের অনুরোধ প্রত্যাখ্যান করা হয়েছে',
		youareblocked: (text: string) =>
			`আপনাকে ${text} এর জন্য ব্লক করা হয়েছে। আপনি চ্যাটে বার্তা লিখতে পারবেন না`,
		youareunblocked: 'আপনি ব্লক থেকে মুক্তি পেয়েছেন। আপনি চ্যাটে বার্তা লিখতে পারবেন',
		youAreModerator: 'আপনি মডারেটর!',
		youAreNoModerator: 'আপনি আর মডারেটর নন',
		micMuted: 'আপনার মাইক্রোফোন মিউট করা হয়েছে',
		noInet: 'ইন্টারনেট সংযোগ নেই',
		messagePinned: 'বার্তা পিন করা হয়েছে',
		messageUnpinned: 'বার্তা পিন থেকে আলাদা করা হয়েছে',
		pollPublished: 'পোল প্রকাশিত হয়েছে',
		pollPublishedError: 'ত্রুটি! পরে আবার চেষ্টা করুন',
		pollAddPic: 'ছবি যুক্ত করুন',
		pollAddPicError: 'অন্য চিত্র চয়ন করুন',
		pollDeleted: 'পোল মুছে ফেলা হয়েছে',
		pollEnded: 'পোল শেষ হয়েছে',
		sendStickerWhenSlowModeIsOn: (time: string) => `স্টিকার প্রেরণের জন্য অপেক্ষা করুন ${time}`,
		streamConnecting: 'স্ট্রীমে সংযোগ স্থাপন হচ্ছে...',
		linkProhibited: 'আপনি চ্যাটে লিঙ্ক পাঠাতে পারবেন না',
		phoneProhibited: 'আপনি চ্যাটে ফোন নম্বর পাঠাতে পারবেন না',
		profileHidden: 'ব্যবহারকারী তাদের প্রোফাইল লুকানো আছে',
		imageSizeWarning: 'আপনি 10 MB পর্যন্ত একটি চিত্র আপলোড করতে পারেন',
		imageBlocked: 'মডারেটর চিত্র আপলোড ব্লক করেছে',
		streamKeyCopied: 'সম্প্রচার কী অনুলিপি করা হয়েছে৷',
	},
	btns: {
		acceptandgo: 'গ্রহণ করুন এবং চলুন',
		saveandgo: 'সংরক্ষণ এবং চালিয়ে যান',
		save: 'সংরক্ষণ করুন',
		unblock: 'আনব্লক করুন',
		block: 'ব্লক করুন',
		restoreAccount: 'প্রোফাইল পুনরুদ্ধার করুন',
		close: 'বন্ধ করুন',
	},
	coopyrights: 'Watchers.io ভিত্তিতে পরিচালিত',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message' ? 'বার্তা রিপোর্ট করুন' : 'ভঙ্গি রিপোর্ট করুন',
			subtitle: 'রিপোর্ট গোপনে প্রেরিত হয়',
			btns: {
				violence: 'অপমান, হুমকি, গালাগাল',
				provocations: 'উত্তেজনা',
				personalDetails: 'ব্যক্তিগত তথ্য',
				flood: 'বন্ধুস্ত',
				spam: 'স্প্যাম',
				fraudBegging: 'প্রতারণা, পোকা',
				other: 'অন্যান্য',
			},
		},
		ban: {
			title: 'ব্যবহারকারী ব্লক করুন',
			subtitle: 'ব্লক করার কারণ উল্লেখ করুন',
			btns: {
				insults: 'অপমান, হুমকি, গালাগাল',
				personal: 'ব্যক্তিগত তথ্য প্রসারণ',
				flood: 'বন্ধুস্ত',
				links: 'বাহ্যিক সার্ভিসের লিংক',
				violence: 'সন্ত্রাস ধরে',
				fraud: 'প্রতারণা এবং পোকা প্রচেষ্টা',
				nickname: 'অযোগ্য নাম',
				politicial: 'রাজনৈতিক, যৌনতা সংক্রান্ত এবং অন্যান্য উত্তেজনাদায়ক বিষয়',
				repeated: 'পুনরাবৃত্তি',
				auto: 'চ্যাট ব্যবহারকারীদের রিপোর্ট',
				spam: 'স্প্যাম',
			},
		},
		waitingSpeakers: {
			text: 'আপনার অপেক্ষারত স্পিকার নেই',
			handsUp: 'হাত উঠিয়েছে',
			waiting: 'অপেক্ষারত স্পিকার',
		},
		poll: {
			supTitles: {
				typeQuiz: 'সঠিক উত্তর দিন',
				typeQuizMultiple: 'সঠিক বিকল্পগুলি চিহ্নিত করুন',
				typePoll: 'আপনার মতামত শেয়ার করুন',
				typePollMultiple: 'একাধিক বিকল্প চিহ্নিত করা যাবে',
				default: 'পাবলিক জনমত পরীক্ষা',
			},
			btns: {
				reply: 'উত্তর দিন',
				vote: 'ভোট দিন',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: 'কুইজ',
				typePoll: 'একাধিক উত্তর',
				default: 'পাবলিক জনমত পরীক্ষা',
			},
			btns: {
				edit: 'পরীক্ষা সম্পাদনা করুন',
				end: 'সমাপ্ত করুন',
			},
		},
		pollCreated: {
			titles: {
				created: 'পরীক্ষা তৈরি করুন',
				typeQuiz: 'কুইজ',
				typePoll: 'একাধিক উত্তর',
				default: 'পাবলিক জনমত পরীক্ষা',
			},
		},
		changeName: {
			title: 'চ্যাটে নাম পরিবর্তন করুন',
			subtitle: 'অযোগ্য নামের জন্য আপনাকে ব্লক করা হয়েছে',
		},
		temporaryAgreement: {
			title: 'চ্যাটের নিয়ম',
			rules: {
				bePolite: {
					title: 'শিষ্ট হোন',
					text: 'চ্যাটে বিরোধাত্মক এবং অশ্লীল বক্তব্য নিষিদ্ধ।',
				},
				beAttentive: {
					title: 'সতর্ক থাকুন',
					text: 'বেশিরভাগ মতামত বা ভাবনা বিনিময় করুন বা মন্তব্য করুন।',
				},
				notSharePersonalData: {
					title: 'ব্যক্তিগত তথ্য ভাগ করবেন না',
					text: 'আপনার নিজের বা অন্যের ব্যক্তিগত তথ্য শেয়ার করবেন না।',
				},
				notAdvertise: {
					title: 'বিজ্ঞাপন না করা',
					text: 'বিজ্ঞাপনের পরিবর্তে মতামত বা ভাবনা ভাগ করুন।',
				},
			},
			btns: {
				allRules: 'সমস্ত নিয়ম',
				acceptAndContinue: 'গ্রহণ এবং চালিয়ে যান',
			},
		},

		slowMode: {
			title: 'ধীরে ধীরে মোড',
			text: 'অংশগ্রহণকারীরা নির্দিষ্ট সময়কের মধ্যে চ্যাটে বার্তা প্রেরণ করতে পারবেন না',
			enable: 'ধীরে ধীরে মোড চালু করুন',
			disable: 'ধীরে ধীরে মোড বন্ধ করুন',
			sendTimeout: 'প্রেরণের সময়সীমা',
		},
		slowModeTimeout: {
			title: 'প্রেরণের সময়সীমা',
		},
		chatBot: {
			title: 'চ্যাট-বট ব্যবহার',
			rules: [
				{
					title: 'মানব বুদ্ধিমত্তা',
					text: 'বট মানুষের সাথে আলোচনা সহ তথ্য সরবরাহ করে এবং তার জ্ঞানের সীমার কারণে এর জবাবগুলি সীমিত এবং মানুষের স্থানে প্রতিস্থাপিত হতে পারে।',
				},
				{
					title: 'বট ভুল হতে পারে',
					text: 'বট তথ্যের নিরাপত্তা এবং জানার সঠিকতা নিশ্চিত করতে পারে না',
				},
			],
			btns: {
				close: 'বন্ধ করুন',
			},
		},
		shareBet: {
			title: 'শেয়ার করুন',
			noBetsTitle: 'আপনার বাজি খুঁজে পাচ্ছেন না',
			noBetsText: '১৪ দিনের মধ্যে যেসকল বাজি হয়েছে তা শেয়ার করতে পারেন',
			btns: {
				share: 'বাজি শেয়ার করুন',
				copy: 'বাজি পুনরাবৃত্তি করুন',
			},
			status: {
				null: 'খেলাধুলায়',
				win: 'জিত',
				lose: 'হার',
			},
			betTypeV1: {
				express: 'EXPRESS',
				single: 'SINGLE',
			},
			betTypeV2: {
				single: 'Single',
				multi: 'Multi',
				system: 'System',
			},
			totalFactor: 'ফ্যাক্টর',
			bet: 'বাজি',
			showMore: 'আরো দেখাও',
			betRepeated: 'Copied Bet',
			express: 'এক্সপ্রেস',
			single: 'একক',
		},
		record: {
			text: 'ব্রডকাস্ট রেকর্ডিং চলছে...',
		},
		agoraDevices: {
			title: 'অডিও সেটিংস',
			text: 'সঠিক মাইক্রোফোনটি নির্বাচন করা আছে তা পরীক্ষা করুন',
		},
		translate: {
			title: 'বার্তা অনুবাদ',
			chooseLanguage: 'ভাষা নির্বাচন করুন',
			descr: 'চ্যাটের বার্তা আপনাদের নির্বাচিত ভাষাতে স্বয়ংক্রিয়ভাবে অনুবাদ হবে',
			btn: 'অনুবাদ',
		},

		streamSettings: {
			title: 'স্ট্রিম সেটিংস',
			description:
				'একটি ভিডিও এনকোডার ডাউনলোড করুন এবং ইনস্টল করুন। এর সেটিংসে স্ট্রিম কী প্রবেশ করান',
			shareScreen: {
				title: 'স্ক্রিন শেয়ার',
				description: 'কোনও বিশেষ সেটিং প্রয়োজন নেই',
			},
			rtmp: {
				title: 'বাহ্যিক স্ট্রিম উৎস',
				description: 'স্ট্রিমিংয়ের জন্য একটি অতিরিক্ত প্রোগ্রাম ব্যবহার করুন',
			},
			streamKey: 'স্ট্রিম কী',
			showStreamKey: 'দেখান',
			hideStreamKey: 'লুকান',
			btns: {
				continue: 'চালিয়ে যান',
				close: 'বন্ধ করুন',
				ok: 'সম্পন্ন',
			},
		},
	},

	pinnedMessage: {
		title: 'পিন করা মেসেজ',
	},
	errorPage: {
		error: 'ত্রুটি',
		types: [
			// errorNumber 0
			{
				title: 'আমরা আপনাকে চিনতে পারছি না',
				text: 'চ্যাটে আবার লগইন করার চেষ্টা করুন',
			},
			// errorNumber 1
			{
				title: 'চ্যাট খুঁজে পাচ্ছি না',
				text: 'আবার লগইন করার চেষ্টা করুন',
			},
			// errorNumber 2
			{
				title: 'চ্যাট লোড করা যাচ্ছে না',
				text: 'পুনরায় লোড করার চেষ্টা করুন',
			},
			// errorNumber 3
			{
				title: 'আমরা আপনাকে চিনতে পারছি না',
				text: 'চ্যাট ইঞ্কগনিটো মোডে অ্যাক্সেসযোগ্য নয়',
			},
			// errorNumber 4
			{
				title: 'অননুমোদিত নিকনেম',
				text: 'আপনার প্রোফাইলে যান, নিকনেম পরিবর্তন করুন এবং আবার চ্যাটে প্রবেশ করুন।',
			},
		],
	},
	tooltips: {
		emotions: 'পরিবর্তন করতে ধরুন',
		badInet: 'নেটওয়ার্কের সংযোগ খারাপ',
		blockedUsers: 'ব্লক করা ব্যবহারকারীদের তালিকা দেখুন <br>এখানে দেখা যাবে।',
		waitingSpeakers: 'প্রত্যাশার্থী কেউ কিছু কথা বলতে চাচ্ছেন।',
		wantSpeak: 'অধিকার অনুরোধ করতে ট্যাপ করুন।',
		slowMode: {
			header: (time: string) =>
				`ধীরে ধীরে মোড সক্রিয়। ব্যবহারকারীরা প্রতি <br> ${time} একটি বার্তা প্রেরণ করতে পারেন। এটি মডারেটরদের ক্ষেত্রে প্রযোজ্য নয়।`,
			btnSendMessage: (time: string) =>
				`আপনি প্রতি ${time} একটি বার্তা প্রেরণ করতে পারেন <br> এখানে।`,
		},
		shareBet: 'চ্যাটে বাজি শেয়ার করুন',
		translate: 'বার্তাগুলি অন্য ভাষায় অনুবাদ করুন',
	},
	poll: {
		toggleSubmenu: {
			create: 'পোল তৈরি করুন',
			edit: 'পোল সম্পাদনা করুন',
			show: 'পোল দেখান',
			end: 'পোল শেষ করুন',
		},
		tooltip: {
			btn: 'ভোট দিন',
		},
		settings: {
			title: 'সেটিংস',
			titleAdditionally: 'অতিরিক্ত',
			switches: {
				quizMode: 'কুইজ মোড',
				multipleChoice: 'একাধিক উত্তর নির্বাচন',
				withPhoto: 'ছবি সহ পোল',
			},
		},
		question: {
			title: 'প্রশ্ন',
			inputPlaceholder: 'প্রশ্ন লিখুন',
		},
		message: {
			title: 'আপনি ভোট দিয়েছেন',
		},
		answers: {
			title: 'উত্তর বিকল্প',
			warning: 'আপনি হারানোর পর্যন্ত 5 উত্তর যোগ করতে পারেন',
		},
		answer: {
			tooltip: 'সঠিক উত্তর নির্বাচন করুন',
			inputPlaceholder: 'উত্তর',
		},
		results: {
			text: 'ভোটিং ফলাফল',
			votes: 'ভোট',
			// words: ['голос', 'голоса', 'голосов'],
		},
		btns: {
			addQuestion: 'প্রশ্ন যোগ করুন',
			addAnswers: 'উত্তর যোগ করুন',
			addAnswer: 'উত্তর যোগ করুন',
			publish: 'প্রকাশ',
			vote: 'ভোট দিন',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: 'রেটিং দিন',
				continue: 'চালিয়ে যান',
				checkConnection: 'সংযোগ পরীক্ষা করুন',
				close: 'বন্ধ করুন',
			},
			quality: {
				title: 'শব্দের গুনগতা রেটিং করুন',
			},
			reason: {
				title: 'কি ভুল হয়েছে?',
			},
			connection: {
				title: 'সংযোগের গুনগতা পরীক্ষা করুন',
				text: 'পরীক্ষা করা সময় নেবে না বেশি সময় নেবে এবং সমস্যার কারণটি খুঁজে বের করার সাহায্য করবে',
			},
			statistics: {
				title: 'পরিসংখ্যান সংগ্রহ করা হচ্ছে...',
			},
			done: {
				title: 'ধন্যবাদ!',
				text: 'আমরা আপনার মতামত বিশ্লেষণ করব এবং নিশ্চিতভাবে সমস্যাটি সমাধান করব।',
			},
		},
	},
	chatBot: {
		message: 'বটের কাছে কিছু জিজ্ঞাসা করুন...',
		warnings: {
			disabled: 'আমাকে অক্ষম করেছেন',
			overloaded: 'আমরা ওভারলোড হয়েছি',
		},
	},
	audioReason: {
		nothear: 'কিছুই শোনা যায়নি',
		connection: 'সংযোগ বিচ্ছিন্ন হয়েছিল',
		microphone: 'মাইক্রোফোন ব্যবহার করা যাবেনি',
	},
	screenShare: {
		title: 'স্ক্রিন শেয়ারিং',
		stopSharing: 'স্ক্রিন শেয়ারিং বন্ধ করুন',
		modal: {
			title: 'স্ক্রিন শেয়ারিং কেবল একটি কম্পিউটার থেকে উপলব্ধ।',
			text: `আপনার কম্পিউটারে চ্যাট খুলুন এবং উপরের ডানদিকে 'স্ক্রিন শেয়ারিং' বোতামটি চাপুন।`,
			btn: 'আমন্ত্রণ লিংকটি কপি করুন।',
		},
	},
	threads: {
		title: 'থ্রেড',
		back: 'ফিরে যান',
		replies: ['উত্তর', 'উত্তরগুলি', 'উত্তরগুলি'],
	},
	attach: {
		submenu: {
			sendImage: 'ছবি পাঠান',
			shareBet: 'বাজি ভাগ করুন',
			shareGamble: 'জয়ের ভাগ করুন',
		},
		images: ['ছবি', 'ছবি', 'ছবির'],
		addCaption: 'ক্যাপশন যোগ করুন',
	},
} as unknown as Dictionary;
