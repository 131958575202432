/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: 'Samkomulag',
		subTitle: 'Leyfi og persónuverndarskilmálar',
		textFirst: 'Ég samþykki skilmálana',
		textSecond: 'og veit þannig samþykki mitt um vinnslu persónuupplýsinga samkvæmt',
		license: 'leyfis',
		policy: 'og persónuverndarskilmálum',
	},
	controlPanel: {
		streamEnded: 'Útsendingu lokið',
		banAlert: 'Þú getur ekki skrifað í spjallið',
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? 'Stickers' : 'Emojis',
	},
	accountDeletedScreen: {
		title: 'Prófíl eytt',
		text: (date: string) => `Þú getur endurheimt prófílinn þinn upp að ${date}`,
	},
	nameScreen: {
		title: 'Nafn í spjalli',
		inputPlaceholder: 'Sláðu inn nafn',
		nameError: 'Ógilt nafn',
	},
	avatarScreen: {
		title: 'Prófíl mynd',
		label: 'Veldu annan:',
	},
	settingsScreen: {
		title: 'Stillingar',
		personal: 'Persónulegar stillingar',
		room: 'Herbergisstillingar',
		profileBtn: 'Læstir',
		languageBtn: 'Tungumál og viðmót',
		blockedBtn: 'Læstir',
		rules: 'Spjallreglur',
		deleteProfile: 'Eyða prófíl',
		slowModeBtn: 'Hæg aðferð',
		agora: {
			microphone: 'Hljóðnemi',
			playbackDevice: 'Gjaldgjöf',
		},
	},
	languageScreen: {
		title: 'Tungumál og viðmót',
		messagesTranslate: 'Þýðing skilaboða',
		translateMessagesMode: 'Þýða skilaboð í spjalli',
		translateMessagesTo: 'Þýða skilaboð á',
	},
	rulesScreen: {
		title: 'Spjallreglur',
	},
	profileScreen: {
		title: 'Prófíll',
		selectAnotherPic: 'Veldur aðra mynd',
		chatNameLabel: 'Nafn í spjalli',
		changeName: 'breyta',
	},
	blockedScreen: {
		title: 'Læstir',
		emptyListText: 'Engir læstir notendur',
	},
	chatScreen: {
		inputPlaceholder: 'Skilaboð...',
		cantWrite: 'Þú getur ekki skrifað í spjallið',
		chatSubmenu: {
			hideMessage: 'Fela sukilaboðin',
			showMessage: 'Sýna skilaboðin',
			removeFromSpeakers: 'Taka út ræðumann',
			setSpeaker: 'Gera að ræðumanni',
			hideOnlyMessage: 'Fela skilaboð',
			showOnlyMessage: 'Sýna skilaboð',
			reportMessage: 'Kvarta um skilaboð',
			reportUser: 'Kvarta um notanda',
			muteSpeaker: 'Slökkva á hljóði',
			blockUser: 'Læsa',
			unblockUser: 'Aflæsa',
			reply: 'Svara',
			copy: 'Afrita',
			edit: 'Breyta',
			delete: 'Eyða',
			pinMessage: 'Festa',
			unpinMessage: 'Aflýsa',
			goToProfile: 'Fara í prófíl',
			replyInThread: 'Svara í þræði',
		},
		actionMessage: {
			editing: 'Breyting',
			reply: 'Svara',
		},
		status: {
			top: 'Topp',
		},
		translating: 'Í þýðingu...',
	},
	chatUsersScreen: {
		title: 'Þátttakendur',
		edited: 'Breytt',
		hidden: 'Skilaboð',
		unreadMessages: 'Ólesið skilaboð',
		usersSubmenu: {
			muteSpeaker: {
				title: 'Þagga niður þátttakenda',
				subtitle: 'Slökkva á hljóði',
			},
			setSpeaker: {
				title: 'Taka út ræðumann',
				subtitle: 'Einungis textaspjall',
			},
			removeFromSpeaker: {
				title: 'Gera að ræðumanni ',
				subtitle: 'Notandi getur talað',
			},
			showMessages: {
				title: 'Sýna skilaboðin',
				subtitle: 'Einungis sýnilegt fyrir stjórnanda',
			},
			hideMessages: {
				title: 'Fela sukilaboðin',
				subtitle: 'Einungis sýnilegt fyrir stjórnanda',
			},
			blockUser: {
				title: 'Læsa',
				subtitle: 'Bæta á bann lista',
			},
			unblockUser: {
				title: 'Aflæsa',
				subtitle: 'Aflæsa notanda',
			},
			blockUserByUser: {
				title: 'Læsa',
				subtitle: 'Fela öll skilaboð notanda',
			},
			unblockUserByUser: {
				title: 'Aflæsa',
				subtitle: 'Sýna öll proskilaboð notanda  ',
			},
			reportUser: {
				title: 'Kvarta um notanda',
				subtitle: 'Kvörtunin send í leyni',
			},
		},
		days: {
			today: 'Í dag',
			yesterday: 'Í gær',
		},
		chatCreated: 'Spjallið hefur verið stofnað',
	},
	alerts: {
		btns: {
			unblock: 'Aflæsa',
			block: 'Læsa',
			send: 'Senda',
			sendAndBlock: 'Senda og læsa',
			show: 'Sýna',
			showAll: 'Sýna allt',
			cancel: 'Hætta við',
			yes: 'Já',
			hide: 'Fela valið',
			hideAll: 'Fela allt',
			make: 'Gera',
			remove: 'Fjarlægja',
			delete: 'Eyða',
			deleteAccount: 'Eyða prófíl',
			close: 'Loka',
			pin: 'Festa',
			unpin: 'Aflýsa',
			edit: 'Breyta',
			end: 'Ljúka',
			endAndShare: 'Ljúka og deila',
			reset: 'Núllstilla',
			rules: 'Spjallreglur ',
			contactus: 'Hafðu samband við okkur',
		},
		pinMessage: {
			title: 'Festa skilaboð?',
		},
		unPinMessage: {
			title: 'Afturkalla skilaboð?',
		},
		accountRestored: {
			title: 'Endurgera prófíl',
		},
		closeApp: {
			title: 'Ertu viss?',
		},
		removeMessage: {
			title: 'Eyða skilaboðum?',
		},
		report: {
			title: 'Senda kvörtun?',
			subtitle: 'Þú getur einnig lokað á notanda til að sjá ekki skilaboð ykkar á milli.',
		},
		banUser: {
			title: (name: string) => `Loka ${name}?`,
			subtitle: (text: string) => `Notandi verður lokaður fyrir ${text}`,
		},
		unBanUser: {
			title: (name: string) => `Aflósa ${name}?`,
		},
		showUserMessages: {
			title: (name: string) => `Sýna skilaboð frá ${name}?`,
			subTitle: 'Aðrir notendur munu geta séð skilaboðin frá þessum notanda',
		},
		hideUserMessages: {
			title: (name: string) => `Fela skilaboð frá ${name}?`,
			subTitle: 'Aðrir notendur munu ekki lengur geta séð skilaboðin frá þessum notanda',
		},
		showUserMessage: {
			title: (name: string) => `Sýna skilaboð frá ${name}?`,
			subTitle: 'Aðrir notendur munu geta séð þetta skilaboð',
		},
		hideUserMessage: {
			title: (name: string) => `Fela skilaboð frá ${name}?`,
			subTitle: 'Aðrir notendur munu ekki lengur geta séð þetta skilaboð',
		},
		blockUserInRoom: {
			title: (name: string) => `Loka ${name}?`,
			subTitle: 'Notandi getur ekki tekið þátt í þessum herbergi',
		},
		unBlockUserInRoom: {
			title: (name: string) => `Aflósa ${name}?`,
			subTitle: 'Notandi getur tekið þátt í þessum herbergi',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `Loka ${name}?`,
			subTitle: 'Þú munt ekki sjá skilaboð annarra',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `Gera ${name} að ræðumanni?`,
			unTitle: (name: string) => `Fjarlægja ${name} sem ræðumann?`,
		},
		banInApp: {
			title: 'Stjórnandi hefur lokað þig',
			for: 'vegna',
			subtitle: (bannedUntil: string) => `Þú ert lokaður til ${bannedUntil}`,
			permanent: 'Þú ert varanlega lokaður ',
		},
		banInAppBadNickname: {
			title: 'Stjórnandi hefur lokað þig vegna óviðeigandi nafns',
			subtitle: 'Farið inn á þínar stillingar, breytið nafni og farið aftur inn í spjallið',
		},
		deleteAccount: {
			title: 'Eyða prófíl',
			subTitle: 'Öll gögn verða eydd. Þú getur endurheimt prófílinn innan 30 daga.',
		},
		pollEdit: {
			title: 'Breyta könnun?',
			subTitle: 'Notkunaraðgerðir notenda verða aftur settar',
		},
		pollReset: {
			title: 'Endurstilla?',
			subTitle: 'Könnunin verður ekki vistuð',
		},
		pollEnd: {
			title: 'Ertu viss um að þú viljir loka könnuninni?',
		},
		pollDelete: {
			title: 'Eyða könnuninni?',
		},
		agoraCheck: {
			updateIOSText: 'Uppfærðu iOS útgáfu til að geta notað alla möguleika forritsins.',
			allowAccess: {
				title: 'Viltu segja eitthvað?',
				text: 'Til að nota hljóðnema og hljóðgjafa, farðu í stillingar forritsins og leyfðu aðganginn.',
			},
			microphoneAndSpeakerText: 'Hljóðnemar og hljóðgjafar eru tiltækar aðeins frá útgáfu 14.3',
			microphoneText: 'Hljóðnemar eru tiltækir frá útgáfu 14.3',
			btn: 'Skýrt',
		},
	},
	eventScreen: {
		title: 'Áætlaður viðburður',
		titleSoon: 'Útsendingin mun hefjast fljótlega',
		subtitle: 'Spjallið mun opnast fljótlega, vinsamlegast bíðið.',
		days: 'dagar',
		hours: 'klukkutímar',
		minutes: 'mínútur',
		seconds: 'sekúndur',
		startTitle: 'Byrjun',
	},
	toasts: {
		reciveComplaint: 'Okkur hefur borist kvörtun þína',
		setSpeakerRoleForUser: 'Notanda skipað að tala',
		unSpeakerRoleForUser: 'Notandi fjarlægður sem ræðumaður',
		messagesShown: 'Skilaboðin eru sýnileg',
		messagesHidden: 'Skilaboðin eru falin ',
		messageShown: 'Skilaboð sýnt',
		messageHidden: 'Skilaboð falið',
		userBlocked: 'Notandinn er lokaður',
		userUnBlocked: 'Notandinn er opnaður',
		youAreSpeaker: 'Þú ert ræðumaður! Segðu eitthvað',
		youAreNotSpeaker: 'Stjórnandi hefur fjarlægt þig sem ræðumann',
		userIsSpeaker: (name: string) => `${name} er nú ræðumaður`,
		userNotSpeaker: (name: string) => `${name} er ekki lengur ræðumaður`,
		yourUserNameInChat: (name: string) => `Notandanafn þitt í spjalli er ${name}`,
		messageRemoved: 'Skilaboð hafa verið eytt',
		messageCopied: 'Textinn hefur verið afritaður',
		obscenities: 'Reyndu að umorða',
		requestSent: 'Beiðni send',
		requestDecline: 'Ósk um hljóðnema hafnað',
		youareblocked: (text: string) =>
			`Þú ert lokaður fyrir ${text}. Þú getur ekki sent skilaboð í spjallið`,
		youareunblocked: 'Þú ert opnaður aftur. Þú getur sent skilaboð í spjallið',
		youAreModerator: 'Þú ert stjórnandi!',
		youAreNoModerator: 'Þú ert ekki lengur stjórnandi',
		micMuted: 'Hljóðneminn þinn er þagnaður  ',
		noInet: 'Engin netþjónusta',
		messagePinned: 'Skilaboðið er fest',
		messageUnpinned: 'Skilaboðið er laust',
		pollPublished: 'Könnunin hefur verið birt',
		pollPublishedError: 'Villa! Reyndu aftur síðar',
		pollAddPic: 'Bættu við mynd',
		pollAddPicError: 'Veldu aðra mynd',
		pollDeleted: 'Könnuninni hefur verið eytt ',
		pollEnded: 'Könnuninni er lokið',
		sendStickerWhenSlowModeIsOn: (time: string) => `Bíddu í ${time} til að senda sticker`,
		streamConnecting: 'Tengjumst útsendingu...',
		linkProhibited: 'Þú getur ekki sent tengla í spjallið.',
		phoneProhibited: 'Þú getur ekki sent',
		profileHidden: 'Notandi faldi prófílinn sinn.',
		imageSizeWarning: 'Þú getur hlaðið upp mynd sem er að hámarki 10 MB',
		imageBlocked: 'Stjórnandinn lokaði fyrir myndaupplading',
		streamKeyCopied: 'Útsendingarlykill afritaður',
	},
	btns: {
		acceptandgo: 'Samþykkja og halda áfram',
		saveandgo: 'Vista og halda áfram',
		save: 'Vista',
		unblock: 'Aflæsa',
		block: 'Læsa',
		restoreAccount: 'Endurheimta prófíl',
		close: 'Loka',
	},
	coopyrights: 'Keyrir á Watchers.io',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message' ? 'Kvarta um skilaboð' : 'Tilkynna um brot',
			subtitle: 'Kynningar eru sendar nafnlaust',
			btns: {
				violence: 'Óvirðingar, þrætur, ótta',
				provocations: 'Ögrandi hegðun',
				personalDetails: 'Persónuupplýsingar',
				flood: 'Flæði',
				spam: 'Spam',
				fraudBegging: 'Rán, betlun',
				other: 'Annað',
			},
		},
		ban: {
			title: 'Loka notanda',
			subtitle: 'Tilgreindu ástæðuna fyrir lokun  ',
			btns: {
				insults: 'Óvirðingar, þrætur, ótta',
				personal: 'Dreifing persónuupplýsinga',
				flood: 'Flæði',
				links: 'Tenglar á þriðja aðila',
				violence: 'Ofbeldishótanir',
				fraud: 'Tilrun ráns og betlunar',
				nickname: 'Óheimilt notendanafn',
				politicial: 'Pólitískar, kynferðislegar og aðrar ögrandi hegðunarmynstur ',
				repeated: 'Endurtekinn brot',
				auto: 'Kvörtun notenda um spjallherbergið',
				spam: 'Spam',
			},
		},
		waitingSpeakers: {
			text: 'Engir óvæntir ræðumenn eru í bið',
			handsUp: 'Hækktu höndina',
			waiting: 'Ræðumenn í bið',
		},
		poll: {
			supTitles: {
				typeQuiz: 'Veittu rétt svar',
				typeQuizMultiple: 'Merktu rétta valkosti',
				typePoll: 'Deildu skoðunum þínum',
				typePollMultiple: 'Hægt er að merkja nokkra möguleika ',
				default: 'Almenn kosning',
			},
			btns: {
				reply: 'Svara',
				vote: 'Kjósa',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: 'Spurningar leikur',
				typePoll: 'Svarmöguleikar',
				default: 'Almenn kosning',
			},
			btns: {
				edit: 'Breyta kosningu',
				end: 'Ljúka',
			},
		},
		pollCreated: {
			titles: {
				created: 'Búa til könnun',
				typeQuiz: 'Spurningar leikur',
				typePoll: 'Svarmöguleikar',
				default: 'Almenn kosning',
			},
		},
		changeName: {
			title: 'Breyttu nafni í spjallinu',
			subtitle: 'Þú ert lokaður út fyrir óheimilísu notandanafn',
		},
		temporaryAgreement: {
			title: 'Spjallreglur ',
			rules: {
				bePolite: {
					title: 'Vertu kurteis',
					text: 'Í spjallinu eru fjandsamleg og ósiðleg orðalög bönnuð.',
				},
				beAttentive: {
					title: 'Vertu gaumgæfur',
					text: 'Deildu ekki persónuupplýsingum og biddu ekki um þær frá öðrum.',
				},
				notAdvertise: {
					title: 'Ekki auglýsa',
					text: 'Betra að deila skoðunum eða tilfinningum.',
				},
			},
			btns: {
				allRules: 'Allar reglur',
				acceptAndContinue: 'Samþykkja og halda áfram',
			},
		},
		slowMode: {
			title: 'Hæg aðferð ',
			text: 'Þátttakendur geta ekki sent skilaboð í spjallið oftar en á völdu tímaþrotabili',
			enable: 'Kveiktu á hægfara aðferð ',
			disable: 'Slökktu á hægfara aðferð',
			sendTimeout: 'Senda tímamörk',
		},
		slowModeTimeout: {
			title: 'Skilaboðatímamörk',
		},
		chatBot: {
			title: 'Nota spjallmenni',
			rules: [
				{
					title: 'Gervigreind',
					text: 'Spjallmenni eftirhermir samtal við fólk og veitir upplýsingar byggt á þekkingu sinni. Hins vegar, mundu að svör spjallmennisins geta verið takmörkuð og munu ekki standa í stað mannlegra samskipta.',
				},
				{
					title: 'Spjallmennið getur gert mistök',
					text: 'Spjallmennið getur ekki tryggt nákvæmni og áreiðanleikar upplýsingar.',
				},
			],
			btns: {
				close: 'Loka',
			},
		},
		shareBet: {
			title: 'Deila',
			noBetsTitle: 'Gat ekki fundið veðmálin þín',
			noBetsText: 'Þú getur deilt veðmálum sem hafa verið gerð innan 14 daga',
			btns: {
				share: 'Deila veðmáli',
				copy: 'Endurtaka veðmálið:',
			},
			status: {
				null: 'Í leik',
				win: 'Vinningur',
				lose: 'Tap',
			},
			betTypeV1: {
				express: 'Express',
				single: 'Einfalt',
			},
			betTypeV2: {
				single: 'Einfalt.',
				multi: 'Multi.',
				system: 'System',
			},
			totalFactor: 'Stuðull',
			bet: 'пари',
			showMore: 'Sýna meira',
			betRepeated: 'einstaklingar endurtóku veðmálið',
		},
		record: {
			text: 'Útsendingin er í vinnslu...',
		},
		agoraDevices: {
			title: 'Hljóðstillingar',
			text: 'Staðfestu að rétti hljóðgjafi sé valinn',
		},
		translate: {
			title: 'Þýðing skilaboða',
			chooseLanguage: 'Veldu tungumál',
			descr: 'Öll skilaboð í spjalli verða sjálfkrafa þýdd á valið tungumál.',
			btn: 'Kveiktu á þýðingu',
		},

		streamSettings: {
			title: 'Straumstillingar',
			description: 'Sæktu og settu upp myndbandskóðara. Sláðu inn straumlykil í stillingarnar hans',
			shareScreen: {
				title: 'Skjádeiling',
				description: 'Engar sérstakar stillingar nauðsynlegar',
			},
			rtmp: {
				title: 'Ytri straumgjafi',
				description: 'Notaðu aukaforrit til að streyma straumi',
			},
			streamKey: 'Straumlykill',
			showStreamKey: 'Sýna',
			hideStreamKey: 'Fela',
			btns: {
				continue: 'Halda áfram',
				close: 'Loka',
				ok: 'Lokið',
			},
		},
	},
	pinnedMessage: {
		title: 'Föst skilaboð',
	},
	errorPage: {
		error: 'Villa',
		types: [
			// errorNumber 0
			{
				title: 'Við þekkjum þig ekki',
				text: 'Reyndu aftur að tengjast spjalli',
			},
			// errorNumber 1
			{
				title: 'Ekki er hægt að finna spjallið',
				text: 'Reyndu aftur að tengjast',
			},
			// errorNumber 2
			{
				title: 'Ekki hægt að hlaða inn spjalli',
				text: 'Reyndu aftur að hlaða spjallið',
			},
			// errorNumber 3
			{
				title: 'Við þekkjum þig ekki',
				text: 'Spjallið er ekki aðgengilegt í incognito ',
			},
			// errorNumber 4
			{
				title: 'Óheimil nafn',
				text: 'Farðu í þín prófíl, breyttu nafni og skráðu þig aftur inn í spjallið.',
			},
		],
	},
	tooltips: {
		emotions: 'Halda til að breyta ',
		badInet: 'Slæm net tenging ',
		blockedUsers: 'Þú getur skoðað listann yfir lokaða notendur hér.',
		waitingSpeakers: 'Einhver af þátttakendum vill tala. ',
		wantSpeak: 'Ýttu til að leifa ræðumanni aðgang.',
		slowMode: {
			header: (time: string) =>
				`Hæg aðferð er virk. Notendur geta sent eitt skilaboð hvert <br>hverja ${time}. Þessi regla gildir ekki fyrir umsjónarmenn.`,
			btnSendMessage: (time: string) => `Þú getur sent skilaboð <br> 
hvert ${time}.`,
		},
		shareBet: 'Deila veðmáli í spjalli',
		translate: 'Þýða skilaboð á annað tungumál',
	},
	poll: {
		toggleSubmenu: {
			create: 'Búa til könnun',
			edit: 'Breyta könnun',
			show: 'Sýna könnun',
			end: 'Loka könnun',
		},
		tooltip: {
			btn: 'Kjósa',
		},
		settings: {
			title: 'Stillingar',
			titleAdditionally: 'Auka',
			switches: {
				quizMode: 'Spurningar leikja hámark',
				multipleChoice: 'Valdir svar möguleikar',
				withPhoto: 'Könnun með mynd',
			},
		},
		question: {
			title: 'Spurning',
			inputPlaceholder: 'Sláðu inn spurninguna',
		},
		message: {
			title: 'Þú hefur kosið ',
		},
		answers: {
			title: 'Svörkostir',
			warning: 'Þú getur bætt við allt að 5 svörum',
		},
		answer: {
			tooltip: 'Veldu rétt svar',
			inputPlaceholder: 'Svar',
		},
		results: {
			text: 'Niðurstöður atkvæðagreiðslu',
			votes: 'atkvæði',
			// words: ['atkvæði', 'atkvæði', 'atkvæði'],
		},
		btns: {
			addQuestion: 'Bæta við spurningu',
			addAnswers: 'Bæta við svörum',
			addAnswer: 'Bæta við svari',
			publish: 'Birta',
			vote: 'Kjósa',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: 'Meta',
				continue: 'Áfram',
				checkConnection: 'Athuga tengingu',
				close: 'Loka',
			},
			quality: {
				title: 'Meta hljóðgæði',
			},
			reason: {
				title: 'Hvað fór úrskeiðis?',
			},
			connection: {
				title: 'Prufa tenginguna',
				text: 'Athugaðu að það mun ekki taka meira en mínútu og hjálpa til við að finna vandamál',
			},
			statistics: {
				title: 'Safna tölfræði...',
			},
			done: {
				title: 'Takk!',
				text: 'Við skoðum hvað fór úrskeiðis og munum laga það. ',
			},
		},
	},
	chatBot: {
		message: 'Spurðu spjaltmannið um eitthvað...',
		warnings: {
			disabled: 'Ég hef verið útskráður',
			overloaded: 'Við erum á ofhlaðin',
		},
	},
	audioReason: {
		nothear: 'Ekkert heyrðist',
		connection: 'Hljóðið voru skerpt ',
		microphone: 'Ekki tókst að nota hljóðnema ',
	},

	copybetting: {
		orderStatus: {
			won: 'Vinningur',
			lost: 'Tap',
			new: 'New',
			pending: 'Bíð eftir útdrætti',
			cashedOut: 'Cashed out',
			boreDraw: 'Bore draw',
			cashback: 'Cashback',
			megaBetNew: 'MegaBet New',
			megaBetCanceledByOperator: 'MegaBet Canceled By Operator',
			megaBetCanceledByPlayer: 'MegaBet Canceled By Player',
			megaBetCanceledBySystem: 'MegaBet Canceled By System',
			betGamesPending: 'BetGames Pending',
			betGamesWon: 'BetGames Won',
			betGamesLost: 'BetGames Lost',
			paid: 'Paid',
			initialWin: 'Initial Win',
			autoBetCanceledBySystem: 'AutoBet Canceled By System',
			autoBetCanceledByPlayer: 'AutoBet Canceled By Player',
			autobetPending: 'Autobet Pending',
			goalAhead: 'Goal Ahead',
			canceled: 'Canceled',
			return: 'Return',
		},
		stakeStatus: {
			pending: 'Pending',
			won: 'Won',
			lost: 'Lost',
			return: 'Return',
			lostReturn: 'Lost | Return',
			wonReturn: 'Won | Return',
			canceled: 'Canceled',
			initialReturn: 'Initial Return',
			pendingInitialReturn: 'Pending | Initial Return',
			wonInitialReturn: 'Won | Initial Return',
		},
	},
	screenShare: {
		title: 'Skjásýning ',
		stopSharing: 'Staða stöðvuð',
		modal: {
			title: 'Deiling sjásinns er einungis hægt með tölvu',
			text: 'Opnið spjallið á tölvunni og ýttu á "Sýna skjá" hnappinn í efra hægra horninu.',
			btn: 'Afrita tengil til aðgangs',
		},
	},
	threads: {
		title: 'Þráður',
		back: 'Til baka',
		replies: ['Svar', 'Svör', 'Svör'],
	},
	attach: {
		submenu: {
			sendImage: `Senda mynd`,
			shareBet: 'Deila veðmáli',
			shareGamble: 'Deila vinningi',
		},
		images: ['mynd', 'myndir', 'mynda'],
		addCaption: 'Bæta við myndatexta',
	},
} as unknown as Dictionary;
