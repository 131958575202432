/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: '계약',
		subTitle: '라이선스 계약 및 개인정보 처리방침',
		textFirst: '조건에 따라 개인 데이터 처리 및 이하 조건에 동의합니다',
		license: '본인은 라이선스 계약',
		policy: ' 및 개인정보처리방침에 정의된.',
	},
	controlPanel: {
		streamEnded: '생방송이 종료되었습니다. ',
		banAlert: '5.	채팅에 글을 쓸 수 없습니다.',
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? '스티커' : '이모티콘',
	},
	accountDeletedScreen: {
		title: '프로필이 삭제되었습니다.',
		text: (date: string) => `7.	이전 프로필을 복원할 수 있습니다 ${date}`,
	},
	nameScreen: {
		title: '채팅 이름 ',
		inputPlaceholder: '이름을 입력하세요.',
		nameError: '잘못된 이름',
	},
	avatarScreen: {
		title: '아바타',
		label: '다른 이름을 선택하세요:',
	},
	settingsScreen: {
		title: '설정',
		personal: '개인 설정',
		room: '회의실 설정',
		profileBtn: '프로필',
		languageBtn: '언어 및 인터페이스',
		blockedBtn: '차단됨',
		rules: '채팅 규칙',
		deleteProfile: '프로필 삭제',
		slowModeBtn: '슬로우 모드',
		agora: {
			microphone: '마이크',
			playbackDevice: '사운드 소스 ',
		},
	},
	languageScreen: {
		title: '언어 및 인터페이스',
		messagesTranslate: '메시지 번역',
		translateMessagesMode: '채팅 메시지 번역하기',
		translateMessagesTo: '메시지를 다음으로 번역',
	},
	rulesScreen: {
		title: '채팅 규칙',
	},
	profileScreen: {
		title: '프로필',
		selectAnotherPic: '다른 아바타 선택하기',
		chatNameLabel: '채팅 이름',
		changeName: '변경',
	},
	blockedScreen: {
		title: '차단됨',
		emptyListText: '차단된 사용자 없음',
	},
	chatScreen: {
		inputPlaceholder: '메시지...',
		cantWrite: '채팅창에 글을 쓸 수 없습니다.',
		chatSubmenu: {
			hideMessage: '모든 메시지 숨기기 ',
			showMessage: '모든 메시지 표시 ',
			removeFromSpeakers: '스피커 목록에서 제거하기',
			setSpeaker: '스피커로 지정하기',
			hideOnlyMessage: '메시지 숨기기 ',
			showOnlyMessage: '메시지 표시',
			reportMessage: '메시지 불만 제기',
			reportUser: '사용자 불만 제기',
			muteSpeaker: '마이크 음소거하기',
			blockUser: '차단 ',
			unblockUser: '차단 해제',
			reply: '회신',
			copy: '복사',
			edit: '변경',
			delete: '삭제',
			pinMessage: '핀',
			unpinMessage: '핀 해제',
			goToProfile: '프로필로 이동 ',
			replyInThread: '스레드에서 답장',
		},
		actionMessage: {
			editing: '수정하기',
			reply: '회신',
		},
		status: {
			top: '상위',
		},
		translating: '번역 중...',
	},
	chatUsersScreen: {
		title: '참가자',
		edited: '변경',
		hidden: '숨겨져 있음',
		unreadMessages: '읽지 않은 메시지',
		usersSubmenu: {
			muteSpeaker: {
				title: '참가자 음소거하기',
				subtitle: '마이크 음소거하기',
			},
			setSpeaker: {
				title: '채팅자로 목록에서 삭제',
				subtitle: '메시지 채팅만 가능합니다',
			},
			removeFromSpeaker: {
				title: '채팅자로 설정 ',
				subtitle: '음성 채팅을 시작할 수 있습니다.',
			},
			showMessages: {
				title: '모든 메시지 표시',
				subtitle: '관리자에게만 표시',
			},
			hideMessages: {
				title: '모든 메시지 숨기기 ',
				subtitle: '관리자에게만 표시',
			},
			blockUser: {
				title: '차단',
				subtitle: '중지 목록에 추가',
			},
			unblockUser: {
				title: '차단 해제',
				subtitle: '사용자 차단 해제',
			},
			blockUserByUser: {
				title: '차단',
				subtitle: '사용자의 모든 메시지 숨기기',
			},
			unblockUserByUser: {
				title: '차단 해제',
				subtitle: '사용자의 모든 메시지 표시',
			},
			reportUser: {
				title: '사용자 불만 제기',
				subtitle: '불만 사항이 익명으로 전송됩니다.',
			},
		},
		days: {
			today: '오늘',
			yesterday: '어제',
		},
		chatCreated: '채팅이 생성되었습니ㄷ,',
	},
	alerts: {
		btns: {
			unblock: '차단 해제',
			block: '차단',
			send: '보내기 ',
			sendAndBlock: '보내기 및 차단',
			show: '표시',
			showAll: '모두 표시',
			cancel: '취소',
			yes: '예',
			hide: '선택 항목 숨기기',
			hideAll: '모두 숨기기',
			make: '만들기',
			remove: '제거 ',
			delete: '삭제',
			deleteAccount: '프로필 삭제',
			close: '닫기',
			pin: '핀',
			unpin: '핀 해제',
			edit: '편집',
			end: '완료',
			endAndShare: '완료 및 공유',
			reset: '리셋',
			rules: '채팅 규칙',
			contactus: '문의하기',
		},
		pinMessage: {
			title: '메시지를 고정하시겠습니까?',
		},
		unPinMessage: {
			title: '메시지 고정을 해제하시겠습니까?',
		},
		accountRestored: {
			title: '프로필이 복원되었습니다.',
		},
		closeApp: {
			title: '정말로 나가시겠습니까?',
		},
		removeMessage: {
			title: '메시지를 삭제하시겠습니까?',
		},
		report: {
			title: '불만 사항을 제기하시겠습니까?',
			subtitle: '사용자를 차단하여 서로의 메시지를 보지 못하도록 할 수도 있습니다.',
		},
		banUser: {
			title: (name: string) => `${name} 차단 ?`,
			subtitle: (text: string) => `${text} 사용자를 차단합니다.`,
		},
		unBanUser: {
			title: (name: string) => `${name}차단 해제 ?`,
		},
		showUserMessages: {
			title: (name: string) => `${name}의 메시지를 표시하시겠습니까?`,
			subTitle: '다른 사용자가 이 유저의 메시지를 볼 수 있습니다.',
		},
		hideUserMessages: {
			title: (name: string) => `${name}의 메시지를 숨기시겠습니까?`,
			subTitle: '다른 사용자가 이 유저의 메시지를 볼 수 있습니다.',
		},
		showUserMessage: {
			title: (name: string) => `${name}의 메시지를 표시하시겠습니까?`,
			subTitle: '다른 사용자가 이 메시지를 볼 수 있습니다.',
		},
		hideUserMessage: {
			title: (name: string) => `${name}의 메시지를 숨기시겠습니까?`,
			subTitle: '다른 사용자가 더 이상 이 유저의 메시지를 볼 수 없습니다.',
		},
		blockUserInRoom: {
			title: (name: string) => `차단 ${name}?`,
			subTitle: '해당 사용자는 이 대화방에 참여할 수 없습니다. ',
		},
		unBlockUserInRoom: {
			title: (name: string) => `차단 해제 ${name}?`,
			subTitle: '해당 사용자는 이 대화방에 참여할 수 없습니다. ',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `${name} 차단 ?`,
			subTitle: '서로의 메시지를 볼 수 없습니다.',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `${name}을 채팅자로 설정하시겠습니까?`,
			unTitle: (name: string) => `${name}을 채팅자 목록에서 제거하시겠습니까?`,
		},
		banInApp: {
			title: '관리자가 의 이유로 유저를 차단하였습니다',
			subtitle: (bannedUntil: string) => `${bannedUntil} 까지 차단됩니다.`,
			permanent: '영원히 차단되었습니다.',
		},
		banInAppBadNickname: {
			title: '관리자가 유저의 잘못된 이름으로 유저를 차단했습니다.',
			subtitle: '프로필에서 이름을 변경한 후 다시 채팅에 참여하세요.',
		},
		deleteAccount: {
			title: '프로필 삭제하기',
			subTitle: '모든 데이터가 삭제됩니다. 30일 이내에 프로필을 복원할 수 있습니다.',
		},
		pollEdit: {
			title: '투표를 수정하시겠습니까?',
			subTitle: '사용자 응답이 초기화됩니다.',
		},
		pollReset: {
			title: '리셋하시겠습니까?',
			subTitle: '설문조사가 저장되지 않습니다.',
		},
		pollEnd: {
			title: '설문조사를 완료하시겠습니까?',
		},
		pollDelete: {
			title: '설문조사를 삭제하시겠습니까?',
		},
		agoraCheck: {
			updateIOSText: '애플리케이션의 모든 기능을 사용하기 위해  iOS 버전을 업데이트합니다.',
			allowAccess: {
				title: '하고 싶은 말이 있나요?',
				text: '마이크와 스피커를 사용하려면 애플리케이션 설정으로 이동하여 액세스를 확인합니다.',
			},
			microphoneAndSpeakerText: '마이크 및 스피커는 버전 14.3부터 사용할 수 있습니다.',
			microphoneText: '마이크는 버전 14.3부터 사용 가능합니다',
			btn: '잘 들립니다',
		},
	},
	eventScreen: {
		title: '예정된 이벤트',
		titleSoon: '방송이 곧 시작됩니다.',
		subtitle: '곧 채팅이 시작될 예정이니 잠시만 기다려주세요.',
		days: '일',
		hours: '시간 ',
		minutes: '분',
		seconds: '초',
		startTitle: '시작',
	},
	toasts: {
		reciveComplaint: '불만 사항이 접수되었습니다.',
		setSpeakerRoleForUser: '스피커로 지정되었습니다',
		unSpeakerRoleForUser: '스피커 목록에서 삭제되었습니다',
		messagesShown: '모든 표시된 메시지 ',
		messagesHidden: '모든 숨겨진 메시지',
		messageShown: '표시된 메시지 ',
		messageHidden: '숨겨진 메시지 ',
		userBlocked: '유저가 차단되었습니다',
		userUnBlocked: '차단이 해제되었습니다.',
		youAreSpeaker: '채팅을 할 수 있습니다! 말해보세요',
		youAreNotSpeaker: '관리자가 사용자를 스피커 목록에서 삭제했습니다.',
		userIsSpeaker: (name: string) => `${name}은 이제 스피커입니다.`,
		userNotSpeaker: (name: string) => `${name}은 더 이상 스피커가 아닙니다.`,
		yourUserNameInChat: (name: string) => `채팅에서 귀하의 이름은${name}입니다.`,
		messageRemoved: '메시지가 삭제되었습니다.',
		messageCopied: '텍스트 복사됨',
		obscenities: '다시 말하기',
		requestSent: '문의가 전송되었습니다.',
		requestDecline: '마이크 요청이 거부되었습니다.',
		youareblocked: (text: string) => `${text}로 인해 차단되었습니다. 채팅에 글을 쓸 수 없습니다.`,
		youareunblocked: '차단이 해제되었습니다. 채팅에서 메시지를 작성할 수 있습니다.',
		youAreModerator: '진행자입니다!',
		youAreNoModerator: '더 이상 진행자가 아닙니다.',
		micMuted: '마이크가 음소거되었습니다.',
		noInet: '인터넷에 연결되어 있지 않습니다.',
		messagePinned: '게시글 고정됨',
		messageUnpinned: '게시물이 고정되지 않음',
		pollPublished: '설문조사가 게시됨',
		pollPublishedError: '오류 발생! 나중에 다시 시도하세요',
		pollAddPic: '사진 추가',
		pollAddPicError: '다른 이미지 선택',
		pollDeleted: '투표 삭제됨',
		pollEnded: '투표 완료',
		sendStickerWhenSlowModeIsOn: (time: string) => `스티커가 전송될 때까지${time}을 기다려주세요.`,
		streamConnecting: '생방송에 연결하기...',
		linkProhibited: '채팅창에 링크를 보낼 수 없습니다.',
		phoneProhibited: '채팅에 전화번호를 보낼 수 없습니다.',
		profileHidden: '사용자가 자신의 프로필을 숨겼습니다.',
		imageSizeWarning: '10MB 이하의 이미지를 업로드 할 수 있습니다',
		imageBlocked: '운영자가 이미지 업로드를 차단했습니다',
		streamKeyCopied: '방송 키가 복사되었습니다.',
	},
	btns: {
		acceptandgo: '수락하고 계속',
		saveandgo: '저장하고 계속',
		save: '저장',
		unblock: '차단 해제',
		block: '차단',
		restoreAccount: '프로필 복원',
		close: '닫기',
	},
	coopyrights: 'Watchers.io 제공',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message' ? '메시지 불만 제기' : '위반 사항 신고하기',
			subtitle: '불만 사항은 익명으로 접수됩니다.',
			btns: {
				violence: '모욕, 협박, 욕설',
				provocations: '도발',
				personalDetails: '개인 데이터 유포',
				flood: '침범',
				spam: '스팸',
				fraudBegging: '사기, 구걸',
				other: '기타',
			},
		},
		ban: {
			title: '사용자 차단하기',
			subtitle: '차단 사유 지정하기',
			btns: {
				insults: '모욕, 협박, 욕설',
				personal: '개인 정보',
				flood: '침범',
				links: '타사 서비스 링크',
				violence: '폭력 위협',
				fraud: '사기 및 구걸 시도',
				nickname: '잘못된 닉네임',
				politicial: '정치적, 성적 및 기타 도발 행위',
				repeated: '반복적인 위반',
				auto: '채팅 사용자 관련 불만',
				spam: '스팸',
			},
		},
		waitingSpeakers: {
			text: '대기 중인 대화 상대가 없습니다 ',
			handsUp: '손을 들었습니다',
			waiting: '대기 중인 스피커',
		},
		poll: {
			supTitles: {
				typeQuiz: '정답을 입력하세요.',
				typeQuizMultiple: '올바른 옵션에 표시하기',
				typePoll: '의견 공유',
				typePollMultiple: '여러 옵션을 표시할 수 있습니다. ',
				default: '공개 투표',
			},
			btns: {
				reply: '회신',
				vote: '투표하기',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: '공개 투표',
				typePoll: '여러 답변',
				default: '공개 투표',
			},
			btns: {
				edit: '설문조사 수정하기',
				end: '완료',
			},
		},
		pollCreated: {
			titles: {
				created: '설문 조사 만들기',
				typeQuiz: '공개 투표',
				typePoll: '여러 답변',
				default: '공개 투표',
			},
		},
		changeName: {
			title: '채팅 이름 변경하기',
			subtitle: '관리자가 유저의 잘못된 이름으로 유저를 차단했습니다.',
		},
		temporaryAgreement: {
			title: '채팅 규칙',
			rules: {
				bePolite: {
					title: '예의 바르게 행동하기',
					text: '채팅에서 적대적이거나 음란한 언어는 금지되어 있습니다.',
				},
				beAttentive: {
					title: '조심하세요',
					text: '개인 정보를 공유하거나 다른 사람에게 개인 정보를 묻지 마세요. ',
				},
				notAdvertise: {
					title: '광고하지 마십시오',
					text: '의견이나 감정을 공유하세요.',
				},
			},
			btns: {
				allRules: '모든 규칙',
				acceptAndContinue: '수락하고 계속하기',
			},
		},
		slowMode: {
			title: '슬로우 모드',
			text: '참가자는 선택한 기간보다 더 자주 채팅에 메시지를 보낼 수 없습니다.',
			enable: '슬로우 모드 활성화',
			disable: '슬로우모드 끄기',
			sendTimeout: '시간 초과 전송',
		},
		slowModeTimeout: {
			title: '시간 초과 전송',
		},
		chatBot: {
			title: '챗봇 사용하기',
			rules: [
				{
					title: '인공 지능',
					text: '봇은 사람과의 커뮤니케이션을 시뮬레이션하고 그 지식을 바탕으로 정보를 제공합니다. 답변은 제한적일 수 있으며 사람을 대체할 수 없다는 점을 기억하세요.',
				},
				{
					title: '봇은 실수를 할 수 있습니다',
					text: '봇은 정보의 정확성과 신뢰성을 보장할 수 없습니다.',
				},
			],
			btns: {
				close: '닫기',
			},
		},
		shareBet: {
			title: '공유',
			noBetsTitle: '베팅을 찾을 수 없습니다.',
			noBetsText: '14일 이내에 이루어진 베팅은 공유할 수 있습니다.',
			btns: {
				share: '베팅 공유',
				copy: '베팅 반복하기:',
			},
			status: {
				null: '게임 참여 중',
				win: '승리',
				lose: '패배',
			},
			betTypeV1: {
				express: '나타내기',
				single: '싱글',
			},
			betTypeV2: {
				single: '싱글',
				multi: '다중',
				system: 'System',
			},
			totalFactor: '계수',
			bet: 'пари',
			showMore: '더 보기',
			betRepeated: '281.	베팅 반복: 명이 베팅을 반복했습니다. ',
		},
		record: {
			text: '생방송 녹화가 처리 중입니다...',
		},
		agoraDevices: {
			title: '오디오 설정',
			text: '올바른 마이크가 선택되었는지 확인하세요.',
		},
		translate: {
			title: '메시지 번역',
			chooseLanguage: '언어 선택',
			descr: '모든 채팅 메시지가 선택한 언어로 자동 번역됩니다.',
			btn: '번역 활성화',
		},
		streamSettings: {
			title: '스트림 설정',
			description: '비디오 인코더를 다운로드하고 설치하세요. 스트림 키를 설정에 입력하세요',
			shareScreen: {
				title: '화면 공유',
				description: '특별한 설정이 필요하지 않음',
			},
			rtmp: {
				title: '외부 스트림 소스',
				description: '추가 프로그램을 사용하여 스트리밍',
			},
			streamKey: '스트림 키',
			showStreamKey: '보기',
			hideStreamKey: '숨기기',
			btns: {
				continue: '계속',
				close: '닫기',
				ok: '완료',
			},
		},
	},
	pinnedMessage: {
		title: '고정된 메시지',
	},
	errorPage: {
		error: '오류',
		types: [
			// errorNumber 0
			{
				title: '사용자를 인식할 수 없습니다.',
				text: '채팅에 다시 들어가 보세요.',
			},
			// errorNumber 1
			{
				title: '채팅을 찾을 수 없습니다.',
				text: '재로그인 시도',
			},
			// errorNumber 2
			{
				title: '채팅을 로딩할 수 없습니다.',
				text: '재부팅 시도',
			},
			// errorNumber 3
			{
				title: '사용자를 인식할 수 없습니다.',
				text: '시크릿 모드 채팅을 사용할 수 없습니다.',
			},
			// errorNumber 4
			{
				title: '잘못된 닉네임',
				text: '프로필에서 이름을 변경한 후 다시 채팅에 참여하세요.',
			},
		],
	},
	tooltips: {
		emotions: '변경하려면 길게 누르기',
		badInet: '네트워크 연결 상태 불량',
		blockedUsers: '여기에서 차단된 사용자 목록을 볼 수 있습니다.',
		waitingSpeakers: '일부 참가자가 발언하기를 원합니다.',
		wantSpeak: '투표를 요청하려면 여기를 클릭하세요.',
		slowMode: {
			header: (time: string) =>
				`슬로우 모드가 활성화됩니다. 사용자는 하나의 메시지를<br> ${time}마다 보낼 수 있습니다.`,
			btnSendMessage: (time: string) => `매<br>마다 메세지를 보낼 수 있습니다${time}.`,
		},
		shareBet: '채팅에서 베팅 공유하기',
		translate: '메시지를 다른 언어로 번역',
	},
	poll: {
		toggleSubmenu: {
			create: '설문 조사 만들기',
			edit: '설문조사 수정하기',
			show: '투표 표시',
			end: '투표 완료',
		},
		tooltip: {
			btn: '투표하기',
		},
		settings: {
			title: '설정',
			titleAdditionally: '추가',
			switches: {
				quizMode: '퀴즈 모드',
				multipleChoice: '객관식',
				withPhoto: '사진으로 투표하기',
			},
		},
		question: {
			title: '질문',
			inputPlaceholder: '질문 입력',
		},
		message: {
			title: '투표되었습니다.',
		},
		answers: {
			title: '답변 옵션',
			warning: '최대 5개까지 답변을 추가할 수 있습니다.',
		},
		answer: {
			tooltip: '정답을 선택하세요.',
			inputPlaceholder: '답',
		},
		results: {
			text: '투표 결과',
			votes: '표',
			// words: ['표', '표', '표'],
		},
		btns: {
			addQuestion: '질문 추가하기',
			addAnswers: '답변 추가하기',
			addAnswer: '답변 추가하기',
			publish: '게시하기',
			vote: '투표하기',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: '계산하기',
				continue: '계속하기',
				checkConnection: '연결 확인',
				close: '닫기',
			},
			quality: {
				title: '음질 평가하기',
			},
			reason: {
				title: '문제가 발생했나요?',
			},
			connection: {
				title: '인터넷 연결상태를 테스트하세요. ',
				text: '연결상태 체크는1분 이상 소요되지 않으며 문제의 원인을 찾는 데 도움이 됩니다.',
			},
			statistics: {
				title: '통계 수집 중...',
			},
			done: {
				title: '감사합니다!',
				text: '무엇이 잘못되었는지 조사하여 문제를 해결하겠습니다.',
			},
		},
	},
	chatBot: {
		message: '봇에게 무엇이든 물어보세요...',
		warnings: {
			disabled: '연결이 끊겼습니다',
			overloaded: '과부하 상태입니다',
		},
	},
	audioReason: {
		nothear: '아무 소리도 들리지 않음',
		connection: '소리가 끊겼습니다.',
		microphone: '마이크를 사용할 수 없습니다.',
	},

	copybetting: {
		orderStatus: {
			won: '승리',
			lost: '패배',
			new: '새로운',
			pending: '추첨을 기다리는 중',
			cashedOut: '철수',
			boreDraw: '골 없이 무승부',
			cashback: 'Cashback',
			megaBetNew: 'MegaBet 새로운',
			megaBetCanceledByOperator: 'MegaBet 운영자에 의한 취소',
			megaBetCanceledByPlayer: 'MegaBet 플레이어에 의해 취소됨',
			megaBetCanceledBySystem: 'MegaBet 시스템에 의해 취소됨',
			betGamesPending: 'BetGames 추첨을 기다리는 중',
			betGamesWon: 'BetGames 승리',
			betGamesLost: 'BetGames 패배',
			paid: '유급의',
			initialWin: '초기 승리',
			autoBetCanceledBySystem: 'AutoBet 시스템에 의해 취소됨',
			autoBetCanceledByPlayer: 'AutoBet 플레이어에 의해 취소됨',
			autobetPending: 'AutoBet 추첨을 기다리는 중',
			goalAhead: '목표가 있을 거예요',
			canceled: '취소',
			return: '반품',
		},
		stakeStatus: {
			pending: '보류 중',
			won: '승리',
			lost: '패배',
			return: '반품',
			lostReturn: '패배 | 반품',
			wonReturn: '승리 | 반품',
			canceled: '취소',
			initialReturn: '발신 반환',
			pendingInitialReturn: '보류 중 | 발신 반환',
			wonInitialReturn: '승리 | 발신 반환',
		},
	},
	screenShare: {
		title: '화면 데모',
		stopSharing: '생방송 중지하기',
		modal: {
			title: '내 컴퓨터에서만 화면을 공유할 수 있습니다.',
			text: '컴퓨터에서 채팅을 열고 오른쪽 상단 코너에 있는 화면 공유 버튼을 클릭합니다.',
			btn: '로그인 링크 복사하기',
		},
	},
	threads: {
		title: '스레드',
		back: '뒤로',
		replies: ['답장', '답장들', '답장들'],
	},
	attach: {
		submenu: {
			sendImage: '이미지 전송',
			shareBet: '내기 공유',
			shareGamble: '상금 공유',
		},
		images: ['이미지', '이미지들', '이미지들'],
		addCaption: '캡션 추가',
	},
} as unknown as Dictionary;
