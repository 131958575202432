/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: 'Kelishuv',
		subTitle: 'Litsenzion kelishuv va maxfiylik shartnomasi',
		textFirst: 'Men shartlarni  ',
		textSecond: 'qabul qilaman va shaxsiy ma’lumotlarim',
		license: 'Litsenzion kelishuv',
		policy:
			'va Maxfiylik shartnomasida belgilangan shartlar asosida qayta ishlanishiga o‘z roziligimni beraman.',
	},
	controlPanel: {
		streamEnded: 'Efir tugadi',
		banAlert: 'Siz chatga yoza olmaysiz',
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? 'Stikerlar' : 'Emojilar',
	},
	accountDeletedScreen: {
		title: 'Profil o‘chirildi',
		text: (date: string) => `Siz parolingizni quyidagi holatgacha tiklashingiz mumkin: ${date}`,
	},
	nameScreen: {
		title: 'Chatdagi ism',
		inputPlaceholder: 'Ismingizni kiriting',
		nameError: 'Nojo’ya ism',
	},
	avatarScreen: {
		title: 'Avatar',
		label: 'Boshqasini tanlash::',
	},
	settingsScreen: {
		title: 'Sozlamalar',
		personal: 'Shaxsiy sozlamalar',
		room: 'Xona sozlamalari',
		profileBtn: 'Profil',
		languageBtn: 'Til',
		blockedBtn: 'Bloklanganlar',
		rules: 'Chat qoidalari',
		deleteProfile: 'Profilni o‘chirib tashlash',
		slowModeBtn: 'Sekin rejim',
		agora: {
			microphone: 'Mikrofon',
			playbackDevice: 'Tovush manbai',
		},
	},
	languageScreen: {
		title: 'Til va interfeys',
		messagesTranslate: 'Xabarlar tarjimasi',
		translateMessagesMode: 'Chatdagi xabarlarni tarjima qilish',
		translateMessagesTo: 'Chatdagi xabarlarni tarjima qilish:',
	},
	rulesScreen: {
		title: 'Chat qoidalari',
	},
	profileScreen: {
		title: 'Profil',
		selectAnotherPic: 'Boshqa avatarni tanlash',
		chatNameLabel: 'Chatdagi ism',
		changeName: 'o‘zgartirish',
	},
	blockedScreen: {
		title: 'Bloklanganlar',
		emptyListText: 'Bloklangan foydalanuvchilar yo‘q',
	},
	chatScreen: {
		inputPlaceholder: 'Xabar...',
		cantWrite: 'Siz chatga yoza olmaysiz',
		chatSubmenu: {
			hideMessage: 'Xabarlarni yashirish',
			showMessage: 'Xabarlarni ko‘rsatish',
			removeFromSpeakers: 'Spikerlikdan olib tashlash',
			setSpeaker: 'Spiker etib tayinlash',
			hideOnlyMessage: 'Xabarni yashirish',
			showOnlyMessage: 'Xabarni ko‘rsatish',
			reportMessage: 'Xabar yuzasidan shikoyat qilish',
			reportUser: 'Foydalanuvchining ustidan shikoyat qilish',
			muteSpeaker: 'Mikrofonni o‘chirish',
			blockUser: 'Bloklash',
			unblockUser: 'Blokdan chiqarish',
			reply: 'Javob berish',
			copy: 'Nusxa olish',
			edit: 'O‘zgartirish',
			delete: 'O‘chirish',
			pinMessage: 'Qadash',
			unpinMessage: 'Yechib olish',
			goToProfile: 'Profilga o‘tish',
			replyInThread: 'Thred’da muhokama qilish',
		},
		actionMessage: {
			editing: 'Tahrirlash',
			reply: 'Javob berish',
		},
		status: {
			top: 'Top',
		},
		translating: 'Tarjima qilyapmiz…',
	},
	chatUsersScreen: {
		title: 'Ishtirokchilar',
		edited: 'O‘zgartirilgan',
		hidden: 'yashirilgan',
		unreadMessages: 'O‘qilmagan xabarlar',
		usersSubmenu: {
			muteSpeaker: {
				title: 'Ishtirokchining ovozini pasaytirish',
				subtitle: 'Mikrofonni o‘chirish',
			},
			setSpeaker: {
				title: 'Spikerlikdan olib tashlash',
				subtitle: 'Faqat matnli chat',
			},
			removeFromSpeaker: {
				title: 'Spiker etib tayinlash',
				subtitle: 'Foydalanuvchi ovoz yordamida gaplashishi mumkin',
			},
			showMessages: {
				title: 'Xabarlarni ko‘rsatish',
				subtitle: 'Faqat ma’murga ko‘rinadi',
			},
			hideMessages: {
				title: 'Xabarlarni yashirish',
				subtitle: 'Faqat ma’murga ko‘rinadi',
			},
			blockUser: {
				title: 'Bloklash',
				subtitle: 'Stop-ro‘yxatga qo‘shish',
			},
			unblockUser: {
				title: 'Blokdan chiqarish',
				subtitle: 'Foydalanuvchini blokdan chiqaring',
			},
			blockUserByUser: {
				title: 'Bloklash',
				subtitle: 'Foydalanuvchining barcha xabarlarini yashirish',
			},
			unblockUserByUser: {
				title: 'Blokdan chiqarish',
				subtitle: 'Foydalanuvchining barcha xabarlarini ko‘rsatish',
			},
			reportUser: {
				title: 'Foydalanuvchining ustidan shikoyat qilish',
				subtitle: 'Shikoyat anonim tarzda jo‘natiladi',
			},
		},
		days: {
			today: 'Bugun',
			yesterday: 'Kecha',
		},
		chatCreated: 'Chat yaratildi,',
	},
	alerts: {
		btns: {
			unblock: 'Blokdan chiqarish',
			block: 'Bloklash',
			send: 'Jo‘natish',
			sendAndBlock: 'Jo‘natish va bloklash',
			show: 'Ko’rsatish',
			showAll: 'Barchasini ko’rsatish',
			cancel: 'Bekor qilish',
			yes: 'Ha',
			hide: 'Tanlanganlarni yashirish',
			hideAll: 'Barchasini yashirish',
			make: 'Bajarish',
			remove: 'Olib tashlash',
			delete: 'O‘chirish',
			deleteAccount: 'Profilni o‘chirib tashlash',
			close: 'Yopish',
			pin: 'Qadash',
			unpin: 'Yechib olish',
			edit: 'Tahrirlash',
			end: 'Tahrirlash',
			endAndShare: 'Yakunlash va ulashish',
			reset: 'Tozalash',
			rules: 'Chat qoidalari',
			contactus: 'Bizga yozing',
		},
		pinMessage: {
			title: 'Xabar qadalsinmi?',
		},
		unPinMessage: {
			title: 'Xabarni yechib olinsinmi?',
		},
		accountRestored: {
			title: 'Profil tiklandi',
		},
		closeApp: {
			title: 'Siz rostdan ham chiqib ketmoqchimisiz?',
		},
		removeMessage: {
			title: 'Xabar o’chirilsinmi?',
		},
		report: {
			title: 'Shikoyat jo‘natilsinmi?',
			subtitle:
				'Bir-biringizning xabarlaringizni ko‘rmaslik uchun siz ham foydalanuvchini bloklashingiz mumkin.',
		},
		banUser: {
			title: (name: string) => `Bloklash ${name}?`,
			subtitle: (text: string) => `Foydalanuvchi ${text} sababli bloklanadi `,
		},
		unBanUser: {
			title: (name: string) => `Blokdan chiqarish ${name}?`,
		},
		showUserMessages: {
			title: (name: string) => `${name}’dan kelgan xabarlar ko‘rsatilsinmi?`,
			subTitle: 'Ushbu foydalanuvchidan kelgan xabarlarni boshqa foydalanuvchilar ko‘ra oladilar',
		},
		hideUserMessages: {
			title: (name: string) => `${name}’dan kelgan xabarlar yashirilsinmi?`,
			subTitle: 'Ushbu foydalanuvchidan kelgan xabarlarni boshqa foydalanuvchilar ko‘ra olmaydilar',
		},
		showUserMessage: {
			title: (name: string) => `${name}’dan kelgan xabar ko‘rsatilsinmi?`,
			subTitle: 'Ushbu foydalanuvchidan kelgan xabarni boshqa foydalanuvchilar ko‘ra oladilar',
		},
		hideUserMessage: {
			title: (name: string) => `${name}’dan kelgan xabar yashirilsinmi?`,
			subTitle: 'Ushbu foydalanuvchidan kelgan xabarni boshqa foydalanuvchilar ko‘ra olmaydilar',
		},
		blockUserInRoom: {
			title: (name: string) => `Bloklash ${name}?`,
			subTitle: 'Foydalanuvchi ushbu xonada qatnasha olmaydi',
		},
		unBlockUserInRoom: {
			title: (name: string) => `Blokdan chiqarish ${name}?`,
			subTitle: 'Foydalanuvchi ushbu xonada qatnasha oladi',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `Bloklash ${name}?`,
			subTitle: 'Siz bir-biringizning xabarlaringizni ko’rmaysizlar',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `${name} spiker etib tayinlansinmi?`,
			unTitle: (name: string) => `${name} spikerlikdan olib tashlansinmi?`,
		},
		banInApp: {
			title: 'administrator sizni bloklagan',

			subtitle: (bannedUntil: string) => `Siz ${bannedUntil} gacha bloklandingiz `,
			permanent: 'Siz umrbod bloklandingiz',
		},
		banInAppBadNickname: {
			title: 'Nojoiz ism sababli ma’mur sizni blokladi',
			subtitle: 'Profilingizga kiring, nikneymni o‘zgartiring va chatga qaytadan kiring.',
		},
		deleteAccount: {
			title: 'Profilni o‘chirish',
			subTitle:
				'Barcha ma’lumotlar o‘chiriladi. Siz profilingizni 30 kun davomida qayta tiklashingiz mumkin.',
		},
		pollEdit: {
			title: 'So’rovnomani tahrirlash kerakmi?',
			subTitle: 'Foydalanuvchilarning javoblari tozalanadi',
		},
		pollReset: {
			title: 'Tozalansinmi?',
			subTitle: 'So’rovnoma saqlab qolinmaydi',
		},
		pollEnd: {
			title: 'Siz rostdan ham so‘rovnomani yakunlamoqchimisiz?',
		},
		pollDelete: {
			title: 'So’rovnoma o‘chirilsinmi?',
		},
		agoraCheck: {
			updateIOSText:
				'Ilovaning barcha imkoniyatlaridan foydalanish uchun iOS versiyasini yangilang.',
			allowAccess: {
				title: 'Baland va tiniq tovushda',
				text: 'Mikrofon va dinamikdan foydalanish uchun ilova sozlamalariga kiring va ulardan foydalanishga ruxsat bering.',
			},
			microphoneAndSpeakerText: 'Mikrofon va dinamik faqat 14.3-versiyadan boshlab mavjud.',
			microphoneText: 'Mikrofon 14.3-versiyada mavjud',
			btn: 'Tushunarli',
		},
	},
	eventScreen: {
		title: 'Rejalashtirilgan voqea',
		titleSoon: 'Efir tez orada boshlanadi',
		subtitle: 'Chat tez orada ochiladi; kutib turing.',
		days: 'kunlar',
		hours: 'soatlar',
		minutes: 'daqiqalar',
		seconds: 'soniyalar',
		startTitle: 'Boshlanishi',
	},
	toasts: {
		reciveComplaint: 'Biz shikoyatingizni oldik',
		setSpeakerRoleForUser: 'Foydalanuvchi spiker etib tayinlandi',
		unSpeakerRoleForUser: 'Foydalanuvchi spikerlar ro‘yxatidan olib tashlangan',
		messagesShown: 'Xabarlar ko‘rsatilgan',
		messagesHidden: 'Xabarlar yashirilgan',
		messageShown: 'Xabar ko‘rsatilgan',
		messageHidden: 'Xabar yashirilgan',
		userBlocked: 'Foydalanuvchi bloklangan',
		userUnBlocked: 'Foydalanuvchi blokdan chiqarilgan',
		youAreSpeaker: 'Siz spikersiz! Biror narsa ayting',
		youAreNotSpeaker: 'Ma’mur sizni spikerlar ro‘yxatidan olib tashladi',
		userIsSpeaker: (name: string) => `Endi ${name} spiker`,
		userNotSpeaker: (name: string) => `${name} boshqa spiker emas`,
		yourUserNameInChat: (name: string) => `Sizning chatdagi ismingiz — ${name}`,
		messageRemoved: 'Xabar o‘chirilgan',
		messageCopied: 'Matn nusxalangan',
		obscenities: 'Parafraza qilishga urinib ko‘ring',
		requestSent: 'So‘rov jo‘natildi',
		requestDecline: 'Mikrofon bo’yicha so‘rovingiz rad etildi',
		youareblocked: (text: string) => `Sizni ${text} sababli bloklashdi. Siz chatga yoz olmaysiz`,
		youareunblocked: 'Sizni blokdan chiqarishdi. Siz chatda xabar yoza olasiz.',
		youAreModerator: 'Siz moderatorsiz!',
		youAreNoModerator: 'Siz endi moderator emassiz',
		micMuted: 'Sizning mikrofoningiz o‘chirilgan',
		noInet: 'Internetga ulanish mavjud emas',
		messagePinned: 'Xabar qadalgan',
		messageUnpinned: 'Xabar yechib olingan',
		pollPublished: 'So‘rovnoma chop etilgan',
		pollPublishedError: 'Xato! Keyinroq urinib ko‘ring',
		pollAddPic: 'Fotosurat qo‘shing',
		pollAddPicError: 'Boshqa tasvirni tanlang',
		pollDeleted: 'So‘rovnoma o‘chirib tashlangan',
		pollEnded: 'So‘rovnoma yakunlangan',
		sendStickerWhenSlowModeIsOn: (time: string) => `Stikerni jo‘natish uchun ${time} kutib turing,`,
		streamConnecting: 'Efirga ulanmoqdamiz…',
		linkProhibited: 'Havolalarni chatga yubora olmaysiz.',
		phoneProhibited: 'Telefon raqamlarini chatga yubora olmaysiz.',
		profileHidden: 'Foydalanuvchi profilini yashirib qo‘ygan.',
		imageSizeWarning: '10 MB’dan katta bo‘lmagan rasmni yuklash mumkin',
		imageBlocked: 'Moderator tasvir jo‘natishni bloklab qo‘ygan',
		streamKeyCopied: 'Yayım açarı kopyalandı',
	},
	btns: {
		acceptandgo: 'Qabul qilish va davom etish',
		saveandgo: 'Saqlash va davom etish',
		save: 'Saqlash',
		unblock: 'Blokdan chiqarish',
		block: 'Bloklash',
		restoreAccount: 'Parolni tiklash',
		close: 'Yopish',
	},
	coopyrights: 'Watchers.io asosida ishlaydi',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message'
					? 'Xabar yuzasidan shikoyat qilish'
					: 'Qoidabuzarlik haqida xabar berish',
			subtitle: 'Shikoyat anonim tarzda jo‘natiladi',
			btns: {
				violence: 'Haqoratlar, do‘q-po‘pisa, so‘kinish',
				provocations: 'Haqoratlar, do‘q-po‘pisa, so‘kinish',
				personalDetails: 'Shaxsiy ma’lumotlar',
				flood: 'Flud',
				spam: 'Spam',
				fraudBegging: 'Firibgarlik, tilanchilik',
				other: 'Boshqa',
			},
		},
		ban: {
			title: 'Foydalanuvchini bloklash',
			subtitle: 'Bloklash sababini ko‘rsating',
			btns: {
				insults: 'Haqoratlar, do‘q-po‘pisa, so‘kinish',
				personal: 'Shaxsiy ma’lumotlarni tarqatish',
				flood: 'Flud',
				links: 'Boshqa servislarga havolalar',
				violence: 'Zo’ravonlik bilan qo‘rqitish',
				fraud: 'Firibgarlikka va tilanchilikka urinish',
				nickname: 'Nojoiz nikneym',
				politicial: 'Siyosiy, jinsiy va boshqa ig‘volar',
				repeated: 'Takroriy qoidabuzarlik',
				auto: 'Chat foydalanuvchilarining shikoyatlari',
				spam: 'Spam',
			},
		},
		waitingSpeakers: {
			text: 'Sizda kutayotgan spikerlar mavjud emas',
			handsUp: 'Qo‘l ko‘tardilar',
			waiting: 'Kutayotgan spikerlar',
		},
		poll: {
			supTitles: {
				typeQuiz: 'To‘g‘ri javob bering',
				typeQuizMultiple: 'To‘g‘ri variantlarni belgilang',
				typePoll: 'Fikringiz bilan bo‘lishing',
				typePollMultiple: 'Bir necha variantlarni belgilash mumkin',
				default: 'Ommaviy so‘rovnoma',
			},
			btns: {
				reply: 'Javob berish',
				vote: 'Ovoz berish',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: 'Viktorina',
				typePoll: 'Bir necha javoblar',
				default: 'Ommaviy so‘rovnoma',
			},
			btns: {
				edit: 'So‘rovnomani tahrirlash',
				end: 'Yakunlash',
			},
		},
		pollCreated: {
			titles: {
				created: 'So’rovnomani yaratish',
				typeQuiz: 'Viktorina',
				typePoll: 'Bir necha javoblar',
				default: 'Ommaviy so‘rovnoma',
			},
		},
		changeName: {
			title: 'Chatdagi ismni o’zgartiring',
			subtitle: 'Nojoiz nikneym sababli bloklandingiz',
		},
		temporaryAgreement: {
			title: 'Chat qoidalari',
			rules: {
				bePolite: {
					title: 'Xushmuomala bo‘ling',
					text: 'Chatda ziddiyatga sabab bo‘luvchi va beadab so‘zlarni ishlatish taqiqlanadi',
				},
				beAttentive: {
					title: 'E’tiborli bo‘ling',
					text: 'Shaxsiy ma’lumotlaringiz bilan bo‘lishmang va boshqalardan ham so‘ramang.',
				},
				notAdvertise: {
					title: 'Reklama bilan shug‘ullanmang',
					text: 'Yaxshisi, fikr-mulohazalaringiz va emotsiyalaringiz bilan bo‘lishing',
				},
			},
			btns: {
				allRules: 'Barcha qoidalar',
				acceptAndContinue: 'Qabul qilish va davom etish',
			},
		},
		slowMode: {
			title: 'Sekin rejim',
			text: 'Ishtirokchilar chatga xabarlarni belgilangan vaqt oralig‘idan tezroq jo‘nata olmaydilar',
			enable: 'Sekin rejimni yoqish',
			disable: 'Sekin rejimni  o‘chirish',
			sendTimeout: 'Jo’natish vaqti tugadi',
		},
		slowModeTimeout: {
			title: 'Jo’natish vaqti tugadi',
		},
		chatBot: {
			title: 'Chat-botdan foydalanish',
			rules: [
				{
					title: 'Sun’iy intellekt',
					text: 'Bot odamlar o‘rtasidagi muloqotga taqlid qiladi va o‘z bilimidan kelib chiqqan holda ma’lumotlarni taqdim etadi. Ammo, esda tuting! Uning javoblari chegaralangan bo‘lishi mumkin va u insonning o‘rnini bosa olmaydi.',
				},
				{
					title: '2.	Bot xato qilishi mumkin',
					text: 'Bot ma’lumotning aniqligi va ishonchliligiga kafolat bera olmaydi.',
				},
			],
			btns: {
				close: 'Yopish',
			},
		},
		shareBet: {
			title: "Baham ko'ring",
			noBetsTitle: 'Sizning garovlaringizni topa olmadik',
			noBetsText: '14 kun davomida bog‘langan garovni ulashishingiz mumkin',
			btns: {
				share: 'Stavkalarni ulashish',
				copy: 'Qaytadan garov o’ynash',
			},
			status: {
				null: 'O‘yinda',
				win: 'Yutuq',
				lose: 'Yo‘qotish',
			},
			betTypeV1: {
				express: 'Ekspress',
				single: 'Bir martalik',
			},
			betTypeV2: {
				single: 'Bir martalik.',
				multi: 'bir nechta',
				system: 'System',
			},
			totalFactor: 'Koeff',
			bet: 'garov',
			showMore: 'Yana ko‘rsatish',
			betRepeated: 'Garovni takrorladi',
		},
		record: {
			text: 'Yozib olingan efir qayta ishlanmoqda…',
		},
		agoraDevices: {
			title: 'Audioni sozlash',
			text: 'To‘g‘ri mikrofon tanlanganini tekshirib ko‘ring',
		},
		translate: {
			title: 'Xabarlar tarjimasi',
			chooseLanguage: 'Tilni tanlash',
			descr: 'Chatdagi barcha xabarlar avtomatik tarzda tanlangan tilga tarjima qilinadi.',
			btn: 'Tarjimani yoqish',
		},
		streamSettings: {
			title: 'Oqim sozlamalari',
			description:
				'Video kodlovchini yuklab oling va o‘rnating. Uning sozlamalariga oqim kalitini kiriting',
			shareScreen: {
				title: 'Ekranni ulashish',
				description: 'Maxsus sozlamalar talab qilinmaydi',
			},
			rtmp: {
				title: 'Tashqi oqim manbai',
				description: 'Translyatsiya uchun qo‘shimcha dasturdan foydalaning',
			},
			streamKey: 'Oqim kaliti',
			showStreamKey: 'Ko‘rsatish',
			hideStreamKey: 'Yopish',
			btns: {
				continue: 'Davom etish',
				close: 'Yopish',
				ok: 'Tayyor',
			},
		},
	},
	pinnedMessage: {
		title: 'Qadalgan xabar',
	},
	errorPage: {
		error: 'Xato!',
		types: [
			// errorNumber 0
			{
				title: 'Biz sizni tanimayapmiz',
				text: 'Chatga qayta kirishga urinib ko‘ring',
			},
			// errorNumber 1
			{
				title: 'Chatni topa olmayapmiz',
				text: 'Qayta kirishga urinib ko‘ring',
			},
			// errorNumber 2
			{
				title: 'Chatni yuklashning iloji yo‘q',
				text: 'Qayta yuklashga urinib ko‘ring',
			},
			// errorNumber 3
			{
				title: 'Biz sizni tanimayapmiz',
				text: 'Inkognito reimidagi chat mavjud emas',
			},
			// errorNumber 4
			{
				title: 'Nojoiz nikneym',
				text: 'Profilingizga kiring, nikneymni o‘zgartiring va chatga qaytadan kiring.',
			},
		],
	},
	tooltips: {
		emotions: 'Almashtirish uchun bosib turing',
		badInet: 'Tarmoq bilan aloqa yaxshi emas',
		blockedUsers: 'Bloklangan foydalanuvchilar ro‘yxatini bu yerda ko‘rishingiz mumkin.',
		waitingSpeakers: 'Ishtirokchilardan biri gapirmoqchi.',
		wantSpeak: 'Nutq huquqini olish uchun bosing.',
		slowMode: {
			header: (time: string) =>
				`“Sekin rejim” yoqilgan. Foydalanuvchilar <br>har {time} da bitta xabar jo‘natishlari mumkin. Bu qoida moderatorlarga tegishli emas`,
			btnSendMessage: (time: string) => `Siz xabarlar jo’natishingiz mumkin: <br> har soniyada`,
		},
		shareBet: 'Chatda stavkalarni ulashish',
		translate: 'Xabarlarni boshqa tilga tarjima qiling',
	},
	poll: {
		toggleSubmenu: {
			create: 'So’rovnomani yaratish',
			edit: 'So’rovnomani tahrirlash',
			show: 'So’rovnomani ko‘rsatish',
			end: 'So’rovnomani yakunlash',
		},
		tooltip: {
			btn: 'Ovoz berish',
		},
		settings: {
			title: 'Sozlamalar',
			titleAdditionally: 'Qo‘shimcha',
			switches: {
				quizMode: 'Viktorina rejimi',
				multipleChoice: 'Bir nechta variantlarni tanlash',
				withPhoto: 'Fotosuratli so‘rovnoma',
			},
		},
		question: {
			title: 'Savol',
			inputPlaceholder: 'Savolni kiriting',
		},
		message: {
			title: 'Siz ovoz berdingiz',
		},
		answers: {
			title: 'Javob variantlari',
			warning: 'Siz 5 tagacha javob qo‘shishingiz mumkin',
		},
		answer: {
			tooltip: 'To‘g‘ri javobni tanlang',
			inputPlaceholder: 'Javob',
		},
		results: {
			text: 'Ovoz berish natijalari',
			votes: 'ta ovoz',
			// words: ['ta ovoz', 'ta ovoz', 'ta ovoz'],
		},
		btns: {
			addQuestion: 'Savol qo‘shish',
			addAnswers: 'Javoblar qo‘shish',
			addAnswer: 'Javob qo‘shish',
			publish: 'Chop etish',
			vote: 'Ovoz bering',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: 'Baholash',
				continue: 'Davom etish',
				checkConnection: 'Aloqani tekshirish',
				close: 'Yopish',
			},
			quality: {
				title: 'Tovush sifatini baholang',
			},
			reason: {
				title: 'Nima bo‘ldi?',
			},
			connection: {
				title: 'Ulanish sifatini tekshirib ko’ring',
				text: 'Tekshiruv bir daqiqadan oshmaydi va muammoning sababini topishga yordam beradi',
			},
			statistics: {
				title: 'Statistik ma’lumotlarni yig‘yapmiz…',
			},
			done: {
				title: 'Rahmat!',
				text: 'Biz nima yuz berganini o’rganib chiqmiz va albatta tuzatamiz.',
			},
		},
	},
	chatBot: {
		message: 'Botdan biror narsa so‘rang…',
		warnings: {
			disabled: 'Meni tarmoqdan uzishdi',
			overloaded: 'Tizim to‘lib ketdi',
		},
	},
	audioReason: {
		nothear: 'Hech narsa eshitilmadi',
		connection: 'Tovush uzilib qoldi',
		microphone: 'Mikrofondan foydalanishning imkoni bo‘lmadi',
	},

	copybetting: {
		orderStatus: {
			won: 'Yutuq',
			lost: 'Yo‘qotish',
			new: 'Yangi',
			pending: 'Kutilmoqda',
			cashedOut: 'Olib tashlash',
			boreDraw: 'Golsiz durang',
			cashback: 'Cashback',
			megaBetNew: 'MegaBet New',
			megaBetCanceledByOperator: 'MegaBet Operator tomonidan bekor qilish',
			megaBetCanceledByPlayer: 'MegaBet O‘yinchi tomonidan bekor qilindi',
			megaBetCanceledBySystem: 'MegaBet Tizim tomonidan bekor qilindi',
			betGamesPending: 'BetGames Kutilmoqda',
			betGamesWon: 'BetGames Yutuq',
			betGamesLost: 'BetGames Yo‘qotish',
			paid: "To'langan",
			initialWin: 'Chiquvchi qaytarmalar',
			autoBetCanceledBySystem: 'AutoBet Tizim tomonidan bekor qilish',
			autoBetCanceledByPlayer: "AutoBet O'yinchi tomonidan bekor qilish",
			autobetPending: 'AutoBet Kutilmoqda',
			goalAhead: "Maqsad bo'ladi",
			canceled: 'Bekor qilish',
			return: 'Qaytish',
		},
		stakeStatus: {
			pending: 'Kutilmoqda',
			won: 'Yutuq',
			lost: 'Yo‘qotish',
			return: 'Qaytish',
			lostReturn: 'Yo‘qotish | Qaytish',
			wonReturn: 'Yutuq | Qaytish',
			canceled: 'Bekor qilish',
			initialReturn: 'Chiquvchi qaytarmalar',
			pendingInitialReturn: 'kutilmoqda | Chiquvchi qaytarmalar',
			wonInitialReturn: 'Yutuq | Chiquvchi qaytarmalar',
		},
	},
	screenShare: {
		title: 'Ekran namoyishi',
		stopSharing: 'Efirni to‘xtatish',
		modal: {
			title: 'Ekran bilan faqat kompyuter orqali bo‘lishish mumkin',
			text: 'Kompyuterda chatni oching va yuqori o‘ng burchakdagi “Ekran namoyishi” tugmasini bosing.',
			btn: 'Kirish havolasini nusxalashа',
		},
	},
	threads: {
		title: 'Tred',
		back: 'Orqaga',
		replies: ['javob', 'javoblar', 'javoblar'],
	},

	attach: {
		submenu: {
			sendImage: 'Tasvirni jo‘natish',
			shareBet: 'Stavka bilan bo‘lishish',
			shareGamble: 'Yutuq bilan bo‘lishish',
		},
		images: ['tasvir', 'tasvirlar', 'tasvirlar'],
		addCaption: 'Imzoni qo‘shish',
	},
} as unknown as Dictionary;
