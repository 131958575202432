import {Gamble} from 'models/gamble';
import {FunctionComponent} from 'react';

import './gamble.scss';
import SocketIoServices from 'services/SocketIoServices';
import {useLocalObservable} from 'mobx-react-lite';
import roomService from 'store/roomService';
import modalService from 'store/modalService';
import {getLocaleDateMessage} from 'utils/message';
import appService from 'store/appService';
import useL10n from 'l10n/useL10n';
import threadService from 'store/threadService';
import MessageType from 'models/enums/MessageType.enum';
import {LinkOpenType} from 'models/enums/LinkOpenType.enum';
import gambleService from 'store/gambleService';

interface IGamble {
	gamble: Gamble;
	isShareGambleModal?: boolean;
	isMessage?: boolean;
}

const GambleItem: FunctionComponent<IGamble> = function GambleItem({
	gamble,
	isShareGambleModal,
	isMessage,
}) {
	const {chatUsersScreen} = useL10n();

	const {roomId} = useLocalObservable(() => roomService);
	const {toggleShareGambleModalVisible, toggleWidgetPreviewModalVisible} = useLocalObservable(
		() => modalService
	);
	const {language, appGambleOpenType, appEnableWidgetText} = useLocalObservable(() => appService);
	const {threadMessageId, currentThreadId} = useLocalObservable(() => threadService);
	const {setCurrentGambleForShare} = useLocalObservable(() => gambleService);

	const shareClickHandler = () => {
		if (appEnableWidgetText) {
			setCurrentGambleForShare(gamble);
			toggleWidgetPreviewModalVisible(true);
			toggleShareGambleModalVisible(false);
			return;
		}

		if (threadMessageId && currentThreadId && currentThreadId === 'new') {
			SocketIoServices.createThread({threadMessageId, messageType: MessageType.GAMBLE, gamble});
			toggleShareGambleModalVisible(false);
			return;
		}
		if (roomId)
			SocketIoServices.emitShareGamble({
				externalRoomId: currentThreadId || roomId,
				gamble,
			});
		toggleShareGambleModalVisible(false);
	};

	const linkPostMessage = () => {
		const msg = {
			type: 'link',
			body: {
				action: 'open',
				data: {
					link: gamble.gameUrl,
				},
			},
		};

		if (typeof WatchersChannel !== 'undefined') {
			WatchersChannel.postMessage(JSON.stringify(msg));
			return;
		}
		if ((window as any).webkit?.messageHandlers) {
			(window as any).webkit.messageHandlers.WatchersChannel?.postMessage(JSON.stringify(msg));
			return;
		}

		window.parent.postMessage(msg, '*');
	};

	const linkClickHandler = () => {
		if (appGambleOpenType === LinkOpenType.NEW_WINDOW) {
			window.open(gamble.gameUrl, '_blank');
		}
		if (appGambleOpenType === LinkOpenType.CURRENT_WINDOW) {
			window.open(gamble.gameUrl, '_self');
		}
		if (appGambleOpenType === LinkOpenType.POST_MESSAGE) {
			linkPostMessage();
		}
	};

	return (
		<div className='gamble'>
			{isShareGambleModal && (
				<button type='button' onClick={shareClickHandler} className='gamble__link'>
					{' '}
				</button>
			)}
			{gamble.gameUrl && !isShareGambleModal && (
				<button type='button' onClick={linkClickHandler} className='gamble__link'>
					{' '}
				</button>
			)}
			<div className='gamble__wrapper'>
				{gamble.iconUrl && (
					<div className='gamble__pic'>
						<img src={gamble.iconUrl} alt='' />
					</div>
				)}
				<div className='gamble__content'>
					<div className='gamble__info'>
						<div className='gamble__info-wrap'>
							{gamble.title && <span className='gamble__title'>{gamble.title}</span>}
							{gamble.text && <span className='gamble__text'>{gamble.text}</span>}
						</div>
						{gamble.buttonText && (
							<button type='button' className='gamble__button'>
								{gamble.buttonText}
							</button>
						)}
					</div>
				</div>
			</div>
			{gamble.timestamp && !isMessage && (
				<div className='gamble__footer'>
					<div className='gamble__time'>{`${getLocaleDateMessage(
						gamble.timestamp,
						language,
						chatUsersScreen.days,
						true
					)} `}</div>
				</div>
			)}
		</div>
	);
};

export default GambleItem;
