/* eslint-disable max-lines */
import {qs, containsClass, getParent, getParentByAttribute} from 'utils/helpers';
import {START_NUMBER_OF_MESSAGES} from 'constants/constants';
import {AgoraStatus} from 'models/enums/AgoraStatus.enum';
import UserRole from 'models/enums/UserRole.enum';
import RoomStatus from 'models/enums/RoomStatus.enum';
import {AppScreens} from 'models/enums/AppScreens.enum';
import NetworkQuality from 'models/enums/NetworkQuality.enum';
import {OnboardStage} from 'models/enums/OnboardStage.enum';
import useBanUsers from 'hooks/useBanUsers';
import useChatActions from 'hooks/useChatActions';
import useStickers from 'hooks/useStickers';
import appService from 'store/appService';
import userService from 'store/userService';
import roomServices from 'store/roomService';
import pollServices from 'store/pollService';
import toastService from 'store/toastService';
import modalService from 'store/modalService';
import slowModeService from 'store/slowModeService';
import alertService from 'store/alertService';
import hintService from 'store/hintService';
import offerService from 'store/offerService';
import playerService from 'store/playerService';
import badgeService from 'store/badgeService';
import tooltipsService from 'store/tooltipsService';
import agoraService from 'store/agoraService';

import SocketIoServices from 'services/SocketIoServices';
import useSettings from 'hooks/useSettings';
import useAgora from 'hooks/useAgora';
import useUser from 'hooks/useUser';
import useSocketsHandlers from 'hooks/useSocketsHandlers';
import useAnalytics from 'hooks/useAnalytics';
import useSendEventWithTimeouts from 'hooks/useSendEventWithTimeouts';
import useHints from 'hooks/useHints';
import useTalkers from 'hooks/useTalkers';
import useWelcome from 'hooks/useWelcome';
import useTranslation from 'hooks/useTranslation';
import {FunctionComponent, useCallback, useEffect} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import useL10n from 'l10n/useL10n';
import {Modals} from 'components/modals';
import {PollToggle} from 'components/poll/pollToggle';
import {PollVotesAnim} from 'components/poll/pollVotesAnim';
import {ToastIconsName} from 'components/toasts/ToastIcons';
import {Preloader} from 'components/preloader';
import {ToastMessageColor} from 'components/toasts/Toast';
import {isDesktop} from 'react-device-detect';

import {Chat} from './components/chat';
import {MainBottom} from './components/mainBottom';
import {EmotionsAnim} from './components/emotionsAnim';
import {Event} from './components/event';

const HTMLNode = document.querySelector('html');

interface IMain {
	isStreamVideo?: boolean;
}
const Main: FunctionComponent<IMain> = function Main({isStreamVideo}) {
	const {accessToken, userData, isUserExternalIdCorrect} = useLocalObservable(() => userService);
	const {
		roomId,
		roomName,
		agoraCreds,
		myTalker,
		roomData,
		removeMessages,
		updateActiveVoice,
		restApiDataLoaded,
		toggleRestApiDataLoaded,
	} = useLocalObservable(() => roomServices);
	const {
		project,
		appApiUrls,
		appReadOnly,
		language,
		appVoice,
		deletedState,
		changeCurrentScreen,
		changeInternetConnectionStatus,
		setNetworkQuality,
		networkQuality,
		agoraStatus,
		changeAgoraStatus,
		setAgoraMicrophoneActive,
		agoraMicrophones,
		setAgoraMicrophones,
		appEnableOnboarding,
		appAgreementAndRulesVisible,

		appEnableAgora,
		isAgoraLoaded,
		appEnableWelcome,
		appUserCounterButtonVisible,
		appEnableMessageTranslation,
		translateLangs,
		enableRoomSpeak,
		enableScreenSharing,
		appEnableSubscriptionsFilter,
		isStand,
		appEnableFullscreenOption,
	} = useLocalObservable(() => appService);
	const {
		hideAllModals,
		toggleOnboardModal,
		toggleChangeNameModal,
		recordingModalVisible,
		welcomeModalVisible,
		offerModalVisible,
		toggleAgoraDevicesModalVisible,
	} = useLocalObservable(() => modalService);
	const {poll} = useLocalObservable(() => pollServices);
	const {addToast, hideToastByMessage} = useLocalObservable(() => toastService);
	const {
		onUserJoinedHandler,
		onSuccessfullyJoined,
		onSuccessfullyJoinedThread,
		onUserLeftHandler,
		onGetMessageHandler,
		onMessageNotSentHandler,
		onMessageEditedHandler,
		onMessagesDeletedHandler,
		onMessagesVisibleHandler,
		onMessageVisibleHandler,
		talkerBanSetHandler,
		onUserBanSetHandler,
		reciveChangeRole,
		onChangeModerHandler,
		onHandToggledHandler,
		reciveAgoraCredsHandler,
		onEmotionHandler,
		onRoomSpeakSetHandler,
		onRoomStatusSet,
		onMuteSetHandler,
		onUserUpdatedHandler,
		onMessagePin,
		onPollCreatedHandler,
		onPollUpdatedHandler,
		onPollDeletedHandler,
		onPollEndedHandler,
		onVoteCreatedHandler,
		onBlockSetHandler,
		onRemoveMessageHandler,
		onReactionCreatedHandler,
		onReactionDeletedHandler,
		onRoomSlowmodeSetHandler,
		onRoomSlowmodeDelaySetHandler,
		onSettingsUpdatedHandler,
		onBetEditedHandler,
		onGetOffersHandler,
		onMessageUnpinnedHandler,
	} = useSocketsHandlers();
	const {hideAlert} = useLocalObservable(() => alertService);
	const {togglleSlowMode} = useLocalObservable(() => slowModeService);
	const {hintsViewed} = useLocalObservable(() => hintService);
	const {offer, offers} = useLocalObservable(() => offerService);
	const {setPlayPressed, setPausePressed, setRewind} = useLocalObservable(() => playerService);
	const {badgePopupArray} = useLocalObservable(() => badgeService);
	const {setTranslateTooltipVisible} = useLocalObservable(() => tooltipsService);
	const {isFullScreenVideo, setIsScreenSharing, setIsMyTalkerShareScreen} = useLocalObservable(
		() => agoraService
	);
	const {getBanUsersWithServices, handleBans} = useBanUsers();
	const {getMessagesWithServices, getPollWithServices} = useChatActions();
	const {getStickersWithServices} = useStickers();
	const {getRulesWithServices, getChatBotWithServices, getGreetingWithServices} = useSettings();
	const {
		getMicrophones,
		getPlaybackDevices,
		initAgora,
		destroyAgora,
		checkForSupportMicrophoneAndSpeaker,
		setMuteClientAudio,
		clearAgoraStatsInterval,
		initAgoraStats,
	} = useAgora();
	const {getWelcomeWithServices} = useWelcome();

	const translations = useL10n();
	const {
		userExtraDataCheckAgreementAndChatRules,
		userExtraDataCheckWelcome,
		userExtraDataCheckOffer,
		userExtraDataCheckTranslateMode,
		userExtraDataCheckBadge,
	} = useUser();
	const {sendAnalytics, sendAgoraAnalytics} = useAnalytics();
	const {clearSessionTime} = useSendEventWithTimeouts();
	const {addHintToElement, removeHintFromElement} = useHints();
	const {getTalkersCount, getSpeakers, getWaitingSpeakers, getSubscriptions} = useTalkers();
	const {getTranslateLangs} = useTranslation();

	const myTalkerIsModer = !!myTalker?.isModer || !!userData?.isModer;
	const isVisiblePollToggle = myTalkerIsModer || (!myTalkerIsModer && poll);
	const isFullScreenMode = appEnableFullscreenOption && isFullScreenVideo && isDesktop;

	const mainPageClasses = classNames('main-page', {
		'main-page--show-record': recordingModalVisible,
		'main-page--stream-video': isStreamVideo,
		'main-page--fullscreen-mode': isFullScreenMode,
	});

	const emitLeaveBySocket = () => {
		if (roomId) {
			SocketIoServices.emitLeave(roomId);
		}
	};

	const onVOD = (id: number, level: number) => {
		updateActiveVoice(id, level > 20);
	};

	const subscribeOnSocketMessages = async () => {
		getBanUsersWithServices();
		if (roomId) {
			SocketIoServices.emitJoin(roomId, language, roomName);
		}

		SocketIoServices.reciveAgoraCreds(reciveAgoraCredsHandler);
		SocketIoServices.onUserJoined(onUserJoinedHandler);
		SocketIoServices.onSuccessfullyJoined(onSuccessfullyJoined);
		SocketIoServices.onSuccessfullyJoinedThread(onSuccessfullyJoinedThread);
		SocketIoServices.onGetOffers(onGetOffersHandler);
		SocketIoServices.onUserLeft(onUserLeftHandler);
		SocketIoServices.onGetMessage(onGetMessageHandler);
		SocketIoServices.onMessageNotSent(onMessageNotSentHandler);
		SocketIoServices.onMessageEdited(onMessageEditedHandler);
		SocketIoServices.onMessagesDeleted(onMessagesDeletedHandler);
		SocketIoServices.onMessagesVisibleSet(onMessagesVisibleHandler);
		SocketIoServices.onMessageVisibleSet(onMessageVisibleHandler);
		SocketIoServices.onTalkerSetBan(talkerBanSetHandler);
		SocketIoServices.onUserBanSet(onUserBanSetHandler);
		SocketIoServices.onChangeRole(reciveChangeRole);
		SocketIoServices.onChangeModer(onChangeModerHandler);
		SocketIoServices.onHandToggled(onHandToggledHandler);
		SocketIoServices.onEmotion(onEmotionHandler);
		SocketIoServices.onRoomSpeakSet(onRoomSpeakSetHandler);
		SocketIoServices.onRoomStatusSet(onRoomStatusSet);
		SocketIoServices.onMuteSet(onMuteSetHandler);
		SocketIoServices.onUserUpdated(onUserUpdatedHandler);
		SocketIoServices.onMessagePinned(onMessagePin);
		SocketIoServices.onPollCreated(onPollCreatedHandler);
		SocketIoServices.onPollUpdated(onPollUpdatedHandler);
		SocketIoServices.onPollDeleted(onPollDeletedHandler);
		SocketIoServices.onPollEnded(onPollEndedHandler);
		SocketIoServices.onVoteCreated(onVoteCreatedHandler);
		SocketIoServices.onBlockSet(onBlockSetHandler);
		SocketIoServices.onMessageDeleted(onRemoveMessageHandler);
		SocketIoServices.onReactionCreated(onReactionCreatedHandler);
		SocketIoServices.onReactionDeleted(onReactionDeletedHandler);
		SocketIoServices.onRoomSlowmodeSet(onRoomSlowmodeSetHandler);
		SocketIoServices.onRoomSlowmodeDelaySet(onRoomSlowmodeDelaySetHandler);
		SocketIoServices.onSettingsUpdated(onSettingsUpdatedHandler);
		SocketIoServices.onBetEdited(onBetEditedHandler);
		SocketIoServices.onMessageUnpinned(onMessageUnpinnedHandler);

		if (roomId && accessToken) {
			if (appUserCounterButtonVisible) await getTalkersCount();

			if (appEnableSubscriptionsFilter) getSubscriptions();
			toggleRestApiDataLoaded({talkersCountLoaded: true});
		}
	};

	const commonPackUnSubscribeSocketMessages = () => {
		SocketIoServices.offUserJoined(onUserJoinedHandler);
		SocketIoServices.offSuccessfullyJoined(onSuccessfullyJoined);
		SocketIoServices.offSuccessfullyJoinedThread(onSuccessfullyJoinedThread);
		SocketIoServices.offGetOffers(onGetOffersHandler);
		SocketIoServices.offUserLeft(onUserLeftHandler);
		SocketIoServices.offGetMessage(onGetMessageHandler);
		SocketIoServices.offMessageNotSent(onMessageNotSentHandler);
		SocketIoServices.offMessageEdited(onMessageEditedHandler);
		SocketIoServices.offMessagesDeleted(onMessagesDeletedHandler);
		SocketIoServices.offMessagesVisibleSet(onMessagesVisibleHandler);
		SocketIoServices.offMessageVisibleSet(onMessageVisibleHandler);
		SocketIoServices.offTalkerSetBan(talkerBanSetHandler);
		SocketIoServices.offUserBanSet(onUserBanSetHandler);
		SocketIoServices.offHandToggled(onHandToggledHandler);
		SocketIoServices.offReciveAgoraCreds(reciveAgoraCredsHandler);
		SocketIoServices.offChangeRole(reciveChangeRole);
		SocketIoServices.offChangeModer(onChangeModerHandler);
		SocketIoServices.offEmotion(onEmotionHandler);
		SocketIoServices.offRoomSpeakSet(onRoomSpeakSetHandler);
		SocketIoServices.offRoomStatusSet(onRoomStatusSet);
		SocketIoServices.offMuteSet(onMuteSetHandler);
		SocketIoServices.offUserUpdated(onUserUpdatedHandler);
		SocketIoServices.offMessagePinned(onMessagePin);
		SocketIoServices.offPollCreated(onPollCreatedHandler);
		SocketIoServices.offPollUpdated(onPollUpdatedHandler);
		SocketIoServices.offPollDeleted(onPollDeletedHandler);
		SocketIoServices.offPollEnded(onPollEndedHandler);
		SocketIoServices.offVoteCreated(onVoteCreatedHandler);
		SocketIoServices.offBlockSet(onBlockSetHandler);
		SocketIoServices.offMessageDeleted(onRemoveMessageHandler);
		SocketIoServices.offReactionCreated(onReactionCreatedHandler);
		SocketIoServices.offReactionDeleted(onReactionDeletedHandler);
		SocketIoServices.offRoomSlowmodeSet(onRoomSlowmodeSetHandler);
		SocketIoServices.offRoomSlowmodeDelaySet(onRoomSlowmodeDelaySetHandler);
		SocketIoServices.offSettingsUpdated(onSettingsUpdatedHandler);
		SocketIoServices.offBetEdited(onBetEditedHandler);
		SocketIoServices.offMessageUnpinned(onMessageUnpinnedHandler);
	};

	const unSubscribeOnSocketMessages = () => {
		emitLeaveBySocket();
		commonPackUnSubscribeSocketMessages();
		SocketIoServices.soketDisconnect();
		clearSessionTime();
		clearAgoraStatsInterval();
		removeMessages();
	};

	const unSubscribeWhenDisconnectOnSocketMessages = () => {
		commonPackUnSubscribeSocketMessages();
	};

	const getLastMessages = async () => {
		await getMessagesWithServices(START_NUMBER_OF_MESSAGES);
	};

	const socketIoServicesCallInit = () => {
		if (
			appApiUrls.backend &&
			userData?.token &&
			roomId &&
			SocketIoServices.socket === null &&
			accessToken
		) {
			SocketIoServices.init(
				appApiUrls.backend,
				accessToken,
				subscribeOnSocketMessages,
				unSubscribeWhenDisconnectOnSocketMessages,
				getLastMessages
			);
		}
	};

	const showInternetConnectionToast = () => {
		clearSessionTime();
		clearAgoraStatsInterval();
		hideAllModals({keepThreadModalVisible: true});
		emitLeaveBySocket();
		commonPackUnSubscribeSocketMessages();
		SocketIoServices.soketDisconnect();
		addToast({
			iconName: ToastIconsName.noInet,
			liveTime: 3000,
			message: translations.toasts.noInet,
			cancellable: false,
			messageColor: ToastMessageColor.ERROR,
			deathCallback: () => {
				changeInternetConnectionStatus(false);
			},
		});
	};

	const hideInternetConnectionToast = () => {
		changeInternetConnectionStatus(true);
		socketIoServicesCallInit();
	};

	const showConnectingToStreamToast = () => {
		addToast({
			iconName: ToastIconsName.streamConnecting,
			message: translations.toasts.streamConnecting,
			cancellable: false,
			stable: true,
		});
	};

	const hideConnectionToStreamToast = () => {
		hideToastByMessage(translations.toasts.streamConnecting);
	};

	const onClickHintHandler = (e: any) => {
		const eventTarget = e.target;
		if (!containsClass(e.target, 'hint__button-close') && qs('.hint')) {
			e.preventDefault();
			e.stopPropagation();
			removeHintFromElement(true);
			return;
		}

		if (
			eventTarget &&
			(getParentByAttribute(eventTarget, 'data-hint') || eventTarget.hasAttribute('data-hint')) &&
			!containsClass(eventTarget, 'hint__button-close')
		) {
			const eventTargetParent = getParentByAttribute(eventTarget, 'data-hint');
			const element =
				eventTargetParent || (eventTarget.hasAttribute('data-hint') ? eventTarget : null);
			if (element && !hintsViewed.includes(element.dataset.hint)) {
				e.preventDefault();
				e.stopPropagation();
				if (!getParent(eventTarget, 'chat__submenu')) {
					addHintToElement(element, element.dataset.hint);
				}
			}
		}
	};

	const send = useCallback(
		debounce(
			(quality, isScreenShare) => {
				sendAgoraAnalytics([{event: 'network-quality', value: {...quality, isScreenShare}}]);
			},
			10000,
			{
				leading: true,
				maxWait: 10000,
			}
		),
		[]
	);

	const networkQualityTest = useCallback(
		(
			quality: {uplinkNetworkQuality: number; downlinkNetworkQuality: number},
			rtcStat: any,
			isScreenShare: boolean
		) => {
			if (
				quality.uplinkNetworkQuality > 3 ||
				quality.downlinkNetworkQuality > 3 ||
				rtcStat.RTT > 920
			) {
				setNetworkQuality(NetworkQuality.POOR);
				return;
			}

			if (
				quality.uplinkNetworkQuality <= 3 &&
				quality.downlinkNetworkQuality <= 3 &&
				rtcStat.RTT <= 900
			) {
				setNetworkQuality(NetworkQuality.NORMAL);
			}

			send(quality, isScreenShare);
		},
		[networkQuality]
	);

	const roomStatusChangedPostMessage = (roomStatus: string) => {
		const msg = {
			type: 'roomStatusChanged',
			body: roomStatus,
		};

		if (typeof WatchersChannel !== 'undefined') {
			WatchersChannel.postMessage(JSON.stringify(msg));
			return;
		}

		if ((window as any).webkit?.messageHandlers) {
			(window as any).webkit.messageHandlers.WatchersChannel?.postMessage(JSON.stringify(msg));
			return;
		}

		window.parent.postMessage(msg, '*');
	};

	const onMessageHandler = (e: MessageEvent) => {
		const postMessage = e.data;
		const isRecord =
			roomData?.status === RoomStatus.ENDED &&
			(roomData.record || roomData.audioRecord || roomData.videoRecord);
		if (postMessage.type === 'audio') {
			if (postMessage.body === 'PAUSE') {
				isRecord ? setPausePressed(true) : setMuteClientAudio(true);
				return;
			}
			if (postMessage.body === 'PLAY') {
				isRecord ? setPlayPressed(true) : setMuteClientAudio(false);
			}
			return;
		}
		if (postMessage.type === 'audioRew') {
			setRewind(postMessage.body);
		}
	};

	const onClickContextMenuHandler = (event: any) => {
		event.preventDefault();
	};

	useEffect(() => {
		if (appEnableAgora && (enableRoomSpeak || roomData?.isSpeak)) initAgoraStats();
	}, [appEnableAgora, roomData, enableRoomSpeak]);

	useEffect(() => {
		if (roomData && agoraStatus && appEnableAgora && isAgoraLoaded) {
			window.addEventListener('message', onMessageHandler);
		}

		return () => {
			window.removeEventListener('message', onMessageHandler);
		};
	}, [roomData, agoraStatus, appEnableAgora, isAgoraLoaded]);

	useEffect(() => {
		changeCurrentScreen(AppScreens.CHAT);
		window.addEventListener('online', hideInternetConnectionToast);
		window.addEventListener('offline', showInternetConnectionToast);
		return () => {
			unSubscribeOnSocketMessages();
			window.removeEventListener('online', hideInternetConnectionToast);
			window.removeEventListener('offline', showInternetConnectionToast);
		};
	}, []);

	useEffect(() => {
		if (appVoice && appEnableAgora && isAgoraLoaded) {
			if (agoraCreds && agoraStatus === AgoraStatus.DESTROYED) {
				if (
					myTalker &&
					roomData &&
					!roomData.isThread &&
					!(myTalker.role === UserRole.GUEST && roomData.status === RoomStatus.SOON)
				) {
					initAgora(
						agoraCreds,
						myTalker.role === UserRole.SPEAKER || myTalkerIsModer,
						myTalker.isMuted,
						onVOD,
						checkForSupportMicrophoneAndSpeaker,
						networkQualityTest,
						setAgoraMicrophoneActive,
						setAgoraMicrophones,
						changeAgoraStatus,
						sendAnalytics
					);

					if (!welcomeModalVisible && !offerModalVisible) {
						checkForSupportMicrophoneAndSpeaker();
					}
				}
			}

			return;
		}

		if (appEnableAgora && agoraStatus === AgoraStatus.INITED) {
			destroyAgora(changeAgoraStatus);
		}
	}, [agoraCreds, appVoice, myTalker, roomData, appEnableAgora, isAgoraLoaded]);

	useEffect(() => {
		appVoice && getSpeakers();
	}, [appVoice]);

	useEffect(() => {
		if (
			(myTalker?.isModer || userData?.isModer || myTalker?.role === UserRole.SPEAKER) &&
			appVoice &&
			agoraStatus === AgoraStatus.INITED
		) {
			getMicrophones();
		}
	}, [myTalker?.isModer, userData?.isModer, myTalker?.role, appVoice, agoraStatus]);

	useEffect(() => {
		(myTalker?.isModer || userData?.isModer) && getWaitingSpeakers();
	}, [myTalker?.isModer, userData?.isModer]);

	useEffect(() => {
		if (appVoice && agoraStatus !== AgoraStatus.INITED && !roomData?.isThread)
			showConnectingToStreamToast();
		if (appVoice && agoraStatus === AgoraStatus.INITED) {
			getPlaybackDevices();
			hideConnectionToStreamToast();
			if (
				((myTalker && myTalker.role === UserRole.SPEAKER) || myTalkerIsModer) &&
				agoraMicrophones.length > 1
			)
				toggleAgoraDevicesModalVisible(true);
		}
	}, [appVoice, agoraStatus, roomData?.isThread]);

	useEffect(() => {
		if (roomData) {
			const {isSlowmode, slowmodeDelayMS} = roomData;
			togglleSlowMode({
				local: {
					enable: isSlowmode,
					time: slowmodeDelayMS || 1000,
				},
			});
			roomStatusChangedPostMessage(roomData.status);
			if (
				(roomData.record || roomData.audioRecord || roomData.videoRecord) &&
				roomData.status === RoomStatus.ENDED
			)
				roomStatusChangedPostMessage('RECORD');
			if (roomData.status === RoomStatus.ENDED && enableScreenSharing) {
				setIsScreenSharing(false);
				setIsMyTalkerShareScreen(false);
			}
		}
	}, [roomData]);

	useEffect(() => {
		if (roomId && accessToken && myTalker) {
			if (!myTalker.room?.isThread) {
				getMessagesWithServices(START_NUMBER_OF_MESSAGES);
				getPollWithServices();
				getStickersWithServices();
			}
		}
	}, [roomId, accessToken, myTalker]);

	useEffect(() => {
		if (accessToken) {
			getRulesWithServices();
			getChatBotWithServices();
			getGreetingWithServices();
			getWelcomeWithServices();
		}
	}, [accessToken, language]);

	useEffect(() => {
		socketIoServicesCallInit();
	}, [userData, roomId, accessToken, deletedState]);

	useEffect(() => {
		if (
			offers !== null &&
			offers.length &&
			userData &&
			roomData?.status === RoomStatus.LIVE &&
			!roomData?.isThread &&
			!roomData.isSpeak &&
			!enableRoomSpeak &&
			offer === null &&
			!badgePopupArray?.length
		) {
			if (!userExtraDataCheckBadge(userData)) userExtraDataCheckOffer(userData);
		}
	}, [userData, roomData, enableRoomSpeak, offers, offer, badgePopupArray]);

	useEffect(() => {
		if (
			!appEnableWelcome &&
			offers !== null &&
			!offers.length &&
			userData &&
			roomData?.status === RoomStatus.LIVE &&
			!roomData?.isThread &&
			!roomData.isSpeak &&
			!enableRoomSpeak &&
			!badgePopupArray?.length
		) {
			if (!userExtraDataCheckBadge(userData) && appEnableMessageTranslation)
				setTranslateTooltipVisible(true);
		}
	}, [userData, roomData, enableRoomSpeak, offers, badgePopupArray, appEnableMessageTranslation]);

	useEffect(() => {
		if (
			appEnableWelcome &&
			userData &&
			roomData?.status === RoomStatus.LIVE &&
			!roomData?.isThread &&
			!roomData.isSpeak &&
			!enableRoomSpeak
		) {
			if (!userExtraDataCheckWelcome(userData)) userExtraDataCheckBadge(userData);
		}
	}, [appEnableWelcome, userData, roomData, enableRoomSpeak]);

	useEffect(() => {
		if (
			accessToken &&
			appEnableMessageTranslation &&
			userData &&
			!userExtraDataCheckTranslateMode(userData) &&
			!translateLangs.length
		)
			getTranslateLangs(language);
	}, [appEnableMessageTranslation, userData, accessToken]);

	useEffect(() => {
		if (userData) {
			if (appAgreementAndRulesVisible) {
				userExtraDataCheckAgreementAndChatRules(userData);
			}

			if (!userData.isOnboard && appEnableOnboarding) {
				if (userData.onboardStage === OnboardStage.AVATAR) {
					toggleOnboardModal(true);
				}
			}

			if (isStand) {
				if (!appEnableOnboarding || (appEnableOnboarding && userData.isOnboard)) {
					HTMLNode?.classList.add('user-authorized');
				} else {
					HTMLNode?.classList.remove('user-authorized');
					if (userData.onboardStage === OnboardStage.CHAT) {
						userExtraDataCheckAgreementAndChatRules(userData);
					}
					if (userData.onboardStage !== OnboardStage.CHAT) {
						toggleOnboardModal(true);
					}
				}
			}
		}
	}, [userData]);

	useEffect(() => {
		if (myTalker?.bans?.length) {
			handleBans(myTalker?.bans);
			return;
		}
		if (myTalker?.user.bans?.length) {
			handleBans(myTalker?.user.bans);
			return;
		}

		changeCurrentScreen(AppScreens.CHAT);
		hideAlert();
		toggleChangeNameModal(false);
	}, [myTalker]);

	useEffect(() => {
		myTalkerIsModer
			? document.querySelector('body')?.classList.add('moderator')
			: document.querySelector('body')?.classList.remove('moderator');

		return () => {
			document.querySelector('body')?.classList.remove('moderator');
		};
	}, [myTalkerIsModer]);

	useEffect(() => {
		if (isStreamVideo) document.querySelector('body')?.classList.add('stream-video');

		return () => {
			document.querySelector('body')?.classList.remove('stream-video');
		};
	}, [isStreamVideo]);

	useEffect(() => {
		if (
			isStand &&
			restApiDataLoaded.messagesLoaded &&
			restApiDataLoaded.pinnedMessageLoaded &&
			userData?.isOnboard &&
			!roomId?.includes('betting-')
		) {
			document.addEventListener('click', onClickHintHandler, true);
			document.addEventListener('contextmenu', onClickHintHandler, true);
		} else document.addEventListener('contextmenu', onClickContextMenuHandler, true);
		return () => {
			if (isStand) {
				document.removeEventListener('click', onClickHintHandler);
				document.removeEventListener('contextmenu', onClickHintHandler);
			} else document.removeEventListener('contextmenu', onClickContextMenuHandler);
		};
	}, [
		project,
		restApiDataLoaded.messagesLoaded,
		restApiDataLoaded.pinnedMessageLoaded,
		userData?.isOnboard,
		roomId,
	]);

	return (
		<div className={mainPageClasses}>
			<Preloader visible={roomData === null} />

			{(roomData?.status === RoomStatus.SOON || roomData?.status === RoomStatus.DISABLED) && (
				<Event />
			)}

			<Chat />

			{!appReadOnly && isUserExternalIdCorrect && <MainBottom />}

			<EmotionsAnim />
			<PollVotesAnim />

			{isVisiblePollToggle && !roomData?.isThread && <PollToggle />}

			<Modals isStreamVideo={isStreamVideo} />
		</div>
	);
};

export default observer(Main);
