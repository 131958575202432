/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: 'Perjanjian',
		subTitle: 'Perjanjian Lesen dan Dasar Privasi',
		textFirst: 'Saya menerima syarat-syarat',
		textSecond:
			'dan memberikan persetujuan saya untuk pemprosesan data peribadi dengan syarat-syarat yang ditentukan dalam',
		license: 'Perjanjian Lesen',
		policy: 'Dasar Privasi',
	},
	controlPanel: {
		streamEnded: 'Penyiaran Tamat',
		banAlert: 'Anda tidak dapat menulis dalam chat',
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? 'Stiker' : 'Emoji',
	},
	accountDeletedScreen: {
		title: 'Profil Dihapus',
		text: (date: string) => `Anda boleh memulihkan profil anda hingga ${date}`,
	},
	nameScreen: {
		title: 'Nama dalam Chat',
		inputPlaceholder: 'Masukkan nama',
		nameError: 'Nama tidak sah',
	},
	avatarScreen: {
		title: 'Avatar',
		label: 'Pilih yang lain:',
	},
	settingsScreen: {
		title: 'Tetapan',
		personal: 'Tetapan Peribadi',
		room: 'Tetapan Bilik',
		profileBtn: 'Profil',
		languageBtn: 'Bahasa',
		blockedBtn: 'Diblok',
		rules: 'Peraturan Chat',
		deleteProfile: 'Padam Profil',
		slowModeBtn: 'Mod Lambat',
		agora: {
			microphone: 'Mikrofon',
			playbackDevice: 'Sumber Bunyi',
		},
	},
	languageScreen: {
		title: 'Bahasa dan Antara Muka',
		messagesTranslate: 'Terjemahan Mesej',
		translateMessagesMode: 'Terjemahkan Mesej dalam Perbualan',
		translateMessagesTo: 'Terjemahkan Mesej ke:',
	},
	rulesScreen: {
		title: 'Peraturan Chat',
	},
	profileScreen: {
		title: 'Profil',
		selectAnotherPic: 'Pilih Avatar Lain:',
		chatNameLabel: 'Nama dalam Chat',
		changeName: 'ubah',
	},
	blockedScreen: {
		title: 'Diblok',
		emptyListText: 'Tiada pengguna yang diblok',
	},
	chatScreen: {
		inputPlaceholder: 'Mesej...',
		cantWrite: 'Anda tidak boleh menulis dalam chat',
		chatSubmenu: {
			hideMessage: 'Sembunyikan mesej',
			showMessage: 'Tunjukkan mesej',
			removeFromSpeakers: 'Keluarkan dari penceramah',
			setSpeaker: 'Jadikan penceramah',
			hideOnlyMessage: 'Sembunyikan hanya mesej',
			showOnlyMessage: 'Tunjukkan hanya mesej',
			reportMessage: 'Laporkan mesej',
			reportUser: 'Laporkan pengguna',
			muteSpeaker: 'Matikan mikrofon',
			blockUser: 'Blokir',
			unblockUser: 'Buka blokir pengguna',
			reply: 'Balas',
			copy: 'Salin',
			edit: 'Edit',
			delete: 'Padam',
			pinMessage: 'Tetapkan',
			unpinMessage: 'Lepaskan',
			replyInThread: 'Balas dalam thread',
		},
		actionMessage: {
			editing: 'Penyuntingan',
			reply: 'Balas',
		},
		status: {
			top: 'Teratas',
		},
		translating: 'Menterjemahkan...',
	},
	chatUsersScreen: {
		title: 'Peserta',
		edited: 'Diedit',
		hidden: 'tersembunyi',
		unreadMessages: 'Mesej yang belum dibaca',
		usersSubmenu: {
			muteSpeaker: {
				title: 'Matikan suara peserta',
				subtitle: 'Matikan mikrofon',
			},
			setSpeaker: {
				title: 'Keluarkan dari penceramah',
				subtitle: 'Hanya chat teks',
			},
			removeFromSpeaker: {
				title: 'Jadikan penceramah',
				subtitle: 'Pengguna dapat berbicara dengan suara',
			},
			showMessages: {
				title: 'Tunjukkan mesej',
				subtitle: 'Hanya terlihat oleh admin',
			},
			hideMessages: {
				title: 'Sembunyikan mesej',
				subtitle: 'Hanya terlihat oleh admin',
			},
			blockUser: {
				title: 'Blokir pengguna',
				subtitle: 'Tambahkan ke senarai hitam',
			},

			unblockUser: {
				title: 'Buka Blokir Pengguna',
				subtitle: 'Buka blokir pengguna',
			},
			blockUserByUser: {
				title: 'Blokir Pengguna',
				subtitle: 'Sembunyikan semua pesan pengguna',
			},
			unblockUserByUser: {
				title: 'Buka Blokir Pengguna',
				subtitle: 'Tunjukkan semua pesan pengguna',
			},
			reportUser: {
				title: 'Laporkan Pengguna',
				subtitle: 'Laporan akan dikirim secara anonim',
			},
		},
		days: {
			today: 'Hari Ini',
			yesterday: 'Semalam',
		},
		chatCreated: 'Chat dicipta,',
	},
	alerts: {
		btns: {
			unblock: 'Buka Blokir',
			block: 'Blokir',
			send: 'Hantar',
			sendAndBlock: 'Hantar dan Blokir',
			show: 'Tunjukkan',
			showAll: 'Tunjukkan Semua',
			cancel: 'Batal',
			yes: 'Ya',
			hide: 'Sembunyikan yang Dipilih',
			hideAll: 'Sembunyikan Semua',
			make: 'Buat',
			remove: 'Alih',
			delete: 'Padam',
			deleteAccount: 'Padam Profil',
			close: 'Tutup',
			pin: 'Tetapkan',
			unpin: 'Lepaskan',
			edit: 'Edit',
			end: 'Selesai',
			endAndShare: 'Selesai dan Kongsi',
			reset: 'Set Semula',
			rules: 'Peraturan Chat',
			contactus: 'Hubungi Kami',
		},
		pinMessage: {
			title: 'Tetapkan Mesej?',
		},
		unPinMessage: {
			title: 'Lepaskan Mesej?',
		},
		accountRestored: {
			title: 'Profil Dipulihkan',
		},
		closeApp: {
			title: 'Adakah anda pasti mahu keluar?',
		},
		removeMessage: {
			title: 'Padam Mesej?',
		},
		report: {
			title: 'Hantar Aduan?',
			subtitle:
				'Anda juga boleh memblokir pengguna supaya tidak melihat mesej antara satu sama lain.',
		},
		banUser: {
			title: (name: string) => `Blokir ${name}?`,
			subtitle: (text: string) => `Pengguna akan diblokir selama ${text}`,
		},
		unBanUser: {
			title: (name: string) => `Buka Blokir ${name}?`,
		},
		showUserMessages: {
			title: (name: string) => `Tunjukkan Mesej dari ${name}?`,
			subTitle: 'Pengguna lain dapat melihat mesej dari pengguna ini',
		},
		hideUserMessages: {
			title: (name: string) => `Sembunyikan Mesej dari ${name}?`,
			subTitle: 'Pengguna lain tidak akan dapat melihat mesej dari pengguna ini lagi',
		},
		showUserMessage: {
			title: (name: string) => `Tunjukkan Mesej dari ${name}?`,
			subTitle: 'Pengguna lain dapat melihat mesej ini',
		},
		hideUserMessage: {
			title: (name: string) => `Sembunyikan Mesej dari ${name}?`,
			subTitle: 'Pengguna lain tidak akan dapat melihat mesej ini lagi',
		},
		blockUserInRoom: {
			title: (name: string) => `Blokir ${name}?`,
			subTitle: 'Pengguna tidak dapat berpartisipasi dalam ruangan ini',
		},
		unBlockUserInRoom: {
			title: (name: string) => `Buka Blokir ${name}?`,
			subTitle: 'Pengguna dapat berpartisipasi dalam ruangan ini',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `Blokir ${name}?`,
			subTitle: 'Anda tidak akan dapat melihat mesej satu sama lain',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `Jadikan ${name} penceramah?`,
			unTitle: (name: string) => `Alihkan ${name} dari penceramah?`,
		},
		banInApp: {
			title: 'Pentadbir telah memblokir anda',
			for: 'selama',
			subtitle: (bannedUntil: string) => `Anda diblokir hingga ${bannedUntil}`,
			permanent: 'Anda diblokir selamanya',
		},
		banInAppBadNickname: {
			title: 'Pentadbir telah memblokir anda atas nama yang tidak sah',
			subtitle: 'Masuk ke profil anda, tukar nama, dan log masuk semula ke chat',
		},
		deleteAccount: {
			title: 'Padam Profil',
			subTitle: 'Semua data akan dipadam. Anda boleh pulihkan profil anda dalam tempoh 30 hari.',
		},
		pollEdit: {
			title: 'Edit Undian?',
			subTitle: 'Jawapan pengguna akan direset',
		},
		pollReset: {
			title: 'Set Semula?',
			subTitle: 'Undian tidak akan disimpan',
		},
		pollEnd: {
			title: 'Adakah anda benar-benar mahu mengakhiri undian?',
		},
		pollDelete: {
			title: 'Padam Undian?',
		},
		agoraCheck: {
			updateIOSText: 'Kemas kini versi iOS untuk menggunakan semua alat aplikasi.',
			allowAccess: {
				title: 'Anda ingin mengatakan sesuatu?',
				text: 'Sila buka tetapan aplikasi dan benarkan akses kepada mikrofon dan pembesar suara.',
			},
			microphoneAndSpeakerText:
				'Penggunaan mikrofon dan pembesar suara telah tersedia sejak versi 14.3.',
			microphoneText: 'Penggunaan mikrofon dan pembesar suara telah tersedia sejak versi 14.3.',
			btn: 'Baiklah!',
		},
	},
	eventScreen: {
		title: 'Acara Terjadwal',
		titleSoon: 'Penyiaran akan dimulai sebentar lagi',
		subtitle: 'Chat akan dibuka tidak lama lagi, harap tunggu.',
		days: 'hari',
		hours: 'jam',
		minutes: 'minit',
		seconds: 'saat',
		startTitle: 'Mula',
	},
	toasts: {
		reciveComplaint: 'Kami telah menerima aduan anda',
		setSpeakerRoleForUser: 'Pengguna ditetapkan sebagai penceramah',
		unSpeakerRoleForUser: 'Pengguna dikeluarkan dari penceramah',
		messagesShown: 'Mesej ditunjukkan',
		messagesHidden: 'Mesej disembunyikan',
		messageShown: 'Mesej ditunjukkan',
		messageHidden: 'Mesej disembunyikan',
		userBlocked: 'Pengguna diblokir',
		userUnBlocked: 'Pengguna dibuka kunci',
		youAreSpeaker: 'Anda penceramah! Katakan sesuatu',
		youAreNotSpeaker: 'Pentadbir mengeluarkan anda dari penceramah',
		userIsSpeaker: (name: string) => `${name} kini penceramah`,
		userNotSpeaker: (name: string) => `${name} kini bukan penceramah`,
		yourUserNameInChat: (name: string) => `Nama anda dalam chat - ${name}`,
		messageRemoved: 'Mesej dipadam',
		messageCopied: 'Teks disalin',
		obscenities: 'Cuba sahut semula',
		requestSent: 'Permintaan dihantar',
		requestDecline: 'Permintaan mikrofon anda ditolak',
		youareblocked: (text: string) =>
			`Anda diblokir selama ${text}. Anda tidak boleh menulis dalam chat`,
		youareunblocked: 'Anda telah dibuka kunci. Anda boleh menulis mesej dalam chat',
		youAreModerator: 'Anda moderator!',
		youAreNoModerator: 'Anda bukan moderator lagi',
		micMuted: 'Mikrofon anda dimute',
		noInet: 'Tiada sambungan internet',
		messagePinned: 'Mesej dipasang',
		messageUnpinned: 'Mesej dilepaskan',
		pollPublished: 'Undian diterbitkan',
		pollPublishedError: 'Ralat! Cuba lagi nanti',
		pollAddPic: 'Tambah gambar',
		pollAddPicError: 'Pilih gambar lain',
		pollDeleted: 'Undian dipadam',
		pollEnded: 'Undian tamat',
		sendStickerWhenSlowModeIsOn: (time: string) => `Tunggu ${time} untuk menghantar stiker`,
		linkProhibited: 'Anda tidak boleh menghantar pautan dalam perbualan.',
		phoneProhibited: 'Anda tidak boleh menghantar nombor telefon dalam perbualan.',
		profileHidden: 'Pengguna telah menyembunyikan profil mereka',
		imageSizeWarning: 'Anda boleh memuat naik imej sehingga 10 MB',
		imageBlocked: 'Moderator menyekat penghantaran imej',
		streamKeyCopied: 'Kunci siaran disalin',
	},
	btns: {
		acceptandgo: 'Terima dan teruskan',
		saveandgo: 'Simpan dan teruskan',
		save: 'Simpan',
		unblock: 'Buka Blokir',
		block: 'Blokir',
		restoreAccount: 'Pulihkan profil',
		close: 'Tutup',
	},
	coopyrights: 'Berfungsi berdasarkan Watchers.io',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message' ? 'Laporkan Mesej' : 'Laporkan Pelanggaran',
			subtitle: 'Aduan akan dihantar secara anonim',
			btns: {
				violence: 'Menghina, ancaman, makian',
				provocations: 'Provokasi',
				personalDetails: 'Butiran peribadi',
				flood: 'Banjir',
				spam: 'Spam',
				fraudBegging: 'Penipuan, meminta-minta',
				other: 'Lain-lain',
			},
		},
		ban: {
			title: 'Blokir Pengguna',
			subtitle: 'Nyatakan sebab blokir',
			btns: {
				insults: 'Menghina, ancaman, makian',
				personal: 'Penyebaran butiran peribadi',
				flood: 'Banjir',
				links: 'Pautan ke perkhidmatan luar',
				violence: 'Ancaman keganasan',
				fraud: 'Percubaan penipuan dan meminta-minta',
				nickname: 'Nama samaran yang tidak sah',
				politicial: 'Provokasi politik, seksual dan lain-lain',
				repeated: 'Pelanggaran berulang',
				auto: 'Aduan dari pengguna chat',
				spam: 'Spam',
			},
		},
		waitingSpeakers: {
			text: 'Anda tiada penceramah yang menunggu',
			handsUp: 'Tangan diangkat',
			waiting: 'Penceramah Menunggu',
		},
		poll: {
			supTitles: {
				typeQuiz: 'Berikan Jawapan Yang Betul',
				typeQuizMultiple: 'Tandakan Pilihan Yang Betul',
				typePoll: 'Kongsi Pendapat',
				typePollMultiple: 'Boleh Tandakan Beberapa Pilihan',
				default: 'Undian Awam',
			},
			btns: {
				reply: 'Balas',
				vote: 'Undi',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: 'Kuis',
				typePoll: 'Beberapa Jawapan',
				default: 'Undian Awam',
			},
			btns: {
				edit: 'Edit Undian',
				end: 'Tamat',
			},
		},
		pollCreated: {
			titles: {
				created: 'Cipta Undian',
				typeQuiz: 'Kuis',
				typePoll: 'Beberapa Jawapan',
				default: 'Undian Awam',
			},
		},
		changeName: {
			title: 'Tukar Nama dalam Chat',
			subtitle: 'Anda diblokir kerana nama yang tidak sah',
		},
		temporaryAgreement: {
			title: 'Peraturan Chat',
			rules: {
				bePolite: {
					title: 'Berkuranglah Sopan',
					text: 'Pernyataan yang bermusuhan dan tidak senonoh tidak dibenarkan dalam chat.',
				},
				beAttentive: {
					title: 'Berhati-hatilah',
					text: 'Jangan kongsi maklumat peribadi atau meminta dari orang lain.',
				},
				notAdvertise: {
					title: 'Jangan Iklankan',
					text: 'Lebih baik kongsi pendapat atau emosi anda.',
				},
			},
			btns: {
				allRules: 'Semua Peraturan',
				acceptAndContinue: 'Terima dan Teruskan',
			},
		},
		slowMode: {
			title: 'Mod Lambat',
			text: 'Peserta tidak boleh menghantar mesej dalam chat lebih kerap daripada yang ditetapkan dalam jangka masa yang dipilih',
			enable: 'Dayakan Mod Lambat',
			disable: 'Matikan Mod Lambat',
			sendTimeout: 'Had Masa Hantar',
		},
		slowModeTimeout: {
			title: 'Had Masa Hantar',
		},
		chatBot: {
			title: 'Penggunaan Chat Bot',
			rules: [
				{
					title: 'Kecerdasan Buatan',
					text: 'Bot meniru percakapan manusia dan memberikan informasi berdasarkan pengetahuannya. Namun, ingatlah bahwa jawabannya mungkin terbatas dan tidak dapat menggantikan manusia.',
				},
				{
					title: 'Bot Dapat Keliru',
					text: 'Bot tidak dapat menjamin keakuratan dan kebenaran informasi',
				},
			],
			btns: {
				close: 'Tutup',
			},
		},
		shareBet: {
			title: 'Berbagi',
			noBetsTitle: 'Tidak dapat menemukan taruhan Anda',
			noBetsText: 'Anda dapat berbagi taruhan yang dibuat dalam waktu 14 hari terakhir',
			btns: {
				share: 'Berbagi Taruhan',
				copy: 'Ulangi Taruhan',
			},
			status: {
				null: 'Dalam Pertandingan',
				win: 'Menang',
				lose: 'Kalah',
			},
			betTypeV1: {
				express: 'Pelbagai',
				single: 'Tunggal',
			},
			betTypeV2: {
				single: 'Tunggal',
				multi: 'Pelbagai',
				system: 'System',
			},
			totalFactor: 'Faktor Total',
			bet: 'taruhan',
			showMore: 'Tunjukkan Lebih Banyak',
		},
		record: {
			text: 'Rekaman siaran sedang diproses...',
		},
		translate: {
			title: 'Terjemahan Mesej',
			chooseLanguage: 'Pilih Bahasa',
			descr:
				'Mesej dalam perbualan akan diterjemahkan secara automatik ke dalam bahasa yang anda pilih',
			btn: 'Terjemahkan',
		},

		streamSettings: {
			title: 'Tetapan Strim',
			description: 'Muat turun dan pasang pengekod video. Masukkan kunci strim dalam tetapannya',
			shareScreen: {
				title: 'Kongsi Skrin',
				description: 'Tiada tetapan khas diperlukan',
			},
			rtmp: {
				title: 'Sumber Strim Luaran',
				description: 'Gunakan program tambahan untuk menstrim suapan',
			},
			streamKey: 'Kunci Strim',
			showStreamKey: 'Tunjukkan',
			hideStreamKey: 'Sembunyikan',
			btns: {
				continue: 'Teruskan',
				close: 'Tutup',
				ok: 'Selesai',
			},
		},
	},
	pinnedMessage: {
		title: 'Pesan Tersemat',
	},
	errorPage: {
		error: 'Kesalahan',
		types: [
			// errorNumber 0
			{
				title: 'Kami Tidak Mengenali Anda',
				text: 'Coba masuk kembali ke chat',
			},
			// errorNumber 1
			{
				title: 'Tidak Dapat Menemukan Chat',
				text: 'Coba masuk kembali',
			},
			// errorNumber 2
			{
				title: 'Tidak Dapat Memuat Chat',
				text: 'Coba muat ulang',
			},
			// errorNumber 3
			{
				title: 'Kami Tidak Mengenali Anda',
				text: 'Chat dalam mode penyamaran tidak tersedia',
			},
			// errorNumber 4
			{
				title: 'Nickname Tidak Sah',
				text: 'Masuk ke profil Anda, ubah nickname, dan masuk kembali ke chat.',
			},
		],
	},
	tooltips: {
		emotions: 'Tahan untuk mengganti',
		badInet: 'Koneksi jaringan buruk',
		blockedUsers: 'Anda dapat melihat daftar pengguna yang diblokir <br>di sini.',
		waitingSpeakers: 'Seseorang dari peserta ingin berbicara.',
		wantSpeak: 'Tekan untuk meminta hak berbicara.',
		slowMode: {
			header: (time: string) =>
				`Mode lambat diaktifkan. Pengguna dapat mengirim satu pesan <br>setiap ${time}. Aturan ini <br>tidak berlaku untuk moderator.`,
			btnSendMessage: (time: string) => `Anda dapat mengirim pesan <br>setiap ${time}.`,
		},
		shareBet: 'Bagikan taruhan dalam chat',
		translate: 'Terjemahkan mesej ke bahasa lain',
	},
	poll: {
		toggleSubmenu: {
			create: 'Buat polling',
			edit: 'Edit polling',
			show: 'Tunjukkan polling',
			end: 'Akhiri polling',
		},
		tooltip: {
			btn: 'Memilih',
		},
		settings: {
			title: 'Pengaturan',
			titleAdditionally: 'Tambahan',
			switches: {
				quizMode: 'Mode kuis',
				multipleChoice: 'Pilihan ganda',
				withPhoto: 'Polling dengan foto',
			},
		},
		question: {
			title: 'Pertanyaan',
			inputPlaceholder: 'Masukkan pertanyaan',
		},
		message: {
			title: 'Anda telah memilih',
		},
		answers: {
			title: 'Pilihan jawaban',
			warning: 'Anda dapat menambahkan hingga 5 jawaban',
		},
		answer: {
			tooltip: 'Pilih jawaban yang benar',
			inputPlaceholder: 'Jawaban',
		},
		results: {
			text: 'Hasil polling',
			votes: 'Voting',
			// words: ['voting', 'votes', 'votes'],
		},
		btns: {
			addQuestion: 'Tambahkan pertanyaan',
			addAnswers: 'Tambahkan jawaban',
			addAnswer: 'Tambahkan jawaban',
			publish: 'Publikasikan',
			vote: 'Memilih',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: 'Beri Nilai',
				continue: 'Lanjutkan',
				checkConnection: 'Periksa Koneksi',
				close: 'Tutup',
			},
			quality: {
				title: 'Beri Nilai Kualitas Suara',
			},
			reason: {
				title: 'Apa yang Tidak Beres?',
			},
			connection: {
				title: 'Uji Kualitas Koneksi',
				text: 'Pemeriksaan akan memakan waktu kurang dari satu menit dan membantu menemukan penyebab masalah',
			},
			statistics: {
				title: 'Mengumpulkan Statistik...',
			},
			done: {
				title: 'Terima Kasih!',
				text: 'Kami akan memeriksa apa yang tidak beres dan pasti akan memperbaikinya.',
			},
		},
	},
	chatBot: {
		message: 'Tanyakan sesuatu pada bot...',
		warnings: {
			disabled: 'Saya dinonaktifkan',
			overloaded: 'Kami kelebihan beban',
		},
	},
	audioReason: {
		nothear: 'Tidak ada yang terdengar',
		connection: 'Suara terputus-putus',
		microphone: 'Tidak bisa menggunakan mikrofon',
	},
	screenShare: {
		title: 'Pembagian Skrin',
		stopSharing: 'Hentikan Pembagian Skrin',
		modal: {
			title: 'Pembagian skrin hanya boleh dilakukan dari komputer.',
			text: `Buka sembang di komputer anda dan klik butang 'Pembagian Skrin' di sudut kanan atas.`,
			btn: 'Salin pautan jemputan.',
		},
	},
	threads: {
		title: 'Thread',
		back: 'Kembali',
		replies: ['Balas', 'Balasan', 'Balasan'],
	},
	attach: {
		submenu: {
			sendImage: 'Hantar imej',
			shareBet: 'Kongsi taruhan',
			shareGamble: 'Kongsi kemenangan',
		},
		images: ['imej', 'imej-imej', 'imej-imej'],
		addCaption: 'Tambah kapsyen',
	},
} as unknown as Dictionary;
