/* eslint-disable max-lines */
import {qs, containsClass, getParent, getParentByAttribute, qsAll, textEnding} from 'utils/helpers';
import MessageType from 'models/enums/MessageType.enum';
import OpenProfileType from 'models/enums/OpenProfileType.enum';
import {Message, Advertisement, Reaction, Sticker} from 'models/room';
import {PollMessageData} from 'models/poll';
import {Bet} from 'models/bet';
import {Gamble} from 'models/gamble';
import {Badge} from 'models/badge';
import TooltipPositoinType from 'models/enums/TooltipPositoinType.enum';

import appService from 'store/appService';
import userServices from 'store/userService';
import roomServices from 'store/roomService';
import chatServices from 'store/chatService';
import stickersEmojiServices from 'store/stickersEmojiService';
import hintService from 'store/hintService';
import threadService from 'store/threadService';
import actionMessageService from 'store/actionMessageService';
import tooltipsService from 'store/tooltipsService';
import modalService from 'store/modalService';
import attachService from 'store/attachService';
import agoraService from 'store/agoraService';
import TooltipType from 'models/enums/TooltipType.enum';
import {ActionType} from 'models/enums/ActionType.enum';
import useLongPress from 'hooks/useLongPress';
import useMessage from 'hooks/useMessage';
import useHints from 'hooks/useHints';
import useUserProfile from 'hooks/useUserProfile';
import useSwipe from 'hooks/useSwipe';
import translations from 'l10n/translations';
import useAppData from 'hooks/useAppData';
import {getLocaleTimeMessage, getLocaleDateMessage} from 'utils/message';
import useL10n from 'l10n/useL10n';
import {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {isMobile, isDesktop} from 'react-device-detect';
import Linkify from 'linkify-react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import classNames from 'classnames/bind';
import {v4 as uuidv4} from 'uuid';
import isEmptyObj from 'lodash/isEmpty';

import {Avatar} from 'components/avatar';
import {PollResults} from 'components/poll/pollResults';
import BetItem from 'components/bet/BetItem';
import GambleItem from 'components/gamble/GambleItem';
import Tooltip from 'components/tooltip/Tooltip';

import AnonimAva from 'assets/images/anonim-ava.jpg';
import {ReactComponent as IcoinvitedGuest} from 'assets/svg/ico-invited-guest.svg';
import {ReactComponent as IcoHint} from 'assets/svg/ico-hint.svg';

import {ChatMessageSubmenu} from '../chatMessageSubmenu';
import {LinkPreview} from '../../linkPreview';
import {Highlight} from '../../highlight';
import {Reactions} from '../../reactions';
import ChatMessageThreadButton from './ChatMessageThreadButton';

interface IChatMessageProps {
	message: Message;
	mentionMessage: Message | null | undefined;
	visibleAvatar: boolean;
	visibleName: boolean;
	visibleDate: boolean;
	visibleMessageSubmenu: boolean;
	isFirstMessage: boolean;
	isLastMessage: boolean;
	highlightMessage?: Advertisement;
	pollResultsMessage?: PollMessageData;
	stickerMessage?: Sticker;
	stickerPic?: string;
	betMessage: Bet;
	gambleMessage: Gamble;
	reactions?: Reaction[];
	isThread?: boolean;
	pics?: string;
}

const MESSAGE_TEXT_BASE_LINE_HEIGHT = 21; // px

const LONG_PRESS_DEFAULT_OPTIONS = {
	shouldPreventDefault: false,
	delay: 500,
};

const LINKIFY_OPTIONS = {
	target: '_blank',
};

const groupingByKey = (arrray: any, key = 'emotion') => {
	const resultObject = arrray.reduce((acc: any, item: any) => {
		// eslint-disable-next-line no-param-reassign
		(acc[item[key]] = acc[item[key]] || []).push(item);
		return acc;
	}, {});
	if (!isEmptyObj(resultObject)) {
		return Object.getOwnPropertyNames(resultObject).map(k => ({
			id: uuidv4(),
			name: resultObject[k][0].emotion,
			messageId: resultObject[k][0].messageId,
			items: resultObject[k],
		}));
	}
	return [];
};

const ChatMessage: FunctionComponent<IChatMessageProps> = function ChatMessage({
	message,
	mentionMessage,
	highlightMessage,
	pollResultsMessage,
	stickerMessage,
	stickerPic,
	betMessage,
	gambleMessage,
	visibleAvatar,
	visibleName,
	visibleDate,
	visibleMessageSubmenu,
	isFirstMessage,
	isLastMessage,
	reactions,
	isThread,
	pics,
}) {
	const messageRef = useRef<HTMLDivElement>(null);
	const chatMessageRef = useRef<HTMLDivElement>(null);
	const chatMessageContainerRef = useRef<HTMLDivElement>(null);
	const chatMessageWrapperRef = useRef<HTMLDivElement>(null);
	const chatMessageContentRef = useRef<HTMLDivElement>(null);
	const chatMessageContainerHeightRef = useRef<HTMLDivElement>(null);
	const chatSubmenuRef = useRef<HTMLDivElement>(null);
	const chatMessageTopPositionRef = useRef<HTMLDivElement>(null);
	const referenceBadgeTooltip = useRef<any>([]);
	const badgesRef = useRef<HTMLDivElement>(null);

	const {chatUsersScreen, chatScreen, attach} = useL10n();

	const {
		id,
		type,
		talker,
		user,
		text,
		translate,
		isVisible,
		hasPreview,
		createdAt,
		updatedAt,
		hiddenAt,
		hiddenBy,
		isAutoposted,
		isThreadStarter,
		threadMessagesCount,
		thread,
	} = message;
	const {
		project,
		appReadOnly,
		language,
		appEnableOnboarding,
		copybettingVersion,
		enableOpenProfile,
		openProfileType,
		appEnableMessageTranslation,
		appIcons,
		appLoaderIcon,
		appEnableSubscriptionsFilter,
		isStand,
		appEnableFullscreenOption,
	} = useLocalObservable(() => appService);

	const {userData, isUserExternalIdCorrect, translateMode} = useLocalObservable(() => userServices);
	const {
		roomData,
		myTalker,
		visibleDateForFirstMessage,
		submenuMessage,
		setSubmenuMessage,
		blockedUsersForFilteredMessages,
		isMessageTranslateInProgress,
		subscriptions,
	} = useLocalObservable(() => (isThread ? threadService : roomServices));

	const {chatBot} = useLocalObservable(() => chatServices);
	const {togglleVisibleStickersEmoji} = useLocalObservable(() => stickersEmojiServices);
	const {hintsViewed} = useLocalObservable(() => hintService);
	const {tooltip, toggleTooltip} = useLocalObservable(() => tooltipsService);
	const {isFullScreenVideo} = useLocalObservable(() => agoraService);

	const [textMultiline, setTextMultiline] = useState(false);
	const [longTapAnim, setLongTapAnim] = useState(false);
	const [submenuAvailable, setSubmenuAvailable] = useState(false);
	const [swipeAnimation, setSwipeAnimation] = useState(false);
	const [badgeTooltip, setBadgeTooltip] = useState<Badge | null>(null);
	const [isSubscription, setIsSubscription] = useState(false);
	const [totalWidth, setTotalWidth] = useState(0);
	const {getMessageText, checkingMessageFromChatBot, getBetTitle} = useMessage();
	const {removeHintFromElement} = useHints();
	const {sendAnalyticsEvent, profileUrlPostMessage} = useUserProfile();
	const {toggleIsVisibleActionMessage, setActionData} = useLocalObservable(
		() => actionMessageService
	);
	const {toggleImagePreviewModalVisible} = useLocalObservable(() => modalService);
	const {setFiles, setIsMessagePreview} = useLocalObservable(() => attachService);

	const {getAppIcon} = useAppData();
	const {IcoAdmin} = appIcons;

	const myTalkerIsBanned =
		!!threadService.myTalker?.bans?.length || !!roomServices.myTalker?.bans?.length;
	const myUserIsBanned = !!myTalker?.user?.bans?.length;
	const messageTalkerIsModer = talker?.isModer || user?.isModer;
	const commonCheck =
		!appReadOnly &&
		isUserExternalIdCorrect &&
		userData &&
		(!appEnableOnboarding || (appEnableOnboarding && userData.isOnboard)) &&
		talker &&
		user &&
		!myTalkerIsBanned &&
		!myUserIsBanned;
	const myMessage = userData && user && userData.id === user.id;
	const chatBotMessage = user && chatBot && user.name === chatBot.name;
	const checkVisibleMessageSubmenu = visibleMessageSubmenu;
	const picsArray: {url: string; isVisible: boolean; width: number; height: number}[] = pics
		? Array.from(JSON.parse(pics))
		: [];
	const userPicsVisible = myMessage ? picsArray : picsArray.filter(el => el.isVisible);
	const mentionPicsArray: {url: string; isVisible: boolean; width: number; height: number}[] =
		mentionMessage?.picData ? Array.from(JSON.parse(mentionMessage?.picData)) : [];

	const mentionPicsArrayVisible = mentionPicsArray
		? mentionPicsArray.filter(el => el.isVisible)
		: [];

	const isHintViewed =
		(type === MessageType.GAMBLE && hintsViewed.includes('gamble')) ||
		(type === MessageType.BET && hintsViewed.includes('bet')) ||
		(type === MessageType.ADVERTISEMENT && hintsViewed.includes('highlight')) ||
		(type !== MessageType.ADVERTISEMENT && chatBotMessage && hintsViewed.includes('chatbot')) ||
		(type === MessageType.USER && hintsViewed.includes('message'));

	const isFullScreenMode = appEnableFullscreenOption && isFullScreenVideo && isDesktop;

	const findLinksInText = () => {
		const arrayFoundLinks = text?.match(/\bhttp(s?):\/\/\S+/gi);
		return arrayFoundLinks?.length && arrayFoundLinks.length > 0 ? arrayFoundLinks[0] : null;
	};

	const getDataAttr = () => {
		let dataAttrObj: any = {'data-id': message.id};

		if (!myMessage) {
			if (type === MessageType.USER) {
				dataAttrObj = {...dataAttrObj, 'data-hint': 'message'};
			}
			if (type === MessageType.POLLRESULTS) {
				dataAttrObj = {...dataAttrObj, 'data-hint': 'poll'};
			}
			if (type === MessageType.ADVERTISEMENT) {
				dataAttrObj = {...dataAttrObj, 'data-hint': 'highlight'};
			}
			if (type === MessageType.BET) {
				dataAttrObj = {...dataAttrObj, 'data-hint': 'bet'};
			}
			if (type === MessageType.GAMBLE) {
				dataAttrObj = {...dataAttrObj, 'data-hint': 'gamble'};
			}
			if (type !== MessageType.ADVERTISEMENT && chatBotMessage) {
				dataAttrObj = {...dataAttrObj, 'data-hint': 'chatbot'};
			}
		}
		return dataAttrObj;
	};

	const showMessageSubmenu = (currentMessageId: number) => {
		if ((isMobile || (isDesktop && currentMessageId === id)) && !qs('.chat__message--submenu')) {
			const messageTopPosition = chatMessageWrapperRef.current?.getBoundingClientRect().top;
			const contentHeight = chatMessageContentRef.current;
			const messageHeight = chatMessageContainerRef.current?.offsetHeight;

			if (chatMessageWrapperRef.current && chatMessageContainerHeightRef.current) {
				chatMessageContainerHeightRef.current.style.height = `${chatMessageWrapperRef.current.offsetHeight}px`;
			}

			if (contentHeight && contentHeight.classList.contains('chat__message-content--end'))
				contentHeight.classList.remove('chat__message-content--end');

			setLongTapAnim(true);

			setTimeout(() => {
				setSubmenuMessage({
					messageId: currentMessageId,
				});

				setLongTapAnim(false);

				if (chatMessageWrapperRef.current && chatMessageTopPositionRef.current) {
					const submenuHeight = chatSubmenuRef.current?.offsetHeight || 0;

					if (messageTopPosition && messageTopPosition > 0 && messageHeight) {
						if (submenuHeight + messageHeight + messageTopPosition + 46 < window.innerHeight) {
							chatMessageTopPositionRef.current.style.height = `${messageTopPosition - 16}px`;
							return;
						}

						if (contentHeight && contentHeight.offsetHeight < window.innerHeight) {
							contentHeight.classList.add('chat__message-content--end');
							return;
						}
					}

					chatMessageWrapperRef.current.scrollTop = chatMessageWrapperRef.current.scrollHeight;
				}
			}, 150);
		}
	};

	const onContextMenuHandler = (event: any) => {
		const eventTarget = event.target;
		if (
			eventTarget &&
			getParent(eventTarget, 'chat__message') &&
			!appReadOnly &&
			isUserExternalIdCorrect &&
			!myTalkerIsBanned &&
			!myUserIsBanned &&
			commonCheck
		) {
			const messageId = parseInt(getParent(eventTarget, 'chat__message').dataset.id, 10);
			if (
				getParent(eventTarget, 'chat__message-body') ||
				containsClass(eventTarget, 'chat__message-body')
			) {
				event.preventDefault();
				if (qs('.hint')) {
					removeHintFromElement(false);
				}

				showMessageSubmenu(messageId);

				togglleVisibleStickersEmoji(false);
			}
		}
	};

	const setFocusInTextarea = () => {
		const chatTextarea: HTMLTextAreaElement | null = document.querySelector('.chat__textarea-item');
		chatTextarea?.focus();
	};

	const swipeLeft = () => {
		setSwipeAnimation(true);
		setFocusInTextarea();
		setTimeout(() => {
			setActionData({type: ActionType.REPLAY, message});
			toggleIsVisibleActionMessage(true);
		}, 200);
		setTimeout(() => {
			setSwipeAnimation(false);
		}, 300);
	};

	const swipeEvent = useSwipe((e: any) => {
		if (isMobile) {
			if (commonCheck) {
				if (qs('.hint')) {
					removeHintFromElement(false);
					togglleVisibleStickersEmoji(false);
				}
				if (
					isStand &&
					chatMessageRef.current &&
					(getParentByAttribute(chatMessageRef.current, 'data-hint') ||
						chatMessageRef.current.hasAttribute('data-hint')) &&
					!containsClass(chatMessageRef.current, 'hint__button-close') &&
					!isHintViewed
				) {
					e.preventDefault();
				}
			}
		}
	}, swipeLeft);

	const longPressEvent = useLongPress(
		() => {
			if (isMobile) {
				if (commonCheck) {
					if (qs('.hint')) {
						removeHintFromElement(false);
						togglleVisibleStickersEmoji(false);
					}
					if (
						isStand &&
						chatMessageRef.current &&
						(getParentByAttribute(chatMessageRef.current, 'data-hint') ||
							chatMessageRef.current.hasAttribute('data-hint')) &&
						!containsClass(chatMessageRef.current, 'hint__button-close') &&
						!isHintViewed
					) {
						return;
					}
					showMessageSubmenu(id);
					if (isMobile) {
						setSubmenuAvailable(false);
						setTimeout(() => {
							setSubmenuAvailable(true);
						}, 1000);
					}
				}
			}
		},
		undefined,
		LONG_PRESS_DEFAULT_OPTIONS
	);

	const badgeClickHandler = (badge: Badge) => {
		setBadgeTooltip(badge);
		toggleTooltip({shown: true, type: TooltipType.TRIGGER});
	};

	const calculateTotalWidth = () => {
		const authorWidth =
			qs(`.chat__message[data-id="${id}"] .chat__message-author`)?.offsetWidth || 0;
		const adminWidth =
			qs(`.chat__message[data-id="${id}"] .chat__message-author-admin`)?.offsetWidth || 0;
		const topWidth =
			qs(`.chat__message[data-id="${id}"] .chat__message-author-status--top`)?.offsetWidth || 0;

		setTotalWidth(authorWidth + adminWidth + topWidth);
	};

	useEffect(() => {
		calculateTotalWidth();
	}, []);

	const chatMessageClasses = classNames('chat__message', {
		'chat__message--no-avatar': !visibleAvatar,
		'chat__message--right': myMessage,
		'chat__message--is-hidden': !myMessage && !isVisible,
		'chat__message--mention': mentionMessage,
		'chat__message--highlight': highlightMessage,
		'chat__message--poll-results': type === MessageType.POLLRESULTS,
		'chat__message--sticker': type === MessageType.STICKER,
		'chat__message--submenu': visibleMessageSubmenu,
		'chat__message--submenu-available': submenuAvailable,
		'chat__message--reactions': reactions?.length,
		'chat__message--bet': type === MessageType.BET,
		'chat__message--bet-v2': type === MessageType.BET && copybettingVersion === 2,
		'chat__message--gamble': type === MessageType.GAMBLE,
		'chat__message--hint-viewed': isHintViewed,
		'chat__message--swipe-anim': swipeAnimation,
		'chat__message--pic': pics && type === MessageType.PIC,
	});

	const chatMessageWrapperClasses = classNames('chat__message-wrapper', {
		'chat__message-wrapper--modal': submenuMessage?.messageId === id,
		'chat__message-wrapper--anim': longTapAnim,
		'chat__message-wrapper--fullscreen-mode': isFullScreenMode,
	});

	const chatMessageContainerHeightClasses = classNames('chat__message-container-height', {
		'chat__message-container-height--visible': submenuMessage?.messageId === id,
	});

	const chatMessageTextClasses = classNames('chat__message-text', {
		'chat__message-text--multiline':
			updatedAt ||
			textMultiline ||
			(findLinksInText() && hasPreview) ||
			(thread?.externalRoomId && threadMessagesCount),
	});

	const chatMessageAvatarClasses = `chat__message-avatar ${
		!appEnableOnboarding ? `background-color-${user?.color || 0}` : ''
	}`;

	const chatMessagePicClasses = (pic: any) => {
		if (pic.isVisible !== undefined)
			return classNames('chat__message-pic', {
				'chat__message-pic--danger': !pic.isVisible && myMessage && !messageTalkerIsModer,
			});
		return 'chat__message-pic';
	};

	const replyClasses = classNames('chat__message-replay', {
		'chat__message-replay--pic': mentionMessage?.type === MessageType.PIC,
	});

	const chatMessagePicsClasses = classNames(
		`chat__message-pics chat__message-pics--${
			!myMessage ? picsArray.filter(el => el.isVisible).length : picsArray.length
		}`,
		{
			'chat__message-pics--column':
				((!myMessage && picsArray.filter(el => el.isVisible).length === 2) ||
					(picsArray.length === 2 && myMessage)) &&
				picsArray[0].height &&
				picsArray[0].width
					? picsArray[0].width / picsArray[0].height < 1 &&
					  picsArray[1].width / picsArray[1].height < 1
					: false,
			'chat__message-pics--square':
				picsArray.length === 1 && picsArray[0].height && picsArray[0].width
					? picsArray[0].width / picsArray[0].height === 1
					: false,
			'chat__message-pics--horizontal':
				picsArray.length === 1 && picsArray[0].height && picsArray[0].width
					? picsArray[0].width / picsArray[0].height >= 1
					: false,

			'chat__message-pics--panorama':
				picsArray.length === 1 && picsArray[0].height && picsArray[0].width
					? picsArray[0].width / picsArray[0].height > 2
					: false,
		}
	);

	const checkBadgeVisible = (prevBagdesWidth: number) => {
		if (badgesRef.current) {
			return badgesRef.current.offsetWidth - prevBagdesWidth > 20;
		}

		return true;
	};

	const renderLinkInText = () => {
		const findLink = findLinksInText();
		if (findLink) {
			return <LinkPreview url={findLink} />;
		}
		return null;
	};

	const renderHeaderMessage = useCallback(() => {
		if (visibleName && !myMessage && type !== MessageType.STICKER && type !== MessageType.PIC) {
			if (user && chatBot && user.name === chatBot.name) {
				return (
					<div className='chat__message-header'>
						<div className={`chat__message-author color-${user?.color || 0}`}>Watchers</div>
						<div className='chat__message-author-admin'>
							{getAppIcon(IcoAdmin.pic)}
							<span>bot</span>
						</div>
					</div>
				);
			}
			return (
				<div className='chat__message-header'>
					<div className={`chat__message-author color-${user?.color || 0}`}>
						{user?.name ? user.name : 'User deleted'}
					</div>
					{user?.isInvitedGuest && (
						<div className='chat__message-author-invited'>
							<IcoinvitedGuest />
						</div>
					)}

					{messageTalkerIsModer && !user?.isInvitedGuest && (
						<div className='chat__message-author-admin'>
							{getAppIcon(IcoAdmin.pic)}
							<span>admin</span>
						</div>
					)}

					{user?.ranking && (
						<div className='chat__message-author-status chat__message-author-status--top'>
							{chatScreen.status.top}-{user.ranking.badge}
						</div>
					)}

					{!user?.isModer &&
						!talker?.isModer &&
						!user?.isInvitedGuest &&
						user?.isVip &&
						user?.vipStatus && (
							<div
								className={`chat__message-author-status chat__message-author-status--${user.vipStatus.toLowerCase()}`}>
								{user.vipStatus.toLowerCase()}
							</div>
						)}

					{user?.badges && user?.badges.length > 0 && (
						<div className='chat__message-author-badges' ref={badgesRef}>
							{user?.badges.map((el: Badge, index: number) => {
								let calcWidth = 0;
								qsAll(`.chat__message[data-id="${id}"] .chat__message-author-status`)?.forEach(
									(elem: any, badgeIndex: number) => {
										if (badgeIndex < index) calcWidth += elem.offsetWidth + 4;
									}
								);

								return (
									referenceBadgeTooltip.current && (
										<button
											type='button'
											onClick={() => badgeClickHandler(el)}
											key={`${id}_${el.id}`}
											ref={ref => {
												referenceBadgeTooltip.current[el.id] = ref;
											}}
											className='chat__message-author-status chat__message-author-status--badge'
											style={{
												backgroundColor: el.backgroundColor,
												color: el.textColor,
												maxWidth: `calc(100% - ${calcWidth}px)`,
												display: checkBadgeVisible(calcWidth) ? 'block' : 'none',
											}}>
											{el.text}
										</button>
									)
								);
							})}
						</div>
					)}

					{isSubscription && (
						<div
							className='chat__message-author-status chat__message-author-status--following'
							style={{
								maxWidth: `calc(100% - ${totalWidth}px`,
							}}>
							{translations.following.following}
						</div>
					)}
				</div>
			);
		}
		return null;
	}, [visibleName, myMessage, talker, isSubscription, totalWidth]);

	const renderDateMessage = useCallback(
		() => (
			<div className='chat__message-date'>
				<div className='chat__message-date-item'>
					{isFirstMessage && visibleDateForFirstMessage
						? `${chatUsersScreen.chatCreated} ${getLocaleDateMessage(
								createdAt,
								language,
								chatUsersScreen.days
						  ).toLowerCase()}`
						: getLocaleDateMessage(createdAt, language, chatUsersScreen.days)}
				</div>
			</div>
		),
		[language, visibleDateForFirstMessage]
	);

	const renderReplayMessage = useCallback(() => {
		if (mentionMessage && mentionMessage.isVisible && !mentionMessage.deletedAt) {
			return (
				<div className={replyClasses} data-replay-id={mentionMessage.id}>
					{mentionMessage.sticker?.pic && (
						<div
							className='chat__message-replay-img'
							style={{backgroundImage: `url('${mentionMessage.sticker.pic}')`}}
						/>
					)}
					{mentionMessage.type === MessageType.PIC &&
						mentionPicsArray.length > 0 &&
						mentionPicsArrayVisible.length > 0 && (
							<div
								className='chat__message-replay-img'
								style={{backgroundImage: `url('${mentionPicsArrayVisible[0].url}')`}}
							/>
						)}
					{mentionMessage.sticker?.pic && (
						<div
							className='chat__message-replay-img'
							style={{backgroundImage: `url('${mentionMessage.sticker.pic}')`}}
						/>
					)}
					<div className='chat__message-replay-content'>
						<div className='chat__message-author'>{mentionMessage.user?.name}</div>
						{getMessageText(mentionMessage) && (
							<div className='chat__message-text'>{getMessageText(mentionMessage)}</div>
						)}
						{mentionMessage.type === MessageType.PIC &&
							mentionPicsArrayVisible.length > 0 &&
							!mentionMessage.text && (
								<div className='action-message__img-text'>
									{mentionPicsArrayVisible.length}{' '}
									{textEnding(mentionPicsArrayVisible.length, attach.images)}
								</div>
							)}
						{mentionMessage.sticker && <div className='chat__message-text'>стикер</div>}
						{mentionMessage.bet?.data && (
							<div className='chat__message-text'>{getBetTitle(mentionMessage)}</div>
						)}
						{mentionMessage.gamble && (
							<div className='chat__message-text'>{mentionMessage.gamble.title}</div>
						)}
					</div>
				</div>
			);
		}
		return null;
	}, [mentionMessage, translateMode]);

	const renderTimeMessage = useCallback(
		() =>
			hiddenAt && !myMessage ? (
				<div className='chat__message-time'>
					{`${chatUsersScreen.hidden} `}
					{`${getLocaleTimeMessage(hiddenAt)} `}
					{hiddenBy && hiddenBy}
				</div>
			) : (
				<div className='chat__message-time'>
					{updatedAt
						? `${chatUsersScreen.edited} ${getLocaleTimeMessage(updatedAt)}`
						: getLocaleTimeMessage(createdAt)}
				</div>
			),
		[createdAt, updatedAt, hiddenAt]
	);

	const renderTimeGamble = useCallback(
		() =>
			gambleMessage?.timestamp && (
				<div className='chat__message-time'>{getLocaleTimeMessage(gambleMessage.timestamp)}</div>
			),
		[gambleMessage?.timestamp]
	);

	const renderHintIcon = () => {
		if (isStand && !roomData?.externalRoomId.includes('betting-')) {
			return (
				<span className='chat__message-hint-icon'>
					<IcoHint />
				</span>
			);
		}
		return null;
	};

	const avatarClickHandler = async (e: any) => {
		if (appReadOnly) {
			e.preventDefault();
			return;
		}
		if (user?.id && !isAutoposted) {
			openProfileType === OpenProfileType.PM && profileUrlPostMessage(user.id);
			sendAnalyticsEvent({userId: user.id, source: 'avatar'});
		}
	};

	const renderAvatarPic = () => {
		return (
			<Avatar
				src={user?.pic ? user.pic : AnonimAva}
				alt={user?.name ? user.name : 'User deleted'}
				className='chat__message-avatar-img'
			/>
		);
	};
	const renderAvatar = () => {
		if (enableOpenProfile)
			switch (openProfileType) {
				case OpenProfileType.GET: {
					return user?.profileUrl ? (
						<a
							href={user.profileUrl}
							target='_blank'
							className={chatMessageAvatarClasses}
							onClick={avatarClickHandler}
							rel='noreferrer'>
							{renderAvatarPic()}
						</a>
					) : (
						<div className='chat__message-avatar'>{renderAvatarPic()}</div>
					);
				}
				case OpenProfileType.PM: {
					return (
						<button type='button' className={chatMessageAvatarClasses} onClick={avatarClickHandler}>
							{renderAvatarPic()}
						</button>
					);
				}
				default: {
					return <div className={chatMessageAvatarClasses}>{renderAvatarPic()}</div>;
				}
			}
		return <div className={chatMessageAvatarClasses}>{renderAvatarPic()}</div>;
	};

	const getText = () => {
		if (appEnableMessageTranslation) {
			if (translateMode.enable) return translate?.text ? translate.text : text;
			return text;
		}
		return text;
	};

	const clickImagePreviewHandler = (images: any[]) => {
		setFiles(images);
		setIsMessagePreview(true);
		toggleImagePreviewModalVisible(true);
	};

	useEffect(() => {
		if (appEnableSubscriptionsFilter && subscriptions.length) {
			setIsSubscription(!!subscriptions.find(el => el.id === talker.id));
		}
	}, [subscriptions]);

	useEffect(() => {
		if (isDesktop) {
			document.addEventListener('contextmenu', onContextMenuHandler);
		}

		return () => {
			if (isDesktop) {
				document.removeEventListener('contextmenu', onContextMenuHandler);
			}
		};
	}, [
		myTalker?.bans?.length,
		myTalker?.user?.bans?.length,
		userData?.isOnboard,
		appEnableOnboarding,
	]);

	useEffect(() => {
		if (submenuMessage === null && chatMessageTopPositionRef.current)
			chatMessageTopPositionRef.current.style.height = '';
	}, [submenuMessage]);

	useEffect(() => {
		if (messageRef.current && messageRef.current.clientHeight > MESSAGE_TEXT_BASE_LINE_HEIGHT) {
			setTextMultiline(true);
		}
	}, [messageRef.current]);

	if (
		type === MessageType.PIC &&
		!text &&
		!picsArray.filter(el => el.isVisible).length &&
		!myMessage
	)
		return null;

	return (
		<div className={chatMessageClasses} {...getDataAttr()} ref={chatMessageRef} {...swipeEvent}>
			{visibleDate && renderDateMessage()}

			<span className={chatMessageContainerHeightClasses} ref={chatMessageContainerHeightRef} />

			<div className={chatMessageWrapperClasses} ref={chatMessageWrapperRef}>
				<span className='chat__message-top-position' ref={chatMessageTopPositionRef} />
				<div className='chat__message-content' ref={chatMessageContentRef}>
					{badgeTooltip?.tooltip && (
						<Tooltip
							referenceElement={referenceBadgeTooltip.current[badgeTooltip.id]}
							position={TooltipPositoinType.TOP}
							text={badgeTooltip.tooltip}
							delay={3000}
							callbackOnHide={() => setBadgeTooltip(null)}
							center
							visibleDate={visibleDate}
						/>
					)}
					<div className='chat__message-container' ref={chatMessageContainerRef}>
						{!myMessage && (
							<div className='chat__message-left'>
								{visibleAvatar && !highlightMessage && renderAvatar()}
							</div>
						)}

						<div className='chat__message-right'>
							{highlightMessage &&
								!pollResultsMessage &&
								!stickerMessage &&
								!stickerPic &&
								!betMessage &&
								!pics &&
								!gambleMessage && (
									<div className='chat__message-body' {...longPressEvent}>
										<Highlight advertisement={highlightMessage} />
										{renderHintIcon()}
									</div>
								)}

							{!highlightMessage &&
								pollResultsMessage &&
								!stickerMessage &&
								!stickerPic &&
								!betMessage &&
								!pics &&
								!gambleMessage && (
									<div className='chat__message-body' {...longPressEvent}>
										{renderHeaderMessage()}
										<PollResults pollResults={pollResultsMessage} />
										{Number(isThreadStarter) !== 0 &&
											threadMessagesCount !== undefined &&
											threadMessagesCount > 0 && (
												<ChatMessageThreadButton message={message} isThread={isThread} />
											)}
									</div>
								)}

							{!highlightMessage &&
								!pollResultsMessage &&
								(stickerMessage || stickerPic) &&
								!betMessage &&
								!pics &&
								!gambleMessage && (
									<div className='chat__message-body' {...longPressEvent}>
										{renderHeaderMessage()}
										{!blockedUsersForFilteredMessages.includes(
											message.mentionMessage?.creatorId as number
										) && renderReplayMessage()}
										<div className='chat__message-text' ref={messageRef}>
											<div className='chat__message-sticker'>
												{stickerMessage && (
													<LazyLoadImage
														src={stickerMessage.pic}
														className='chat__message-sticker-img'
													/>
												)}
												{stickerPic && (
													<LazyLoadImage src={stickerPic} className='chat__message-sticker-img' />
												)}
											</div>

											{reactions && reactions.length > 0 && (
												<Reactions reactions={groupingByKey(reactions)} />
											)}
										</div>
									</div>
								)}

							{!highlightMessage &&
								!pollResultsMessage &&
								!stickerMessage &&
								!stickerPic &&
								!betMessage &&
								!pics &&
								!gambleMessage && (
									<div className='chat__message-body' {...longPressEvent}>
										{renderHeaderMessage()}
										{!blockedUsersForFilteredMessages.includes(
											message.mentionMessage?.creatorId as number
										) && renderReplayMessage()}
										<div className={chatMessageTextClasses} ref={messageRef}>
											<div className='chat__message-text-item'>
												{messageTalkerIsModer ? (
													<Linkify options={LINKIFY_OPTIONS}>
														{checkingMessageFromChatBot(getText())}
													</Linkify>
												) : (
													checkingMessageFromChatBot(getText())
												)}
											</div>
											{messageTalkerIsModer && hasPreview && renderLinkInText()}
											{Number(isThreadStarter) !== 0 &&
												threadMessagesCount !== undefined &&
												threadMessagesCount > 0 && (
													<ChatMessageThreadButton message={message} isThread={isThread} />
												)}
											{reactions && reactions.length > 0 && (
												<Reactions reactions={groupingByKey(reactions)} />
											)}
											<div className='chat__message-footer'>
												<span className='chat__message-translate-caption'>
													{appEnableMessageTranslation &&
														translateMode.enable &&
														isMessageTranslateInProgress &&
														!message.translate?.text &&
														translations.chatScreen.translating}
												</span>

												{renderTimeMessage()}
											</div>
										</div>
										{renderHintIcon()}
									</div>
								)}

							{!highlightMessage &&
								!pollResultsMessage &&
								!stickerMessage &&
								!stickerPic &&
								!pics &&
								betMessage &&
								!gambleMessage && (
									<div className='chat__message-body' {...longPressEvent}>
										{renderHeaderMessage()}
										<BetItem bet={betMessage} myMessage={myMessage} userId={user?.id} />
										{text && (
											<div className={chatMessageTextClasses} ref={messageRef}>
												<div className='chat__message-text-item'>
													{messageTalkerIsModer ? (
														<Linkify options={LINKIFY_OPTIONS}>
															{checkingMessageFromChatBot(getText())}
														</Linkify>
													) : (
														checkingMessageFromChatBot(getText())
													)}
												</div>
												{messageTalkerIsModer && hasPreview && renderLinkInText()}
												{Number(isThreadStarter) !== 0 &&
													threadMessagesCount !== undefined &&
													threadMessagesCount > 0 && (
														<ChatMessageThreadButton message={message} isThread={isThread} />
													)}
												{reactions && reactions.length > 0 && (
													<Reactions reactions={groupingByKey(reactions)} />
												)}
												<div className='chat__message-footer'>
													<span className='chat__message-translate-caption'>
														{appEnableMessageTranslation &&
															translateMode.enable &&
															isMessageTranslateInProgress &&
															!message.translate?.text &&
															translations.chatScreen.translating}
													</span>
												</div>
											</div>
										)}

										{renderHintIcon()}
									</div>
								)}

							{!highlightMessage &&
								!pollResultsMessage &&
								!stickerMessage &&
								!stickerPic &&
								!betMessage &&
								!pics &&
								gambleMessage && (
									<div className='chat__message-body' {...longPressEvent}>
										{/* {renderHeaderMessage()} */}
										<GambleItem gamble={gambleMessage} isMessage />
										{text && (
											<div className={chatMessageTextClasses} ref={messageRef}>
												<div className='chat__message-text-item'>
													{messageTalkerIsModer ? (
														<Linkify options={LINKIFY_OPTIONS}>
															{checkingMessageFromChatBot(getText())}
														</Linkify>
													) : (
														checkingMessageFromChatBot(getText())
													)}
												</div>
												{messageTalkerIsModer && hasPreview && renderLinkInText()}
												{Number(isThreadStarter) !== 0 &&
													threadMessagesCount !== undefined &&
													threadMessagesCount > 0 && (
														<ChatMessageThreadButton message={message} isThread={isThread} />
													)}
												{reactions && reactions.length > 0 && (
													<Reactions reactions={groupingByKey(reactions)} />
												)}
												<div className='chat__message-footer'>
													<span className='chat__message-translate-caption'>
														{appEnableMessageTranslation &&
															translateMode.enable &&
															isMessageTranslateInProgress &&
															!message.translate?.text &&
															translations.chatScreen.translating}
													</span>

													{renderTimeGamble()}
												</div>
											</div>
										)}
										{!text && renderTimeGamble()}
										{renderHintIcon()}
									</div>
								)}

							{!highlightMessage &&
								!pollResultsMessage &&
								!stickerMessage &&
								!stickerPic &&
								!betMessage &&
								!gambleMessage &&
								pics && (
									<div className='chat__message-body' {...longPressEvent}>
										{renderHeaderMessage()}
										{!blockedUsersForFilteredMessages.includes(
											message.mentionMessage?.creatorId as number
										) && renderReplayMessage()}

										{(picsArray.filter(el => el.isVisible).length > 0 || myMessage) && (
											<div className={chatMessagePicsClasses}>
												{picsArray.map(
													(image: any, i: number) =>
														(myMessage || (!myMessage && image.isVisible)) && (
															<div
																className={chatMessagePicClasses(image)}
																key={i}
																data-see-more={
																	userPicsVisible.length > 3
																		? `+${userPicsVisible.length - 3}`
																		: null
																}
																style={{
																	height:
																		userPicsVisible.length < 3
																			? `${(image.height / image.width) * window.innerWidth}px`
																			: 'auto',
																	width:
																		userPicsVisible.length < 3 && userPicsVisible.length > 1
																			? `${image.width}px`
																			: 'auto',
																}}>
																{image.isVisible && (
																	<button
																		type='button'
																		className='chat__message-pics-link'
																		onClick={() => clickImagePreviewHandler(picsArray)}>
																		{' '}
																	</button>
																)}
																{!image.isVisible && !messageTalkerIsModer && (
																	<span className='chat__message-pic-NSFW'>NSFW</span>
																)}
																<div className='chat__message-pic-loader'>
																	{getAppIcon(appLoaderIcon)}
																</div>

																<LazyLoadImage src={image.url} className='chat__message-pic-img' />
															</div>
														)
												)}
											</div>
										)}

										{(text ||
											reactions?.length ||
											(Number(isThreadStarter) !== 0 &&
												threadMessagesCount !== undefined &&
												threadMessagesCount > 0)) && (
											<div className={chatMessageTextClasses} ref={messageRef}>
												<div className='chat__message-text-item'>
													{messageTalkerIsModer ? (
														<Linkify options={LINKIFY_OPTIONS}>
															{checkingMessageFromChatBot(getText())}
														</Linkify>
													) : (
														checkingMessageFromChatBot(getText())
													)}
												</div>
												{messageTalkerIsModer && hasPreview && renderLinkInText()}
												{Number(isThreadStarter) !== 0 &&
													threadMessagesCount !== undefined &&
													threadMessagesCount > 0 && (
														<ChatMessageThreadButton message={message} isThread={isThread} />
													)}
												{reactions && reactions.length > 0 && (
													<Reactions reactions={groupingByKey(reactions)} />
												)}

												<div className='chat__message-footer'>
													<span className='chat__message-translate-caption'>
														{appEnableMessageTranslation &&
															translateMode.enable &&
															isMessageTranslateInProgress &&
															!message.translate?.text &&
															translations.chatScreen.translating}
													</span>

													{renderTimeMessage()}
												</div>
											</div>
										)}

										{renderHintIcon()}
									</div>
								)}
						</div>
					</div>
					{checkVisibleMessageSubmenu && (
						<ChatMessageSubmenu
							message={message}
							reactions={reactions}
							submenuRef={chatSubmenuRef}
							isThread={isThread}
						/>
					)}
				</div>
			</div>
			{isLastMessage && (
				<span className='chat__message-not-viewed'>{chatUsersScreen.unreadMessages}</span>
			)}
		</div>
	);
};

export default observer(ChatMessage);
