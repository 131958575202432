import classNames from 'classnames';
import useAgora from 'hooks/useAgora';
import {observer, useLocalObservable} from 'mobx-react-lite';
import UserRole from 'models/enums/UserRole.enum';
import {FunctionComponent, useEffect, useState} from 'react';
import roomService from 'store/roomService';
import agoraService from 'store/agoraService';
import userService from 'store/userService';
import useL10n from 'l10n/useL10n';
import threadService from 'store/threadService';
import appService from 'store/appService';
import {isDesktop} from 'react-device-detect';

const ChatVideoTrack: FunctionComponent = function ChatVideoTrack() {
	const {myTalker, pinnedMessages} = useLocalObservable(() => roomService);
	const {userData} = useLocalObservable(() => userService);
	const {isMyTalkerShareScreen, isFullScreenVideo, setIsFullScreenVideo} = useLocalObservable(
		() => agoraService
	);
	const {currentThreadId} = useLocalObservable(() => threadService);
	const {appEnableFullscreenOption} = useLocalObservable(() => appService);
	const {stopScreenShareTrack} = useAgora();
	const {screenShare} = useL10n();

	const myTalkerIsModer = !!myTalker?.isModer || !!userData?.isModer;
	const myTalkerSpeaker = myTalker?.role === UserRole.SPEAKER || myTalkerIsModer;

	const isFullScreenMode = appEnableFullscreenOption && isFullScreenVideo && isDesktop;

	const videoTrackClasses = classNames('chat__video-track', {
		'chat__video-track--fullscreen-video': isFullScreenVideo,
		'chat__video-track--fullscreen-mode': isFullScreenMode,
		'chat__video-track--speaker': isMyTalkerShareScreen,
		'chat__video-track--pinned': pinnedMessages?.length,
		'chat__video-track--thread-opened': currentThreadId && !appEnableFullscreenOption,
	});

	const fullScreenHandler = () => {
		if (appEnableFullscreenOption && isDesktop) {
			if (isFullScreenVideo) document.exitFullscreen();
			else document.body.requestFullscreen();
		}

		setIsFullScreenVideo(!isFullScreenVideo);
	};

	const stopScreenShareHandler = () => {
		stopScreenShareTrack();
	};

	useEffect(() => {
		if (isFullScreenMode)
			document.addEventListener('fullscreenchange', event => {
				if (document.fullscreenElement === null) {
					setIsFullScreenVideo(false);
				}
			});
	}, [isFullScreenVideo]);

	return (
		<div className={videoTrackClasses}>
			{!isMyTalkerShareScreen && (
				<button type='button' className='chat__fullscreen-btn' onClick={fullScreenHandler}>
					{' '}
				</button>
			)}
			{myTalkerSpeaker && isMyTalkerShareScreen && (
				<button type='button' className='chat__stop-sharing-btn' onClick={stopScreenShareHandler}>
					{screenShare.stopSharing}
				</button>
			)}
		</div>
	);
};

export default observer(ChatVideoTrack);
