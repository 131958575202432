/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: 'Umowa',
		subTitle: 'Umowa licencyjna i polityka prywatności',
		textFirst: 'Akceptuję warunki i',
		textSecond: 'wyrażam zgodę na przetwarzanie danych osobowych na warunkach określonych przez ',
		license: 'Umowę licencyjną i',
		policy: 'Politykę prywatności',
	},
	controlPanel: {
		streamEnded: 'Transmisja została zakończona',
		banAlert: 'Nie możesz pisać na czacie',
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? 'Naklejki' : 'Emotikony',
	},
	accountDeletedScreen: {
		title: 'Profil usunięty',
		text: (date: string) => `Możesz przywrócić swój profil do ${date}`,
	},
	nameScreen: {
		title: 'Nazwa w czacie',
		inputPlaceholder: 'Wpisz swoją nazwę',
		nameError: 'Niedozwolona nazwa',
	},
	avatarScreen: {
		title: 'Awatar',
		label: 'Wybierz inny:',
	},
	settingsScreen: {
		title: 'Ustawienia',
		personal: 'Ustawienia osobiste',
		room: 'Ustawienia pokoju',
		profileBtn: 'Profil',
		languageBtn: 'Język',
		blockedBtn: 'Zablokowane',
		rules: 'Zasady czatu',
		deleteProfile: 'Usuń profil',
		slowModeBtn: 'Wolny tryb',
		agora: {
			microphone: 'Mikrofon',
			playbackDevice: 'Źródło dźwięku',
		},
	},
	languageScreen: {
		title: 'Język i interfejs',
		messagesTranslate: 'Tłumaczenie wiadomości',
		translateMessagesMode: 'Tłumacz wiadomości w czacie',
		translateMessagesTo: 'Przetłumacz wiadomości na',
	},
	rulesScreen: {
		title: 'Zasady czatu',
	},
	profileScreen: {
		title: 'Profil',
		selectAnotherPic: 'Wybierz inny awatar',
		chatNameLabel: 'Nazwa w czacie',
		changeName: 'zmienić',
	},
	blockedScreen: {
		title: 'Zablokowane',
		emptyListText: 'Brak zablokowanych użytkowników',
	},
	chatScreen: {
		inputPlaceholder: 'Wiadomość...',
		cantWrite: 'Nie możesz pisać na czacie',
		chatSubmenu: {
			hideMessage: 'Ukryj wiadomości',
			showMessage: 'Pokaż wiadomości',
			removeFromSpeakers: 'Usuń z mówiących',
			setSpeaker: 'Zrobić osobą mówiącą',
			hideOnlyMessage: 'Ukryj wiadomość',
			showOnlyMessage: 'Pokaż wiadomość',
			reportMessage: 'Zgłoś wiadomość',
			reportUser: 'Zgłoś użytkownika',
			muteSpeaker: 'Wyłącz mikrofon',
			blockUser: 'Zablokuj',
			unblockUser: 'Odblokuj',
			reply: 'Odpowiedz',
			copy: 'Kopiuj',
			edit: 'Edytui',
			delete: 'Usuń',
			pinMessage: 'Przypnij',
			unpinMessage: 'Odepnij',
			goToProfile: 'Przejdź do profilu',
			replyInThread: 'Porozmawiaj w wątku',
		},
		actionMessage: {
			editing: 'Edycja',
			reply: 'Odpowiedz',
		},
		status: {
			top: 'Top',
		},
		translating: 'Tłumaczenie...',
	},
	chatUsersScreen: {
		title: 'Uczestnicy',
		edited: 'Zmieniono',
		hidden: 'ukryto',
		unreadMessages: 'Nieprzeczytane wiadomości',
		usersSubmenu: {
			muteSpeaker: {
				title: 'Wycisz uczestnika',
				subtitle: 'Wyłącz mikrofon',
			},
			setSpeaker: {
				title: 'Usuń z mówiących',
				subtitle: 'Tylko czat tekstowy',
			},
			removeFromSpeaker: {
				title: 'Zrobić osobą mówiącą',
				subtitle: 'Użytkownik będzie mógł komunikować głosem',
			},
			showMessages: {
				title: 'Pokaż wiadomości',
				subtitle: 'Widoczne dla wszystkich uczestników',
			},
			hideMessages: {
				title: 'Ukryj wiadomości',
				subtitle: 'Widoczne tylko dla administratora',
			},
			blockUser: {
				title: 'Zablokuj',
				subtitle: 'Dodaj do listy zablokowanych',
			},
			unblockUser: {
				title: 'Odblokuj',
				subtitle: 'Pokaż wszystkie wiadomości użytkownika',
			},
			blockUserByUser: {
				title: 'Zablokuj',
				subtitle: 'Nie będziecie widzieć swoich wiadomości',
			},
			unblockUserByUser: {
				title: 'Odblokuj',
				subtitle: 'Pokaż wszystkie wiadomości użytkownika',
			},
			reportUser: {
				title: 'Zgłoś użytkownika',
				subtitle: 'Skargi przesyłane są anonimowo',
			},
		},
		days: {
			today: 'Dzisiaj',
			yesterday: 'Wczoraj',
		},
		chatCreated: 'Czat został utworzony',
	},
	alerts: {
		btns: {
			unblock: 'Odblokuj',
			block: 'Zablokuj',
			send: 'Wyślij',
			sendAndBlock: 'Wyślij i zablokuj',
			show: 'Pokaż',
			showAll: 'Pokaż wszystko',
			cancel: 'Anuluj',
			yes: 'Tak',
			hide: 'Ukryj wybrane',
			hideAll: 'Ukryj wszystko',
			make: 'Zrób',
			remove: 'Remove',
			delete: 'Usuń',
			deleteAccount: 'Usuń profil',
			close: 'Zamknij',
			pin: 'Przypnij',
			unpin: 'Odepnij',
			edit: 'Edytuj',
			end: 'Zakończ',
			endAndShare: 'Zakończ i udostępnij',
			reset: 'Resetuj',
			rules: 'Zasady czatu',
			contactus: 'Napisz do nas',
		},
		pinMessage: {
			title: 'Przypiąć wiadomość?',
		},
		unPinMessage: {
			title: 'Odpiąć wiadomość?',
		},
		accountRestored: {
			title: 'Profil przywrócony',
		},
		closeApp: {
			title: 'Czy naprawdę chcesz wyjść?',
		},
		removeMessage: {
			title: 'Usunąć wiadomość?',
		},
		report: {
			title: 'Wysłać skargę?',
			subtitle:
				'Możesz także zablokuj użytkownika, aby uniemożliwić wzajemne oglądanie wiadomości.',
		},
		banUser: {
			title: (name: string) => `Zablokuj ${name}?`,
			subtitle: (text: string) => `Użytkownik zostanie zablokowany za ${text}`,
		},
		unBanUser: {
			title: (name: string) => `Odblokuj ${name}?`,
		},
		showUserMessages: {
			title: (name: string) => `Show messages from ${name}?`,
			subTitle: 'Other participants will be able to see this user messages',
		},
		hideUserMessages: {
			title: (name: string) => `Hide messages from ${name}?`,
			subTitle: 'Inni użytkownicy będą mogli zobaczyć wiadomości tego użytkownika',
		},
		showUserMessage: {
			title: (name: string) => `Pokazać wiadomość od ${name}?`,
			subTitle: 'Inni użytkownicy będą mogli zobaczyć tę wiadomość',
		},
		hideUserMessage: {
			title: (name: string) => `Ukryć wiadomość od ${name}?`,
			subTitle: 'Inni użytkownicy nie będą już widzieć tej wiadomośc',
		},
		blockUserInRoom: {
			title: (name: string) => `Zablokuj ${name}?`,
			subTitle: 'Użytkownik nie będzie mógł uczestniczyć w tym pokoju',
		},
		unBlockUserInRoom: {
			title: (name: string) => `Odblokuj ${name}?`,
			subTitle: 'Użytkownik będzie mógł uczestniczyć w tym pokoju',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `Zablokuj ${name}?`,
			subTitle: 'Nie będziecie widzieć swoich wiadomości',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `Zrobić z ${name} osobę mówiącą?`,
			unTitle: (name: string) => `Usunąć ${name} z mówiących?`,
		},
		banInApp: {
			title: 'Administrator zablokował cię',
			for: 'za',
			subtitle: (bannedUntil: string) => `Jesteś zablokowany do ${bannedUntil}`,
			permanent: 'Jesteś zablokowany na zawsze',
		},
		banInAppBadNickname: {
			title: 'Administrator zablokował cię z powodu nieprawidłowej nazwy.',
			subtitle: 'Przejdź do swojego profilu, edytui swoją nazwę i ponownie wejdź na czat',
		},
		deleteAccount: {
			title: 'Usuwanie profilu',
			subTitle:
				'Wszystkie dane zostaną usunięte. Będziesz mógł/mogła przywrócić swój profil w ciągu 30 dni.',
		},
		pollEdit: {
			title: 'Edytować ankietę?',
			subTitle: 'Odpowiedzi użytkowników zostaną zresetowane',
		},
		pollReset: {
			title: 'Resetować?',
			subTitle: 'Ankieta nie zostanie zapisana',
		},
		pollEnd: {
			title: 'Czy na pewno chcesz zakończyć ankietę?',
		},
		pollDelete: {
			title: 'Usunąć ankietę?',
		},
		agoraCheck: {
			updateIOSText: 'Zaktualizuj swoją wersję iOS, aby uzyskać pełne możliwości aplikacji.',
			allowAccess: {
				title: 'Chcesz coś powiedzieć?',
				text: 'Aby skorzystać z mikrofonu i głośnika, przejdź do ustawień aplikacji i zezwól na dostęp.',
			},
			microphoneAndSpeakerText: 'Mikrofon i głośnik są dostępne dopiero od wersji 14.3',
			microphoneText: 'Mikrofon dostępny od wersji 14.3',
			btn: 'Ok!',
		},
	},
	eventScreen: {
		title: 'Zaplanowane wydarzenie',
		titleSoon: 'Transmisja rozpocznie się wkrótce',
		subtitle: 'Czat zostanie wkrótce otwarty, proszę czekać.',
		days: 'dni',
		hours: 'godziny',
		minutes: 'minuty',
		seconds: 'sekundy',
		startTitle: 'Początek',
	},
	toasts: {
		reciveComplaint: 'Otrzymaliśmy twoją skargę',
		setSpeakerRoleForUser: 'Użytkownik wyznaczony na osobę mówiącą',
		unSpeakerRoleForUser: 'Użytkownik został usunięty z mówiących',
		messagesShown: 'Wyświetlone wiadomości',
		messagesHidden: 'Wiadomości ukryte',
		messageShown: 'Wyświetlono wiadomość',
		messageHidden: 'Wiadomość ukryta',
		userBlocked: 'Użytkownik jest zablokowany',
		userUnBlocked: 'Użytkownik jest odblokowany',
		youAreSpeaker: 'Jesteś osobą mówiącą! Powiedz coś',
		youAreNotSpeaker: 'Administrator usunął cię z mówiących',
		userIsSpeaker: (name: string) => `${name} jest teraz osobą mówiącą`,
		userNotSpeaker: (name: string) => `${name} nie jest już osobą mówiącą`,
		yourUserNameInChat: (name: string) => `Twoja nazwa na czacie to ${name}`,
		messageRemoved: 'Wiadomość usunięta',
		messageCopied: 'Tekst skopiowany',
		obscenities: 'Spróbuj przeformułować',
		requestSent: 'Zgłoszenie zostało wysłane',
		requestDecline: `Twoja prośba o mikrofon została odrzucona`,
		youareblocked: (text: string) =>
			`Zostałeś/-aś zablokowany za ${text}. Nie możesz pisać na czacie`,
		youareunblocked: 'Zostałeś/-aś odblokowany. Możesz pisać wiadomości na czacie',
		youAreModerator: 'Jesteś moderatorem!',
		youAreNoModerator: 'Nie jesteś już moderatorem',
		micMuted: 'Twój mikrofon jest wyciszony',
		noInet: 'Brak połączenia z internetem',
		messagePinned: 'Przypięta wiadomość',
		messageUnpinned: 'Wiadomość została odpięta',
		pollPublished: 'Ankieta została opublikowana',
		pollPublishedError: 'Błąd! Spróbuj później',
		pollAddPic: 'Dodaj zdjęcie',
		pollAddPicError: 'Wybierz inny obraz',
		pollDeleted: 'Ankieta usunięta',
		pollEnded: 'Ankieta zakończona',
		sendStickerWhenSlowModeIsOn: (time: string) => `Poczekaj ${time} na wysłanie naklejki`,
		streamConnecting: 'Dołączamy do transmisji...',
		linkProhibited: 'Nie możesz wysyłać linków na czacie.',
		phoneProhibited: 'Nie możesz wysyłać numerów telefonów na czacie.',
		profileHidden: 'Użytkownik ukrył swój profil.',
		imageSizeWarning: 'Możesz przesłać obrazek do 10 MB',
		imageBlocked: 'Moderator zablokował przesyłanie obrazka',
		streamKeyCopied: 'Klucz rozgłoszeniowy skopiowany',
	},
	btns: {
		acceptandgo: 'Zaakceptuj i kontynuuj',
		saveandgo: 'Zapisz i kontynuuj',
		save: 'Zapisz',
		unblock: 'Odblokuj',
		block: 'Zablokuj',
		restoreAccount: 'Przywróć profil',
		close: 'Zamknij',
	},
	coopyrights: 'Obsługiwane przez Watchers.io',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message' ? 'Zgłoś wiadomość' : 'Podaj powód zablokowania',
			subtitle: 'Skargi przesyłane są anonimowo',
			btns: {
				violence: 'Wyzwiska',
				provocations: 'Prowokacje',
				personalDetails: 'Dane osobowe',
				flood: 'Flood',
				spam: 'Spam',
				fraudBegging: 'Oszustwo, prośby o datki',
				other: 'Inny',
			},
		},
		ban: {
			title: 'Block this user',
			subtitle: 'Podaj powód zablokowania',
			btns: {
				insults: 'Wyzwiska, groźby, przekleństwa',
				personal: 'Dane osobowe',
				flood: 'Flood',
				links: 'Linki do usług stron trzecich',
				violence: 'Groźby przemocy',
				fraud: 'Oszustwo, prośby o datki',
				nickname: 'Niedozwolona nazwa użytkownika',
				politicial: 'Political, sexualized, and other provocations',
				repeated: 'Powtarzające się naruszenie',
				auto: 'Skargi użytkowników czatu',
				spam: 'Spam',
			},
		},
		waitingSpeakers: {
			text: 'Nie masz oczekujących mówców',
			handsUp: 'Podniosłeś rękę',
			waiting: 'Oczekujący mówcy',
		},
		agreements: {
			license: 'Umowa licencyjna',
			policy: 'Polityka prywatności',
			licenseText: `
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam est fermentum fusce eu
			rutrum nullam ut blandit. Ut quam sapien faucibus blandit at non quis adipiscing. Erat
			rutrum amet dictum purus habitant blandit.

			Amet lorem ornare enim non tincidunt pellentesque facilisis cras diam. Adipiscing nullam
			volutpat, sed convallis fermentum. Elementum volutpat, ultrices ipsum morbi amet. Aliquet
			pretium ipsum dictum tristique vulputate semper laoreet at habitant. Tellus quam ut
			aliquam volutpat vitae.

			Et adipiscing porta porttitor mus ullamcorper et porttitor curabitur. Amet diam bibendum
			odio tincidunt faucibus amet integer. Sagittis, velit ut sit sodales at nec, eleifend
			bibendum. Nulla in semper a, sit mattis lectus eget nunc pellentesque. Volutpat egestas in
			aenean sed. Nullam ultricies commodo, ligula facilisis placerat viverra.

			Pellentesque lectus lorem eget ut rhoncus faucibus amet, etiam. Nulla arcu rhoncus,
			volutpat sit lectus. Condimentum semper eleifend tincidunt tempor metus.

			Tempor facilisis id nec eget at et non. Est id tellus quis elementum, vel risus. Et quis
			in aliquam viverra rhoncus et. Suspendisse pellentesque duis non vel id vulputate dolor eu
			aliquam. In sapien volutpat elementum risus. Fringilla aliquet bibendum nullam volutpat
			sem odio sed.
		`,
			policyText: `
		Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam est fermentum fusce eu
		rutrum nullam ut blandit. Ut quam sapien faucibus blandit at non quis adipiscing. Erat
		rutrum amet dictum purus habitant blandit.

		Amet lorem ornare enim non tincidunt pellentesque facilisis cras diam. Adipiscing nullam
		volutpat, sed convallis fermentum. Elementum volutpat, ultrices ipsum morbi amet. Aliquet
		pretium ipsum dictum tristique vulputate semper laoreet at habitant. Tellus quam ut
		aliquam volutpat vitae.

		Et adipiscing porta porttitor mus ullamcorper et porttitor curabitur. Amet diam bibendum
		odio tincidunt faucibus amet integer. Sagittis, velit ut sit sodales at nec, eleifend
		bibendum. Nulla in semper a, sit mattis lectus eget nunc pellentesque. Volutpat egestas in
		aenean sed. Nullam ultricies commodo, ligula facilisis placerat viverra.

		Pellentesque lectus lorem eget ut rhoncus faucibus amet, etiam. Nulla arcu rhoncus,
		volutpat sit lectus. Condimentum semper eleifend tincidunt tempor metus.

		Tempor facilisis id nec eget at et non. Est id tellus quis elementum, vel risus. Et quis
		in aliquam viverra rhoncus et. Suspendisse pellentesque duis non vel id vulputate dolor eu
		aliquam. In sapien volutpat elementum risus. Fringilla aliquet bibendum nullam volutpat
		sem odio sed.
	`,
		},
		poll: {
			supTitles: {
				typeQuiz: 'Podaj poprawną odpowiedź',
				typeQuizMultiple: 'Zaznacz prawidłowe opcje',
				typePoll: 'Podziel się swoją opinią',
				typePollMultiple: 'Można zaznaczyć kilka opcji',
				default: 'Ankieta publiczna',
			},
			btns: {
				reply: 'Odpowiedz',
				vote: 'Głosować',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: 'Quiz',
				typePoll: 'Kilka odpowiedzi',
				default: 'Ankieta publiczna',
			},
			btns: {
				edit: 'Edytuj ankietę',
				end: 'Zakończ',
			},
		},
		pollCreated: {
			titles: {
				created: 'Utwórz ankietę',
				typeQuiz: 'Quiz',
				typePoll: 'Kilka odpowiedzi',
				default: 'Ankieta publiczna',
			},
		},
		changeName: {
			title: 'Edytui nazwę w czacie.',
			subtitle: 'Administrator zablokował cię z powodu nieprawidłowej nazwy.',
		},
		temporaryAgreement: {
			title: 'Zasady czatu',
			rules: {
				bePolite: {
					title: 'Bądź uprzejmy',
					text: 'Na czacie zabrania się używać nienawistnych i niecenzuralnych wyrażeń. ',
				},
				beAttentive: {
					title: 'Bądź ostrożny',
					text: 'Nie udostępniaj ani nie proś innych o podanie danych osobowych.',
				},
				notAdvertise: {
					title: 'Nie reklamuj',
					text: 'Lepiej podziel się swoją opinią lub emocjami.',
				},
			},
			btns: {
				allRules: 'Wszystkie zasady',
				acceptAndContinue: 'Zaakceptuj i kontynuuj',
			},
		},
		slowMode: {
			title: 'Wolny tryb',
			text: 'Uczestnicy nie będą mogli wysyłać wiadomości na czacie częściej niż w wybranym okresie',
			enable: 'Włącz tryb wolny',
			disable: 'Wyłącz tryb wolny',
			sendTimeout: 'Limit czasu wysyłania',
		},
		slowModeTimeout: {
			title: 'Limit czasu wysyłania',
		},
		chatBot: {
			title: 'Korzystanie z chatbota',
			rules: [
				{
					title: 'Sztuczna inteligencja',
					text: 'Bot symuluje komunikację z ludźmi i dostarcza informacji w oparciu o posiadaną wiedzę. Pamiętaj jednak, że nie zastąpi człowieka, a jego odpowiedzi mogą być ograniczone.',
				},
				{
					title: 'Bot może popełniać błędy',
					text: 'Bot nie może zagwarantować dokładności i wiarygodności informacji.',
				},
			],
			btns: {
				close: 'Zamknij',
			},
		},
		shareBet: {
			title: 'Udostępnij zakład',
			noBetsTitle: 'Nie udało się znaleźć twoich zakładów',
			noBetsText: 'Możesz udostępniać zakłady postawione w ciągu 14 dni.',
			btns: {
				share: 'Udostępnij zakład',
				copy: 'Powtórz zakład',
			},
			status: {
				null: 'Oczekiwanie na losowanie',
				win: 'Wygrana',
				lose: 'Przegrana',
			},
			betTypeV1: {
				express: 'EXPRESS',
				single: 'SINGLE',
			},
			betTypeV2: {
				single: 'Pojed.',
				multi: 'Multi',
				system: 'System',
			},
			totalFactor: 'Współcz',
			bet: 'zakład',
			showMore: 'Pokaż więcej',
			betRepeated: 'Skopiowany zakład',
		},
		record: {
			text: 'Trwa przetwarzanie nagrania transmisji...',
		},
		agoraDevices: {
			title: 'Ustawienia dźwięku',
			text: 'Sprawdź, czy wybrano właściwy mikrofon',
		},
		translate: {
			title: 'Tłumaczenie wiadomości',
			chooseLanguage: 'Wybierz język',
			descr: 'Wszystkie wiadomości czatu zostaną automatycznie przetłumaczone na wybrany język.',
			btn: 'Ok',
		},
		streamSettings: {
			title: 'Ustawienia transmisji',
			description:
				'Pobierz i zainstaluj enkoder wideo. Wprowadź klucz transmisji w jego ustawieniach',
			shareScreen: {
				title: 'Udostępnianie ekranu',
				description: 'Nie są wymagane specjalne ustawienia',
			},
			rtmp: {
				title: 'Zewnętrzne źródło transmisji',
				description: 'Użyj dodatkowego programu do transmisji strumienia',
			},
			streamKey: 'Klucz transmisji',
			showStreamKey: 'Pokaż',
			hideStreamKey: 'Ukryj',
			btns: {
				continue: 'Kontynuuj',
				close: 'Zamknij',
				ok: 'Gotowe',
			},
		},
	},
	pinnedMessage: {
		title: 'Przypięta wiadomość',
	},
	errorPage: {
		error: 'Błąd',
		types: [
			// errorNumber 0
			{
				title: 'Nie rozpoznajemy cię',
				text: 'Spróbuj ponownie wejść na czat',
			},
			// errorNumber 1
			{
				title: 'Can not find the room',
				text: 'Try to re-enter',
			},
			// errorNumber 2
			{
				title: 'Nie możemy znaleźć czatu',
				text: 'Spróbuj wejść ponownie',
			},
			// errorNumber 3
			{
				title: 'Nie rozpoznajemy cię',
				text: 'Czat w trybie incognito jest niedostępny',
			},
			// errorNumber 4
			{
				title: 'Niedozwolona nazwa użytkownika',
				text: 'Go to main profile settings and change your nickname',
			},
		],
	},
	tooltips: {
		emotions: 'Przytrzymaj, aby zmienić',
		badInet: 'Słabe połączenie sieciowe',
		blockedUsers: 'Listę zablokowanych użytkowników możesz zobaczyć tutaj.',
		waitingSpeakers: 'Ktoś z uczestników chce zabrać głos.',
		wantSpeak: 'Kliknij, aby poprosić o prawo głosu.',
		slowMode: {
			header: (time: string) =>
				`Tryb wolny jest włączony. Użytkownicy mogą wysłać jedną wiadomość <br>każd${time}. Ta zasada nie dotyczy moderatorów.`,
			btnSendMessage: (time: string) => `Możesz wysyłać wiadomości <br> co ${time}.`,
		},
		shareBet: 'Udostępnij zakład na czacie',
		translate: 'Przetłumacz wiadomości na inny język',
	},
	poll: {
		toggleSubmenu: {
			create: 'Utwórz ankietę',
			edit: 'Edytuj ankietę',
			show: 'Pokaż ankietę',
			end: 'Zakończ ankietę',
		},
		tooltip: {
			btn: 'Głosować',
		},
		settings: {
			title: 'Ustawienia',
			titleAdditionally: 'Dodatkowo',
			switches: {
				quizMode: 'Tryb quizu',
				multipleChoice: 'Wielokrotny wybór',
				withPhoto: 'Ankieta ze zdjęciem',
			},
		},
		question: {
			title: 'Pytanie',
			inputPlaceholder: 'Wpisz pytanie',
		},
		message: {
			title: 'Zagłosowałeś',
		},
		answers: {
			title: 'Opcje odpowiedzi',
			warning: 'Możesz dodać maksymalnie 5 odpowiedzi',
		},
		answer: {
			tooltip: 'Wybierz poprawną odpowiedź',
			inputPlaceholder: 'Odpowiedź',
		},
		results: {
			text: 'Wyniki głosowania',
			votes: 'głosów',
			// words: ['głos', 'głosy', 'głosów'],
		},
		btns: {
			addQuestion: 'Dodaj pytanie',
			addAnswers: 'Dodaj odpowiedzi',
			addAnswer: 'Dodaj odpowiedź',
			publish: 'Publikuj',
			vote: 'Głosuj',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: 'Oceń',
				continue: 'Kontynuuj',
				checkConnection: 'Sprawdź połączenie',
				close: 'Zamknij',
			},
			quality: {
				title: 'Oceń jakość dźwięku',
			},
			reason: {
				title: 'Coś poszło nie tak?',
			},
			connection: {
				title: 'Sprawdź jakość połączenia',
				text: 'Sprawdzanie zajmie nie więcej niż minutę i pomoże ci znaleźć przyczynę problemu.',
			},
			statistics: {
				title: 'Zbieramy statystykę...',
			},
			done: {
				title: 'Dziękuję!',
				text: 'Przeanalizujemy, co poszło nie tak i naprawimy to.',
			},
		},
	},
	chatBot: {
		message: 'Zapytaj bota o coś...',
		warnings: {
			disabled: 'Zostałem rozłączony',
			overloaded: 'Jesteśmy przeciążeni',
		},
	},
	audioReason: {
		nothear: 'Nic nie było słychać',
		connection: 'Dźwięk przerywał',
		microphone: 'Nie można było użyć mikrofonu',
	},

	copybetting: {
		orderStatus: {
			won: 'Wygrana',
			lost: 'Przegrana',
			new: 'Nowy',
			pending: 'oczekujące',
			cashedOut: 'Wypłacone',
			boreDraw: 'Bore draw',
			cashback: 'Cashback',
			megaBetNew: 'MegaBet Nowy',
			megaBetCanceledByOperator: 'MegaBet Canceled By Operator',
			megaBetCanceledByPlayer: 'MegaBet Anulowanie przez gracza',
			megaBetCanceledBySystem: 'MegaBet Anulowanie przez system',
			betGamesPending: 'BetGames Pending',
			betGamesWon: 'BetGames Won',
			betGamesLost: 'BetGames Lost',
			paid: 'zapłacone',
			initialWin: 'Początkowa wygrana',
			autoBetCanceledBySystem: 'AutoBet Anulowanie przez system ',
			autoBetCanceledByPlayer: 'AutoBet Anulowanie przez gracza',
			autobetPending: 'Autobet oczekujące',
			goalAhead: 'Goal Ahead',
			canceled: 'Anulowanie',
			return: 'Zwrot',
		},
		stakeStatus: {
			pending: 'oczekujące',
			won: 'Wygrana',
			lost: 'Przegrana',
			return: 'Zwrot ',
			lostReturn: 'Przegrana | Zwrot ',
			wonReturn: 'Wygrana | Zwrot ',
			canceled: 'Odwołany',
			initialReturn: 'Zwrot wych. ',
			pendingInitialReturn: 'oczekujące | Zwrot wych.',
			wonInitialReturn: 'Wygrana | Zwrot wych.',
		},
	},
	screenShare: {
		title: 'Pokaz ekranu',
		stopSharing: 'Zatrzymaj transmisję',
		modal: {
			title: 'Możesz udostępniać ekran tylko z komputera',
			text: `Otwórz czat na komputerze i kliknij przycisk „Pokaz ekranu” w prawym górnym rogu.`,
			btn: 'Skopiuj link do logowania',
		},
	},
	threads: {
		title: 'Wątek',
		back: 'Powrót',
		replies: ['odpowiedź', 'odpowiedzi', 'odpowiedzi'],
	},
	attach: {
		submenu: {
			sendImage: 'Wyślij obrazek',
			shareBet: 'Podziel się zakładem',
			shareGamble: 'Podziel się wygraną',
		},
		images: ['obraz', 'obrazy', 'obrazów'],
		addCaption: 'Dodaj podpis',
	},
} as unknown as Dictionary;
