import ResponseStatus from 'models/enums/ResponseStatus.enum';
import appService from 'store/appService';
import userServices from 'store/userService';
import roomServices from 'store/roomService';
import UserService from 'services/api/UserService';
import React, {createRef, useCallback, useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import classNames from 'classnames/bind';
import modalServices from 'store/modalService';
import './chatNameForm.scss';
import {encryptionUserName} from 'utils/encryptionUserName';
import debounce from 'lodash/debounce';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import useAntimat from 'hooks/useAntimat';
import {useLocation} from 'react-router-dom';
import {isDesktop, isMobile} from 'react-device-detect';
import {OnboardStage} from 'models/enums/OnboardStage.enum';
import {Field} from 'components/hoc';
import Button from 'components/hoc/Button';
import {ReactComponent as IcoCheckmark} from 'assets/svg/ico-checkmark.svg';

interface IAgreementsProps {
	setCurrentScreen?: () => void;
	checkNameChange?: boolean;
	fromChangeNameModal?: boolean;
}

const ChatNameForm: React.FC<IAgreementsProps> = function ChatNameForm(props) {
	const {setCurrentScreen, checkNameChange = true, fromChangeNameModal} = props;
	const location = useLocation();
	const textInputRef = createRef<HTMLInputElement>();
	const [text, setText] = useState('');
	const [disabledBtn, setDisabledBtn] = useState(checkNameChange);
	const [valid, setValid] = useState(true);
	const {language} = useLocalObservable(() => appService);
	const {accessToken, userData, updateUserData} = useLocalObservable(() => userServices);
	const {talkers, updateTalker, updateUserMessagesByUserId} = useLocalObservable(
		() => roomServices
	);
	const {toggleChangeNameModal} = useLocalObservable(() => modalServices);
	const textTrim = encryptionUserName(text.trim());
	const translations = useL10n();
	const {sendAnalytics} = useAnalytics();
	const {containsMat} = useAntimat();

	const onContainsMatHandler = useCallback(
		debounce(
			async (value: string) => {
				if (value) {
					const {status, isVailable} = await containsMat(value, language, () =>
						setDisabledBtn(true)
					);
					if (status === ResponseStatus.SUCCESS) {
						if (isVailable) {
							if (value.trim() !== userData?.name || !checkNameChange) {
								setValid(true);
								setDisabledBtn(false);
								return;
							}
						}

						if (!isVailable) {
							setValid(false);
							setDisabledBtn(true);
							return;
						}
					}

					setDisabledBtn(true);
				}
			},
			100,
			{leading: false}
		),
		[language]
	);

	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = e.target;
		setText(value);
		onContainsMatHandler(value);
	};

	const chatnameFormClasses = classNames('chatname-form', {
		'chatname-form--no-valid': textTrim.length && !valid && textTrim !== userData?.name,
		'chatname-form--valid': textTrim.length && valid,
	});

	const onPressSaveBtnHandler = async () => {
		const {isVailable}: any = await containsMat(textTrim, language);
		if (accessToken && isVailable) {
			if (userData?.name === textTrim) {
				const response = await UserService.patchUserData(
					{onboardStage: OnboardStage.AVATAR},
					accessToken
				);

				if (response.status === ResponseStatus.SUCCESS) {
					setCurrentScreen && setCurrentScreen();
				}

				return;
			}

			const response = await UserService.patchUserData(
				{name: textTrim, onboardStage: OnboardStage.AVATAR},
				accessToken
			);

			if (response.status === ResponseStatus.SUCCESS) {
				if (userData) {
					updateUserData({name: textTrim, onboardStage: OnboardStage.AVATAR});

					if (userData.id) {
						updateUserMessagesByUserId(userData.id, 'name', textTrim);
					}
				}
				fromChangeNameModal ? sendAnalytics('name_changed') : sendAnalytics('name_set');
				setCurrentScreen && setCurrentScreen();
			}
		}
	};

	useEffect(() => {
		if (userData?.name?.length) {
			setText(userData.name);
		}
		textInputRef.current?.focus();
	}, []);

	const onCheckKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (isMobile && e.key === 'Enter') {
			textInputRef.current?.blur();
		}
		if (e.key === 'Enter' && !e.shiftKey && isDesktop && !disabledBtn && textTrim.length) {
			onPressSaveBtnHandler();
			e.preventDefault();
		}
	};

	return (
		<section className={chatnameFormClasses}>
			<Field
				fieldType='input'
				innerRef={textInputRef}
				type='text'
				placeholder={translations.nameScreen.inputPlaceholder}
				className='chatname-form__input'
				value={text}
				maxLength={25}
				onKeyDown={onCheckKeyPress}
				onChange={onChangeHandler}
			/>

			<span className='chatname-form__checkmark'>
				<IcoCheckmark />
			</span>

			<span className='chatname-form__warn'>{translations.nameScreen.nameError}</span>

			<Button
				disabled={!textTrim.length || disabledBtn}
				onClick={onPressSaveBtnHandler}
				className='chatname-form__btn btn'>
				{translations.btns.saveandgo}
			</Button>
		</section>
	);
};

export default observer(ChatNameForm);
