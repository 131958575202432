/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: 'Угода',
		subTitle: 'Ліцензійна угода та політика конфіденційності',
		textFirst: 'Я приймаю умови',
		textSecond: 'та даю свою згоду на обробку персональних даних на умовах, визначених',
		license: 'Ліцензійною угодою',
		policy: 'Політикою конфіденційності.',
	},
	controlPanel: {
		streamEnded: 'Трансляцію завершено',
		banAlert: 'Ви не можете писати в чат',
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? 'Стикеры' : 'Emoji',
	},
	accountDeletedScreen: {
		title: 'Профіль видалено',
		text: (date: string) => `Ви можете відновити свій профіль до ${date}`,
	},
	nameScreen: {
		title: "Ім'я в чаті",
		inputPlaceholder: "Введіть ім'я",
		nameError: "Неприпустиме ім'я",
	},
	avatarScreen: {
		title: 'Аватар',
		label: 'Вибрати інший:',
	},
	settingsScreen: {
		title: 'Налаштування',
		personal: 'Персональні налаштування',
		room: 'Налаштування кімнати',
		profileBtn: 'Профіль',
		languageBtn: 'Мова та інтерфейс',
		blockedBtn: 'Заблоковані',
		rules: 'Правила чату',
		deleteProfile: 'Видалити профіль',
		slowModeBtn: 'Повільний режим',
		agora: {
			microphone: 'Мікрофон',
			playbackDevice: 'Джерело звуку',
		},
	},
	languageScreen: {
		title: 'Мова та інтерфейс',
		messagesTranslate: 'Переклад повідомлень',
		translateMessagesMode: 'Перекладати повідомлення в чаті',
		translateMessagesTo: 'Перекладати повідомлення на',
	},
	rulesScreen: {
		title: 'Правила чату',
	},
	profileScreen: {
		title: 'Профіль',
		selectAnotherPic: 'Вибрати інший аватар',
		chatNameLabel: "Ім'я в чаті",
		changeName: 'Змінити',
	},
	blockedScreen: {
		title: 'Заблоковані',
		emptyListText: 'Немає заблокованих користувачів',
	},
	chatScreen: {
		inputPlaceholder: 'Повідомлення...',
		cantWrite: 'Ви не можете написати в чат',
		chatSubmenu: {
			hideMessage: 'Приховати повідомлення',
			showMessage: 'Показати повідомлення',
			removeFromSpeakers: 'Прибрати зі спікерів',
			setSpeaker: 'Зробити спікером',
			hideOnlyMessage: 'Приховати повідомлення',
			showOnlyMessage: 'Показати повідомлення',
			reportMessage: 'Пожаловаться на повідомлення',
			reportUser: 'Пожаловаться на користувача',
			muteSpeaker: 'Выключить микрофон',
			blockUser: 'Заблокувати',
			unblockUser: 'Розблокувати',
			reply: 'Відповісти',
			copy: 'Копіювати',
			edit: 'Змінити',
			delete: 'Удалить',
			pinMessage: 'Закріпити',
			unpinMessage: 'Відкріпити',
			goToProfile: 'Перейти в профіль',
			replyInThread: 'Відповісти в треді',
		},
		actionMessage: {
			editing: 'Редагування',
			reply: 'Відповісти',
		},
		status: {
			top: 'Топ',
		},
		translating: 'Перекладаємо...',
	},
	chatUsersScreen: {
		title: 'Учасники',
		edited: 'Змінено',
		hidden: 'Приховано',
		unreadMessages: 'Непрочитані повідомлення',
		usersSubmenu: {
			muteSpeaker: {
				title: 'Заглушити учасника',
				subtitle: 'Вимкнути мікрофон',
			},
			setSpeaker: {
				title: 'Прибрати зі спікерів',
				subtitle: 'Тільки текстовий чат',
			},
			removeFromSpeaker: {
				title: 'Зробити спікером',
				subtitle: 'Користувач зможе розмовляти голосом',
			},
			showMessages: {
				title: 'Показати повідомлення',
				subtitle: 'Видно тільки адміну',
			},
			hideMessages: {
				title: 'Приховати повідомлення',
				subtitle: 'Видно тільки адміну',
			},
			blockUser: {
				title: 'Заблокувати',
				subtitle: 'Додати до стоп-списку',
			},
			unblockUser: {
				title: 'Розблокувати',
				subtitle: 'Розблокуйте користувача',
			},
			blockUserByUser: {
				title: 'Заблокувати',
				subtitle: 'Приховати всі повідомлення користувача',
			},
			unblockUserByUser: {
				title: 'Розблокувати',
				subtitle: 'Показати всі повідомлення користувача',
			},
			reportUser: {
				title: 'Пожаловаться на користувача',
				subtitle: 'Скаргу буде надіслано анонімно',
			},
		},
		days: {
			today: 'Сьогодні',
			yesterday: 'Вчора',
		},
		chatCreated: 'Чат створений,',
	},
	alerts: {
		btns: {
			unblock: 'Розблокувати',
			block: 'Заблокувати',
			send: 'Відправити',
			sendAndBlock: 'Надіслати і заблокувати',
			show: 'Показати',
			showAll: 'Показати все',
			cancel: 'Відміна',
			yes: 'Так',
			hide: 'Приховати вибране',
			hideAll: 'Приховати все',
			make: 'Зробити',
			remove: 'Прибрати',
			delete: 'Видалити',
			deleteAccount: 'Видалити профіль',
			close: 'Закрити',
			pin: 'Закріпити',
			unpin: 'Відкріпити',
			edit: 'Редагувати',
			end: 'Завершити',
			endAndShare: 'Завершити і поділитися',
			reset: 'Скинути',
			rules: 'Правила чату',
			contactus: 'Написати нам',
		},
		pinMessage: {
			title: 'Закріпити повідомлення?',
		},
		unPinMessage: {
			title: 'Відкріпити повідомлення?',
		},
		accountRestored: {
			title: 'Профіль відновлено',
		},
		closeApp: {
			title: 'Ви справді хочете вийти?',
		},
		removeMessage: {
			title: 'Видалити повідомлення?',
		},
		report: {
			title: 'Надіслати скаргу?',
			subtitle: 'Ви також можете заблокувати користувача, щоб не бачити повідомлення один одного.',
		},
		banUser: {
			title: (name: string) => `Заблокувати ${name}?`,
			subtitle: (text: string) => `Користувач буде заблокований за ${text}`,
		},
		unBanUser: {
			title: (name: string) => `Розблокувати ${name}?`,
		},
		showUserMessages: {
			title: (name: string) => `Показати повідомлення від ${name}?`,
			subTitle: 'Інші користувачі зможуть бачити повідомлення цього користувача.',
		},
		hideUserMessages: {
			title: (name: string) => `Приховати повідомлення від ${name}?`,
			subTitle: 'Інші користувачі більше не зможуть бачити повідомлення цього користувача',
		},
		showUserMessage: {
			title: (name: string) => `Приховати повідомлення від ${name}?`,
			subTitle: 'Інші користувачі більше не зможуть бачити повідомлення цього користувача.',
		},
		hideUserMessage: {
			title: (name: string) => `Приховати повідомлення від ${name}?`,
			subTitle: 'Інші користувачі більше не зможуть бачити це повідомлення.',
		},
		blockUserInRoom: {
			title: (name: string) => `Заблокувати ${name}?`,
			subTitle: 'Користувач не зможе брати участь у цій кімнаті.',
		},
		unBlockUserInRoom: {
			title: (name: string) => `Розблокувати ${name}?`,
			subTitle: 'Користувач зможе брати участь у цій кімнаті.',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `Заблокувати ${name}?`,
			subTitle: 'Ви не будете бачити повідомлення один одного.',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `Зробити ${name} спікером?`,
			unTitle: (name: string) => `Прибрати ${name} зі спікерів?`,
		},
		banInApp: {
			title: 'Адміністратор заблокував вас',
			for: 'за',
			subtitle: (bannedUntil: string) => `Ви заблоковані до ${bannedUntil}`,
			permanent: 'Вас заблоковано назавжди',
		},
		banInAppBadNickname: {
			title: "Адміністратор заблокував вас за неприпустиме ім'я.",
			subtitle: "Зайдіть у свій профіль, змініть ім'я та увійдіть до чату заново.",
		},
		deleteAccount: {
			title: 'Видалення профілю',
			subTitle: 'Усі дані будуть видалені. Ви зможете відновити профіль протягом 30 днів.',
		},
		pollEdit: {
			title: 'Редагувати опитування?',
			subTitle: 'Відповіді користувачів будуть скинуті.',
		},
		pollReset: {
			title: 'Скинути?',
			subTitle: 'Опитування не буде збережено.',
		},
		pollEnd: {
			title: 'Ви дійсно хочете завершити опитування?',
		},
		pollDelete: {
			title: 'Видалити опитування?',
		},
		agoraCheck: {
			updateIOSText: 'Обновите версию iOS, чтобы использовать все возможности приложения.',
			allowAccess: {
				title: 'Хочете щось сказати?',
				text: 'Щоб використовувати мікрофон і динамік, зайдіть у налаштування застосунку та дозвольте доступ.',
			},
			microphoneAndSpeakerText: 'Мікрофон і динамік доступні тільки з версії 14.3',
			microphoneText: 'Мікрофон доступний з версії 14.3',
			btn: 'Зрозуміло',
		},
	},
	eventScreen: {
		title: 'Запланована подія',
		titleSoon: 'Трансляція скоро розпочнеться',
		subtitle: 'Чат скоро відкриється, почекайте.',
		days: 'дні',
		hours: 'години',
		minutes: 'хвилини',
		seconds: 'секунди',
		startTitle: 'Початок',
	},
	toasts: {
		reciveComplaint: 'Ми отримали вашу скаргу',
		setSpeakerRoleForUser: 'Користувача призначено спікером',
		unSpeakerRoleForUser: 'Користувача видалено зі спікерів',
		messagesShown: 'Повідомлення показані',
		messagesHidden: 'Повідомлення приховані',
		messageShown: 'Повідомлення показано',
		messageHidden: 'Повідомлення приховано',
		userBlocked: 'Користувач заблокований',
		userUnBlocked: 'Користувач розблокований',
		youAreSpeaker: 'Ви спікер! Скажіть що-небудь',
		youAreNotSpeaker: 'Адміністратор видалив вас зі спікерів',
		userIsSpeaker: (name: string) => `${name} тепер спікер`,
		userNotSpeaker: (name: string) => `${name} більше не спікер`,
		yourUserNameInChat: (name: string) => `Ваше ім'я в чаті - ${name}`,
		messageRemoved: 'Повідомлення видалено',
		messageCopied: 'Текст скопійовано',
		obscenities: 'Спробуйте перефразувати',
		requestSent: 'Запит надіслано',
		requestDecline: 'Ваш запит на мікрофон відхилено',
		youareblocked: (text: string) => `Вас заблокували за ${text}. Ви не можете писати в чат`,
		youareunblocked: 'Вас розблокували. Ви можете писати повідомлення в чат',
		youAreModerator: 'Ви модератор!',
		youAreNoModerator: 'Ви більше не модератор.',
		micMuted: 'Ваш мікрофон заглушено',
		noInet: "Відсутнє інтернет-з'єднання",
		messagePinned: 'Повідомлення закріплено',
		messageUnpinned: 'Повідомлення відкріплено',
		pollPublished: 'Опитування опубліковано',
		pollPublishedError: 'Помилка! Спробуйте пізніше',
		pollAddPic: 'Додайте фото',
		pollAddPicError: 'Виберіть інше зображення',
		pollDeleted: 'Опитування видалено',
		pollEnded: 'Опитування завершено',
		sendStickerWhenSlowModeIsOn: (time: string) => `Зачекайте ${time} для надсилання стікера`,
		streamConnecting: 'Підключаємось до трансляції...',
		linkProhibited: 'Ви не можете надсилати посилання на чат.',
		phoneProhibited: 'Ви не можете надсилати номери телефонів у чат.',
		profileHidden: 'Користувач приховав профіль.',
		imageSizeWarning: 'Можна завантажити картинку не більше 10 MB',
		imageBlocked: 'Модератор заблокував надсилання зображення',
		streamKeyCopied: 'Ключ трансляції скопійовано',
	},
	btns: {
		acceptandgo: 'Прийняти і продовжити',
		saveandgo: 'Зберегти та продовжити',
		save: 'Зберегти',
		unblock: 'Розблокувати',
		block: 'Заблокувати',
		restoreAccount: 'Відновити профіль',
		close: 'Закрити',
	},
	coopyrights: 'Працює на базі Watchers.io',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message' ? 'Пожаловаться на повідомлення' : 'Повідомити про порушення',
			subtitle: 'Скарги надсилаються анонімно',
			btns: {
				violence: 'Образи, погрози, лайка',
				provocations: 'Провокації',
				personalDetails: 'Персональні дані',
				flood: 'Флуд',
				spam: 'Спам',
				fraudBegging: 'Шахрайство, жебрацтво',
				other: 'Інше',
			},
		},
		ban: {
			title: 'Заблокувати користувача',
			subtitle: 'Вкажіть причину блокування',
			btns: {
				insults: 'Образи, погрози, лайка',
				personal: 'Поширення персональних даних',
				flood: 'Флуд',
				links: 'Посилання на сторонні сервіси',
				violence: 'Погрози насильства',
				fraud: 'Спроби шахрайства та жебракування',
				nickname: 'Неприпустимий нікнейм',
				politicial: 'Політичні, сексуалізовані та інші провокації',
				repeated: 'Повторне порушення',
				auto: 'Скарги користувачів чату',
				spam: 'Спам',
			},
		},
		waitingSpeakers: {
			text: 'У вас немає спікерів, які очікують',
			handsUp: 'Підняли руку',
			waiting: 'Спікери, які очікують',
		},
		poll: {
			supTitles: {
				typeQuiz: 'Дайте правильну відповідь',
				typeQuizMultiple: 'Позначте правильні варіанти',
				typePoll: 'Поділіться думкою',
				typePollMultiple: 'Можна відзначити кілька варіантів',
				default: 'Публічне опитування',
			},
			btns: {
				reply: 'Відповісти',
				vote: 'Голосувати',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: 'Вікторина',
				typePoll: 'Кілька відповідей',
				default: 'Публічне опитування',
			},
			btns: {
				edit: 'Редагувати опитування',
				end: 'Завершити',
			},
		},
		pollCreated: {
			titles: {
				created: 'Створити опитування',
				typeQuiz: 'Вікторина',
				typePoll: 'Кілька відповідей',
				default: 'Публічне опитування',
			},
		},
		changeName: {
			title: "Змініть ім'я в чаті",
			subtitle: 'Вас заблоковано за неприпустимий нікнейм',
		},
		temporaryAgreement: {
			title: 'Правила чату',
			rules: {
				bePolite: {
					title: 'Будьте ввічливі',
					text: 'У чаті заборонені ворожі та нецензурні висловлювання.',
				},
				beAttentive: {
					title: 'Будьте уважні',
					text: 'Не ділитесь особистими даними та не просіть їх у інших.',
				},
				notAdvertise: {
					title: 'Не займайтеся рекламою',
					text: 'Краще поділіться думкою чи емоціями.',
				},
			},
			btns: {
				allRules: 'Усі правила',
				acceptAndContinue: 'Прийняти та продовжити',
			},
		},
		slowMode: {
			title: 'Повільний режим',
			text: 'Учасники не зможуть надсилати повідомлення в чат частіше, ніж в обраний проміжок часу',
			enable: 'Увімкнути повільний режим',
			disable: 'Вимкнути повільний режим',
			sendTimeout: 'Таймаут надсилання',
		},
		slowModeTimeout: {
			title: 'Таймаут надсилання',
		},
		chatBot: {
			title: 'Використання чат-бота',
			rules: [
				{
					title: 'Штучний інтелект',
					text: "Бот імітує спілкування з людьми та надає інформацію в силу своїх знань. Однак пам'ятайте, що його відповіді можуть бути обмеженими і не замінять людину.",
				},
				{
					title: 'Бот може помилятися',
					text: 'Бот не може гарантувати точність і достовірність інформації.',
				},
			],
			btns: {
				close: 'Закрити',
			},
		},
		shareBet: {
			title: 'Поділитися',
			noBetsTitle: 'Не змогли знайти ваші парі',
			noBetsText: 'Можна поділитися парі, які укладені протягом 14 днів',
			btns: {
				share: 'Поділитися ставкою',
				copy: 'Повторити парі',
			},
			status: {
				null: 'У грі',
				win: 'Виграш',
				lose: 'Програш',
			},
			betTypeV1: {
				express: 'Експрес',
				single: 'Одинар',
			},
			betTypeV2: {
				single: 'Одинар.',
				multi: 'Множ.',
				system: 'System',
			},
			totalFactor: 'Коеф',
			bet: 'парі',
			showMore: 'Показати ще',
			betRepeated: 'Повторили ставку',
		},
		record: {
			text: 'Запис ефіру обробляється...',
		},
		agoraDevices: {
			title: 'Налаштування аудіо',
			text: 'Перевірте, що обрано правильний мікрофон',
		},
		translate: {
			title: 'Переклад повідомлень',
			chooseLanguage: 'Вибрати мову',
			descr: 'Всі повідомлення в чаті будуть автоматично переведені на вибрану мову.',
			btn: 'Включити переклад',
		},
		streamSettings: {
			title: 'Налаштування трансляції',
			description:
				'Завантажте та встановіть відео-кодер. Введіть ключ трансляції у його налаштуваннях',
			shareScreen: {
				title: 'Спільний доступ до екрану',
				description: 'Спеціальні налаштування не потрібні',
			},
			rtmp: {
				title: 'Зовнішнє джерело трансляції',
				description: 'Використовуйте додаткову програму для трансляції',
			},
			streamKey: 'Ключ трансляції',
			showStreamKey: 'Показати',
			hideStreamKey: 'Приховати',
			btns: {
				continue: 'Продовжити',
				close: 'Закрити',
				ok: 'Готово',
			},
		},
	},
	pinnedMessage: {
		title: 'Закріплене повідомлення',
	},
	errorPage: {
		error: 'Помилка',
		types: [
			// errorNumber 0
			{
				title: 'Ми вас не впізнаємо',
				text: 'Спробуйте перезайти в чат',
			},
			// errorNumber 1
			{
				title: 'Не можемо знайти чат',
				text: 'Спробуйте перезайти',
			},
			// errorNumber 2
			{
				title: 'Неможливо завантажити чат',
				text: 'Спробуйте перезавантажити',
			},
			// errorNumber 3
			{
				title: 'Ми вас не впізнаємо',
				text: 'Чат у режимі інкогніто недоступний',
			},
			// errorNumber 4
			{
				title: 'Неприпустимий нікнейм',
				text: 'Зайдіть у свій профіль, змініть нікнейм і увійдіть до чату заново.',
			},
		],
	},
	tooltips: {
		emotions: 'Утримайте, щоб змінити',
		badInet: "Погане з'єднання з інтернетом",
		blockedUsers: 'Переглянути список заблокованих користувачів можна тут.',
		waitingSpeakers: 'Хтось із учасників хоче висловитися.',
		wantSpeak: 'Натисніть, щоб запросити право голосу.',
		slowMode: {
			header: (time: string) =>
				`можуть надсилати одне повідомлення на кожен ${time}. Це правило не стосується модераторів`,
			btnSendMessage: (time: string) => `Ви можете надсилати повідомлення <br> кожні ${time}.`,
		},
		shareBet: 'Поділитися ставкою в чаті',
		translate: 'Перекласти повідомлення іншою мовою',
	},
	poll: {
		toggleSubmenu: {
			create: 'Створити опитування',
			edit: 'Редагувати опитування',
			show: 'Показати опитування',
			end: 'Завершити опитування',
		},
		tooltip: {
			btn: 'Голосувати',
		},
		settings: {
			title: 'Налаштування',
			titleAdditionally: 'Додатково',
			switches: {
				quizMode: 'Режим вікторини',
				multipleChoice: 'Вибір декількох відповідей',
				withPhoto: 'Опитування з картинками',
			},
		},
		question: {
			title: 'Запитання',
			inputPlaceholder: 'Введіть запитання',
		},
		message: {
			title: 'Ви проголосували',
		},
		answers: {
			title: 'Варіанти відповіді',
			warning: 'Ви можете додати до 5 відповідей',
		},
		answer: {
			tooltip: 'Виберіть правильну відповідь',
			inputPlaceholder: 'Відповідь',
		},
		results: {
			text: 'Результати голосування',
			votes: 'голосів',
			// words: ['голос', 'голоси', 'голосів'],
		},
		btns: {
			addQuestion: 'Додати запитання',
			addAnswers: 'Додати відповіді',
			addAnswer: 'Додати відповідь',
			publish: 'Опублікувати',
			vote: 'Голосуйте',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: 'Оцінити',
				continue: 'Продовжити',
				checkConnection: "Перевірити з'єднання",
				close: 'Закрити',
			},
			quality: {
				title: 'Оцініть якість звуку',
			},
			reason: {
				title: 'Що пішло не так?',
			},
			connection: {
				title: "Протестуйте якість з'єднання",
				text: 'Перевірка займе не більше хвилини і допоможе знайти причину проблеми',
			},
			statistics: {
				title: 'Збираємо статистику...',
			},
			done: {
				title: 'Дякую!',
				text: "Ми вивчимо, що пішло не так, і обов'язково виправимо.",
			},
		},
	},
	chatBot: {
		message: 'Запитайте бота про що-небудь...',
		warnings: {
			disabled: 'Мене відключили',
			overloaded: 'Ми перевантажені',
		},
	},
	audioReason: {
		nothear: 'Нічого не було чути',
		connection: 'Звук переривався',
		microphone: 'Не вдалося скористатися мікрофоном',
	},

	copybetting: {
		orderStatus: {
			won: 'Виграш',
			lost: 'Програш',
			new: 'Нова',
			pending: 'В очікуванні',
			cashedOut: 'Вилучення',
			boreDraw: 'Нульова нічия',
			cashback: 'Cashback',
			megaBetNew: 'MegaBet New',
			megaBetCanceledByOperator: 'MegaBet Скасування оператором',
			megaBetCanceledByPlayer: 'MegaBet Скасування гравцем',
			megaBetCanceledBySystem: 'MegaBet Скасування системою',
			betGamesPending: 'BetGames В очікуванні',
			betGamesWon: 'BetGames Виграш',
			betGamesLost: 'BetGames Програш',
			paid: 'Оплачено',
			initialWin: 'Вихідне виграш',
			autoBetCanceledBySystem: 'AutoBet Скасування системою',
			autoBetCanceledByPlayer: 'AutoBet Скасування гравцем',
			autobetPending: 'AutoBet В очікуванні',
			goalAhead: 'Буде гол',
			canceled: 'Відміна',
			return: 'Повернення',
		},
		stakeStatus: {
			pending: 'В очікуванні',
			won: 'Виграш',
			lost: 'Програш',
			return: 'Повернення',
			lostReturn: 'Програш | Повернення',
			wonReturn: 'Виграш | Повернення',
			canceled: 'Скасування',
			initialReturn: 'Вихідне повернення',
			pendingInitialReturn: 'В очікуванні | Вихідне повернення',
			wonInitialReturn: 'Виграш | Вихідне повернення',
		},
	},
	screenShare: {
		title: 'Демонстрація екрана',
		stopSharing: 'Зупинити трансляцію',
		modal: {
			title: "Ділитися екраном можна тільки з комп'ютера",
			text: "Відкрийте чат на комп'ютері та натисніть кнопку «Демонстрація екрана» у правому верхньому куті.",
			btn: 'Скопіювати посилання для входу',
		},
	},
	threads: {
		title: 'Тред',
		back: 'Назад',
		replies: ['відповідь', 'відповіді', 'відповіді'],
	},

	attach: {
		submenu: {
			sendImage: 'Надіслати зображення',
			shareBet: 'Поділитися ставкою',
			shareGamble: 'Поділитися виграшем ',
		},
		images: ['зображення', 'зображення', 'зображень'],
		addCaption: 'Додати підпис',
	},
} as unknown as Dictionary;
