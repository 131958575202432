/* eslint-disable max-lines */
/* eslint-disable jsx-a11y/media-has-caption */

import classNames from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import useAppData from 'hooks/useAppData';
import useDeviceOrientation from 'hooks/useDeviceOrientation';
import usePlayer from 'hooks/usePlayer';
import useSearchParams from 'hooks/useSearchParams';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import appService from 'store/appService';
import playerService from 'store/playerService';
import roomService from 'store/roomService';
import {getParent} from 'utils/helpers';

const playbackRateItems = [
	{id: 0, value: 1},
	{id: 1, value: 1.5},
	{id: 2, value: 2},
];

interface IVideoPlayer {
	isViewSmall: boolean;
}

const VideoPlayer: FunctionComponent<IVideoPlayer> = function VideoPlayer({isViewSmall}) {
	const wrapperRef = useRef<any>(null);
	const mediaAudioRef = useRef<any>(null);
	const mediaVideoRef = useRef<any>(null);

	const progressBarRef = useRef<HTMLInputElement>(null);
	const isAudioReadyRef = useRef<any>(false);
	const isVideoReadyRef = useRef<any>(false);
	const isAudioPlayingRef = useRef<any>(false);

	const [isFullscreen, setIsFullscreen] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);
	const [isDisabledSkipBack, setIsDisabledSkipBack] = useState(true);
	const [currentTimeTrack, setCurrentTimeTrack] = useState(0);
	const [durationTrack, setDurationTrack] = useState(0);
	const [playbackRate, setPlaybackRate] = useState(1);
	const [isDisabledSkipForward, setIsDisabledSkipForward] = useState(true);
	const [isVisiblePlayerSelectDropdown, setIsVisiblePlayerSelectDropdown] = useState(false);
	const [isVideoStub, setIsVideoStub] = useState(false);
	const [roomRecordVideoStartTimes, setRoomRecordVideoStartTimes] = useState<number[]>([]);
	const [roomRecordVideoEndTimes, setRoomRecordVideoEndTimes] = useState<number[]>([]);
	const [roomRecordStreamEndTimes, setRoomRecordStreamEndTimes] = useState<number[]>([]);
	const [isPlayReady, setIsPlayReady] = useState(false);
	const [roomRecordData, setRoomRecordData] = useState<
		{time: number; name: string; event: string}[] | null
	>(null);

	const {roomData} = useLocalObservable(() => roomService);
	const {appIcons} = useLocalObservable(() => appService);
	const {playPressed, pausePressed, setPausePressed, setPlayPressed, rewind} = useLocalObservable(
		() => playerService
	);
	const {isPortrait} = useDeviceOrientation();
	const {sendAnalytics} = useAnalytics();
	const {getAppIcon} = useAppData();
	const {audioPostMessage, formatTime} = usePlayer();
	const {platformFromUrl} = useSearchParams();
	const getPlatform = () => (platformFromUrl ? platformFromUrl.toLowerCase() : 'web');

	const {IcoPlay, IcoPause, IcoSeekBack, IcoSeekForward} = appIcons;

	const playerSelectToggleClasses = classNames('player__select-toggle', {
		'player__select-toggle--active': isVisiblePlayerSelectDropdown,
	});

	const playerWrapperClasses = classNames('player__video-wrapper', {
		'player__video-wrapper--full-screen': isFullscreen,
		'player__video-wrapper--horizontal': isFullscreen && !isPortrait,
	});

	const videoStubClasses = classNames('player__video-stub', {
		'player__video-stub--visible': isVideoStub,
	});

	const onLoadedMetadataHandler = () => {
		if (mediaVideoRef.current) {
			const {currentTime, duration} = mediaVideoRef.current;
			setCurrentTimeTrack(currentTime);
			setDurationTrack(duration);

			if (progressBarRef.current) {
				progressBarRef.current.max = duration.toFixed(0);
			}

			if (duration - currentTime > 15) {
				setIsDisabledSkipForward(false);
			}
		}
	};

	const checkReadyState = () => {
		if ((isAudioReadyRef.current || !mediaAudioRef.current) && isVideoReadyRef.current) {
			setIsPlayReady(true);
		}
	};

	const onCanPlayThroughAudio = () => {
		isAudioReadyRef.current = true;
		checkReadyState();
	};

	const onCanPlayThroughVideo = () => {
		if (mediaVideoRef.current.readyState > 3) {
			isVideoReadyRef.current = true;
			checkReadyState();
		}
	};

	const onEndedHandler = () => {
		setIsPlaying(false);
		setIsDisabledSkipBack(true);
		setIsDisabledSkipForward(true);
		setCurrentTimeTrack(0);
		setPlaybackRate(1);

		if (mediaAudioRef.current) {
			mediaAudioRef.current.currentTime = 0;
			mediaAudioRef.current.remove();
		}
	};

	const onPlayAudioHandler = () => {
		if (document.visibilityState === 'hidden') {
			isAudioPlayingRef.current = true;
		}
	};

	const onPauseAudioHandler = () => {
		if (document.visibilityState === 'hidden') {
			mediaVideoRef.current.pause();
			isAudioPlayingRef.current = false;
		}
	};

	const onPlayHandler = () => {
		if (mediaAudioRef.current) mediaAudioRef.current.play();
		setIsPlaying(true);
	};

	const onPauseHandler = () => {
		setIsPlaying(false);
	};

	const playHandler = () => {
		if (mediaVideoRef.current && !isPlaying) {
			mediaVideoRef.current.play();
			setIsPlaying(true);
			sendAnalytics('records_play', {
				time_stamp: mediaVideoRef.current.currentTime.toFixed(0),
				platform: getPlatform(),
			});
		}
	};

	const pauseHandler = () => {
		if (mediaVideoRef.current) {
			mediaVideoRef.current.pause();
			if (mediaAudioRef.current) mediaAudioRef.current.pause();

			setIsPlaying(false);
			sendAnalytics('records_pause', {time_stamp: mediaVideoRef.current.currentTime.toFixed(0)});
		}
	};

	useEffect(() => {
		if (playPressed) {
			playHandler();
			setPlayPressed(false);
		}
	}, [playPressed]);

	useEffect(() => {
		if (pausePressed) {
			pauseHandler();
			setPausePressed(false);
		}
	}, [pausePressed]);

	const syncPlayback = () => {
		if (Math.abs(mediaAudioRef.current.currentTime - mediaVideoRef.current.currentTime) > 0.1) {
			mediaAudioRef.current.currentTime = mediaVideoRef.current.currentTime;
		}
	};

	const handleVideoStub = (currentTime: number) => {
		setIsVideoStub(true);
		const time = Math.floor(currentTime * 1000);

		const maxTimeEnded = roomRecordVideoEndTimes.length
			? Math.max(...roomRecordVideoEndTimes)
			: Math.max(...roomRecordStreamEndTimes);

		if (
			time < Math.min(...roomRecordVideoStartTimes) ||
			(roomRecordVideoEndTimes.length && time > Math.max(...roomRecordVideoEndTimes))
		) {
			setIsVideoStub(true);
			return;
		}

		if (time > Math.min(...roomRecordVideoStartTimes) + 1500 && time < maxTimeEnded) {
			setIsVideoStub(false);
		}
	};

	const onTimeUpdateHandler = () => {
		if (mediaAudioRef.current) syncPlayback();
		if (progressBarRef.current && mediaVideoRef.current) {
			const {currentTime, duration} = mediaVideoRef.current;

			if (roomData?.recordInfo) handleVideoStub(currentTime);

			const rangeValue = ((currentTime / duration) * 100).toFixed();
			setCurrentTimeTrack(currentTime);
			progressBarRef.current.value = `${currentTime}`;
			progressBarRef.current.style.setProperty('--range-progress', `${rangeValue}%`);

			if (duration > 15) {
				if (currentTime >= 15) {
					setIsDisabledSkipBack(false);
				} else {
					setIsDisabledSkipBack(true);
				}

				if (duration - currentTime > 15) {
					setIsDisabledSkipForward(false);
				} else {
					setIsDisabledSkipForward(true);
				}
			}
		}
	};

	const onControlsPlayHandler = () => {
		if (isPlaying) {
			mediaVideoRef.current.pause();
			if (mediaAudioRef.current) mediaAudioRef.current.pause();
			audioPostMessage('PAUSE');
			setIsPlaying(false);
			sendAnalytics('records_pause', {time_stamp: mediaVideoRef.current.currentTime.toFixed(0)});
			return;
		}

		mediaVideoRef.current.play();

		// setIsPlaying(true);
		audioPostMessage('PLAY');
		sendAnalytics('records_play', {
			time_stamp: mediaVideoRef.current.currentTime.toFixed(0),
			platform: getPlatform(),
		});
	};

	const onProgressBarHandler = () => {
		if (mediaVideoRef.current && progressBarRef.current) {
			mediaVideoRef.current.currentTime = parseInt(progressBarRef.current.value, 10);
		}
		if (mediaAudioRef.current && progressBarRef.current) {
			mediaAudioRef.current.currentTime = parseInt(progressBarRef.current.value, 10);
		}
	};

	const onControlsSkipHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
		const target = e.target as HTMLButtonElement;
		const skipDirection = target.getAttribute('data-skip');

		if (mediaVideoRef.current) {
			if (skipDirection === 'forward') {
				if (
					!isDisabledSkipForward &&
					mediaVideoRef.current.currentTime + 15 < mediaVideoRef.current.duration
				) {
					mediaVideoRef.current.currentTime += 15;
					sendAnalytics('records_forward', {Duration: 15});
				}

				return;
			}

			if (!isDisabledSkipBack && mediaVideoRef.current.currentTime > 16) {
				mediaVideoRef.current.currentTime -= 15;
				if (mediaVideoRef.current) mediaVideoRef.current.currentTime -= 15;
				sendAnalytics('records_back', {Duration: 15});
			}
		}
	};

	const onPlayerSelectButtonHandler = (value: number) => {
		if (value !== playbackRate && mediaVideoRef.current) {
			setPlaybackRate(value);
			setIsVisiblePlayerSelectDropdown(false);
			mediaVideoRef.current.playbackRate = value;
			sendAnalytics('records_speed_changed', {Speed: value});
		}
	};

	const renderPlayerSelectItem = (item: {id: number; value: number}) => {
		return (
			<div className='player__select-item' key={item.id}>
				<button
					type='button'
					className='player__select-button'
					onClick={() => onPlayerSelectButtonHandler(item.value)}>
					{item.value}x
				</button>
			</div>
		);
	};

	const fullScreenHandler = () => {
		setIsFullscreen(!isFullscreen);
	};

	const visibilitychangeHandler = () => {
		if (document.visibilityState === 'visible' && mediaAudioRef.current) {
			mediaAudioRef.current.pause();
			mediaVideoRef.current.currentTime = mediaAudioRef.current.currentTime;
			if (isAudioPlayingRef.current) {
				mediaVideoRef.current.play();
			}
		}
	};

	const onClickPlayerHandler = (e: any) => {
		const eventTarget = e.target;
		if (eventTarget && !getParent(eventTarget, 'player__select') && isVisiblePlayerSelectDropdown) {
			setIsVisiblePlayerSelectDropdown(false);
		}
	};

	useEffect(() => {
		if (rewind) {
			if (mediaVideoRef.current) {
				if (rewind.direction === 'FORWARD') {
					if (
						!isDisabledSkipForward &&
						mediaVideoRef.current.currentTime + rewind.seconds < mediaVideoRef.current.duration
					) {
						mediaVideoRef.current.currentTime += rewind.seconds;
						sendAnalytics('records_forward', {Duration: rewind.seconds});
					}

					return;
				}

				if (!isDisabledSkipBack) {
					if (mediaVideoRef.current.currentTime > rewind.seconds + 1) {
						mediaVideoRef.current.currentTime -= rewind.seconds;
					} else {
						mediaVideoRef.current.currentTime = 0;
					}
					sendAnalytics('records_back', {Duration: rewind.seconds});
				}
			}
		}
	}, [rewind]);

	useEffect(() => {
		document.addEventListener('click', onClickPlayerHandler);
		return () => {
			document.removeEventListener('click', onClickPlayerHandler);
		};
	}, [isVisiblePlayerSelectDropdown]);

	useEffect(() => {
		if (!isPlayReady) {
			mediaVideoRef.current.load();
			if (mediaAudioRef.current) mediaAudioRef.current.load();
		}
		window.addEventListener('visibilitychange', visibilitychangeHandler);
	}, [isPlayReady]);

	useEffect(() => {
		if ('mediaSession' in navigator) {
			navigator.mediaSession.metadata = new MediaMetadata({
				title: roomData?.name || '',
				artist: roomData?.about || '',
				artwork: [{src: roomData?.pic800 || '', sizes: '800x800', type: 'image/jpeg'}],
			});
		}
		if (!roomRecordData && roomData?.recordInfo) {
			const recordData = roomData?.recordInfo ? JSON.parse(roomData?.recordInfo) : null;
			setRoomRecordData(recordData);
			const startTime = recordData?.find(
				(el: any) => el.name !== 'stream' && el.event === 'start'
			)?.time;
			setRoomRecordVideoStartTimes(
				recordData
					?.filter((el: any) => el.event === 'start' && el.name === 'video')
					.map((elem: any) => elem.time)
					.map((elem: any) => {
						return elem - startTime;
					})
			);
			setRoomRecordVideoEndTimes(
				recordData
					?.filter((el: any) => el.event === 'end' && el.name === 'video')
					.map((elem: any) => elem.time)
					.map((elem: any) => {
						return elem - startTime;
					})
			);
			setRoomRecordStreamEndTimes(
				recordData
					?.filter((el: any) => el.event === 'end' && el.name === 'stream')
					.map((elem: any) => elem.time)
					.map((elem: any) => {
						return elem - startTime;
					})
			);
		}
	}, [roomData]);

	return (
		<div className='player'>
			<div className='player__head'>
				<div className={playerWrapperClasses} ref={wrapperRef}>
					<div className={videoStubClasses}>
						{roomData?.pic ? (
							<img
								className='player__video-stub-img'
								src={roomData?.pic}
								alt={roomData?.name || ''}
							/>
						) : (
							''
						)}
					</div>

					{roomData?.audioRecord && (
						<audio
							controls
							preload='metadata'
							src={roomData?.audioRecord}
							className='player__audio'
							onCanPlayThrough={onCanPlayThroughAudio}
							onPlay={onPlayAudioHandler}
							onPause={onPauseAudioHandler}
							ref={mediaAudioRef}
							title={roomData?.name || ''}
							muted={isPlaying}
						/>
					)}

					<video
						playsInline
						preload='auto'
						src={roomData?.videoRecord || roomData?.record}
						onCanPlayThrough={onCanPlayThroughVideo}
						onLoadedMetadata={onLoadedMetadataHandler}
						onPlay={onPlayHandler}
						onPause={onPauseHandler}
						onEnded={onEndedHandler}
						onTimeUpdate={onTimeUpdateHandler}
						className='player__video'
						ref={mediaVideoRef}
						title={roomData?.name || ''}
						// muted
					/>
					<button type='button' className='player__fullscreen-btn' onClick={fullScreenHandler}>
						{' '}
					</button>
				</div>
				{isViewSmall && (
					<div className='player__info'>
						<button
							type='button'
							className='player__controls-play'
							aria-label='play'
							disabled={!isPlayReady}
							onClick={onControlsPlayHandler}>
							{isPlaying ? getAppIcon(IcoPause.pic) : getAppIcon(IcoPlay.pic)}
						</button>
						{roomData?.name && <div className='player__info-title'>{roomData?.name}</div>}
						<div className='player__info-time'>
							{formatTime(currentTimeTrack)} из {formatTime(durationTrack)}
						</div>
					</div>
				)}
				<div className='player__progressbar'>
					<input
						type='range'
						defaultValue='0'
						className='player__progressbar-range'
						onChange={onProgressBarHandler}
						ref={progressBarRef}
					/>
					<div className='player__progressbar-times'>
						<div className='player__progressbar-time'>{formatTime(currentTimeTrack)}</div>
						<div className='player__progressbar-time'>{formatTime(durationTrack)}</div>
					</div>
				</div>
			</div>
			{!isViewSmall && (
				<div className='player__body'>
					<div className='player__controls'>
						<div className='player__select'>
							<button
								type='button'
								className={playerSelectToggleClasses}
								onClick={() => setIsVisiblePlayerSelectDropdown(!isVisiblePlayerSelectDropdown)}>
								{playbackRate}x
							</button>
							{isVisiblePlayerSelectDropdown && (
								<div className='player__select-dropdown'>
									<div className='player__select-items'>
										{playbackRateItems.map(renderPlayerSelectItem)}
									</div>
								</div>
							)}
						</div>
						<button
							type='button'
							className='player__controls-skip'
							data-skip='back'
							disabled={isDisabledSkipBack || !isPlayReady}
							onClick={onControlsSkipHandler}>
							{getAppIcon(IcoSeekBack.pic)}
						</button>
						<button
							type='button'
							className='player__controls-play'
							aria-label='play'
							disabled={!isPlayReady}
							onClick={onControlsPlayHandler}>
							{isPlaying ? getAppIcon(IcoPause.pic) : getAppIcon(IcoPlay.pic)}
						</button>
						<button
							type='button'
							className='player__controls-skip'
							data-skip='forward'
							disabled={isDisabledSkipForward || !isPlayReady}
							onClick={onControlsSkipHandler}>
							{getAppIcon(IcoSeekForward.pic)}
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default observer(VideoPlayer);
