/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: 'Համաձայնագիր',
		subTitle: 'Լիցենզային համաձայնագիր և գաղտնիության քաղաքականություն',
		textFirst: 'Ես ընդունում եմ պայմանները',
		textSecond:
			'և տալիս եմ համաձայնություն անձնական տվյալների մշակման համար այն պայմաններով, որոնք որոշված են',
		license: 'Լիցենզային պայմանագրով',
		policy: 'Գաղտնիության քաղաքականությամբ',
	},
	controlPanel: {
		streamEnded: 'Հեռարձակումն ավարտված է',
		banAlert: 'Դուք չեք կարող գրել զրուցարանում',
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? 'ՍԹԻՔԵՐՆԵՐ' : 'ԷՄՈՋԻՆԵՐ',
	},
	accountDeletedScreen: {
		title: 'Անձնական էջը ջնջված է',
		text: (date: string) => `Դուք կարող եք վերականգնել Ձեր անձնական էջը մինչև ${date}`,
	},
	nameScreen: {
		title: 'Գլխավոր նկար',
		inputPlaceholder: 'Մուտքագրեք անունը',
		nameError: 'Անթույլատրելի անուն',
	},
	avatarScreen: {
		title: 'Գլխավոր նկար',
		label: 'Ընտրել այլ:',
	},
	settingsScreen: {
		title: 'Կարգավորումներ',
		personal: 'Անձնական կարգավորումներ',
		room: 'Սենյակի կարգավորումներ',
		profileBtn: 'Անձնական էջ',
		languageBtn: 'Լեզու',
		blockedBtn: 'Արգելափակվածներ',
		rules: 'Զրուցարանի կանոններ',
		deleteProfile: 'Ջնջել անձնական էջը',
		slowModeBtn: 'Դանդաղ ռեժիմ',
		agora: {
			microphone: 'Միկրոֆոն',
			playbackDevice: 'Ձայնի աղբյուր',
		},
	},
	languageScreen: {
		title: 'Լեզվուն և ինտերֆեյս',
		messagesTranslate: 'Շաբաթական Նամակների Թարգմանություն',
		translateMessagesMode: 'Թարգմանել շաբաթական նամակները',
		translateMessagesTo: 'Թարգմանել նամակները:',
	},
	rulesScreen: {
		title: 'Զրուցարանի կանոններ',
	},
	profileScreen: {
		title: 'Անձնական էջ',
		selectAnotherPic: 'Ընտրել այլ գլխավոր նկար',
		chatNameLabel: 'Անունը զրուցարանում',
		changeName: 'փոփոխել',
	},
	blockedScreen: {
		title: 'Արգելափակում',
		emptyListText: 'Չկան արգելափակված օգտատերեր',
	},
	chatScreen: {
		inputPlaceholder: 'Հաղորդագրություն',
		cantWrite: 'Դուք չեք կարող գրել զրուցարանում',
		chatSubmenu: {
			hideMessage: 'Թաքցնել հաղորդագրությունները',
			showMessage: 'Ցույց տալ հաղորդագրությունները',
			removeFromSpeakers: 'Հեռացնել  խոսնակներից',
			setSpeaker: 'Դարձնել խոսնակ',
			hideOnlyMessage: 'Թաքցնել հաղորդագրությունը',
			showOnlyMessage: 'Ցույց տալ հաղորդագրությունը',
			reportMessage: 'Բողոքել հաղորդագրությունից',
			reportUser: 'Բողոքել օգտատիրոջից',
			muteSpeaker: 'Անջատել խոսափողը',
			blockUser: 'Արգելափակե՞լ',
			unblockUser: 'Ապաարգելափակել',
			reply: 'Պատասխանել',
			copy: 'Պատճենել',
			edit: 'Փոփոխել',
			delete: 'Ջնջել',
			pinMessage: 'Ամրացնել',
			unpinMessage: 'Ապաամրացնել',
			replyInThread: 'Պատասխանել թեմայում',
		},
		actionMessage: {
			editing: 'Խմբագրում',
			reply: 'Պատասխանել',
		},
		status: {
			top: 'Թոփ',
		},
		translating: 'թարգմանում..․',
	},
	chatUsersScreen: {
		title: 'Մասնակիցներ',
		edited: 'Փոփոխված է',
		hidden: 'թաքցված',
		unreadMessages: 'Չկարդացված հաղորդագրություններ',
		usersSubmenu: {
			muteSpeaker: {
				title: 'Լռեցնել մասնակցին',
				subtitle: 'Անջատել խոսափողը',
			},
			setSpeaker: {
				title: 'Հեռացնել  խոսնակներից',
				subtitle: 'Միայն տեքստային զրուցարան',
			},
			removeFromSpeaker: {
				title: 'Դարձնել խոսնակ',
				subtitle: 'Օգտատերը կկարողանա խոսել ձայնով',
			},
			showMessages: {
				title: 'Ցույց տալ հաղորդագրությունները',
				subtitle: 'Տեսանելի է միայն ադմինին',
			},
			hideMessages: {
				title: 'Թաքցնել հաղորդագրությունը',
				subtitle: 'Տեսանելի է միայն ադմինին',
			},
			blockUser: {
				title: 'Արգելափակել',
				subtitle: 'Գցել սև ցուցակ',
			},
			unblockUser: {
				title: 'Ապաարգելափակել',
				subtitle: 'Ապաարգելափակե՛ք օգտատիրոջը',
			},
			blockUserByUser: {
				title: 'Արգելափակել',
				subtitle: 'Թաքցնել օգտատիրոջ բոլոր հաղորդագրությունները',
			},
			unblockUserByUser: {
				title: 'Ապաարգելափակել',
				subtitle: 'Ցույց տալ օգտատիրոջ բոլոր հաղորդագրությունները',
			},
			reportUser: {
				title: 'Բողոքել օգտատիրոջից',
				subtitle: 'Բողոքը կուղարկվի անանուն',
			},
		},
		days: {
			today: 'Այսօր',
			yesterday: 'Երեկ',
		},
		chatCreated: 'Զրույցը ստեղծվեց,',
	},
	alerts: {
		btns: {
			unblock: 'Ապաարգելափակել',
			block: 'Արգելափակել',
			send: 'Ուղարկել',
			sendAndBlock: 'Ուղարկել և արգելափակել',
			show: 'Ցուցադրել',
			showAll: 'Ցուցադրել ամբողջը',
			cancel: 'Չեղարկում',
			yes: 'Այո',
			hide: 'Թաքցնել ընտրածը',
			hideAll: 'Թաքցնել ամբողջը',
			make: 'Անել',
			remove: 'Հեռացնել',
			delete: 'Ջնջել',
			deleteAccount: 'Ջնջել անձնական էջը',
			close: 'Փակել',
			pin: 'Ամրացնել',
			unpin: 'Ապաամրացնել',
			edit: 'Խմբագրել',
			end: 'Ավարտել',
			endAndShare: 'Ավարտել և կիսվել',
			reset: 'Հեռացնել',
			rules: 'Զրուցարանի կանոնները',
			contactus: 'Գրել Մեզ',
		},
		pinMessage: {
			title: 'Ամրացնե՞լ հաղորդագրությունը',
		},
		unPinMessage: {
			title: 'Ապաամրացնե՞լ հաղորդագրությունը',
		},
		accountRestored: {
			title: 'Անձնական էջը վերականգնված է',
		},
		closeApp: {
			title: 'Դուք իրո՞ք ցանկանում եք դուրս գալ:',
		},
		removeMessage: {
			title: 'Ջնջե՞լ հաղորդագրությունը',
		},
		report: {
			title: 'Ուղարկե՞լ բողոք: ',
			subtitle:
				'Դուք նաև կարող եք արգելափակել օգտատիրոջը՝ միմյանց հաղորդագրությունները չտեսնելու համար:',
		},
		banUser: {
			title: (name: string) => `Արգելափակե՞լ  ${name}`,
			subtitle: (text: string) => `Օգտատերը կարգելափակվի  ${text}`,
		},
		unBanUser: {
			title: (name: string) => `Ապաարգելափակե՞լ ${name}`,
		},
		showUserMessages: {
			title: (name: string) => `Ցու՞յց տալ հաղորդագրությունները ${name}`,
			subTitle: 'Մյուս օգտատերերը կկարողանան տեսնել այս օգտատիրոջ հաղորդագրությունները:',
		},
		hideUserMessages: {
			title: (name: string) => `Թաքցնե՞լ հաղորդագրությունները ${name}`,
			subTitle: 'Մյուս օգտատերերը այլևս չեն կարողանա տեսնել այս օգտատիրոջ հաղորդագրությունները:',
		},
		showUserMessage: {
			title: (name: string) => `Ցու՞յց տալ հաղորդագրությունը ${name}`,
			subTitle: 'Մյուս օգտատերերը կկարողանան տեսնել այս հաղորդագրությունը:',
		},
		hideUserMessage: {
			title: (name: string) => `Թաքցնե՞լ հաղորդագրությունը ${name}`,
			subTitle: 'Մյուս օգտատերերը այլևս չեն կարողանա տեսնել այս հաղորդագրությունը:',
		},
		blockUserInRoom: {
			title: (name: string) => `Արգելափակե՞լ ${name}`,
			subTitle: 'Օգտատերը չի կարող մասնակցել այս սենյակում:',
		},
		unBlockUserInRoom: {
			title: (name: string) => `Ապաարգելափակե՞լ ${name}`,
			subTitle: 'Օգտատերը կարող է մասնակցել այս սենյակում:',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `ելափակե՞լ ${name}?`,
			subTitle: 'Դուք չեք տեսնելու միմյանց հաղորդագրությունները:',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `Դարձնե՞լ ${name} խոսնակ:`,
			unTitle: (name: string) => `Հեռացնե՞լ ${name} խոսնակներից:`,
		},
		banInApp: {
			title: 'Ադմինիստրատորը արգելափակել է Ձեզ:',
			for: '',
			subtitle: (bannedUntil: string) => `Դուք արգելափակված եք մինչև ${bannedUntil}`,
			permanent: 'Դուք արգելափակված եք ընդմիշտ',
		},
		banInAppBadNickname: {
			title: 'Ադմինիստրատորը արգելափակել է Ձեզ անթույլատրելի անվան համար:',
			subtitle: 'Մու՛տք գործեք Ձեր  անձնական էջ, փոխեք անունը և կրկին մտեք զրուցարան:',
		},
		deleteAccount: {
			title: 'Անձնական էջի ջնջում:',
			subTitle:
				'Բոլոր տվյալները ջնջվելու են: Դուք կկարողանաք վերականգնել անձնական էջը 30 օրվա ընթացքում:',
		},
		pollEdit: {
			title: 'Խմբագրե՞լ հարցումը:',
			subTitle: 'Օգտատերերի պատասխանները կհեռացվեն:',
		},
		pollReset: {
			title: 'Հեռացնե՞լ:',
			subTitle: 'Հարցումը չի պահպանվի:',
		},
		pollEnd: {
			title: 'Իսկապե՞ս ցանկանում եք ավարտել հարցումը:',
		},
		pollDelete: {
			title: 'Ջնջե՞լ հարցումը:',
		},
		agoraCheck: {
			updateIOSText: 'Թարմացրեք iOS-ի տարբերակը բոլոր ծրագրի գործիքները գումարելու համար։',
			allowAccess: {
				title: 'Ունե՞ք ինչ-որ բան ասելու։',
				text: 'Խնդրում ենք մուտք գործել Ծրագրի կարգավորումներ և Հասանելիություն տալ միկրոֆոնին և բարձրախոսին։',
			},
			microphoneAndSpeakerText: 'Միկրոֆոնի և Բարձրախոսի Օգտագործումը հասանելի է 14.3',
			microphoneText:
				'թարմացումից սկսած։ Կատարեք iOS-ի թարմացումը բոլոր ծրագրի գործիքները Օգտագործելու համար։',
			btn: 'լավ!',
		},
	},
	eventScreen: {
		title: 'Պլանավորված իրադարձություն',
		titleSoon: 'Հեռարձակումը շուտով կսկսվի',
		subtitle: 'Զրոյացումը շուտ կլինի։ Խնդրում եմ սպասեք։',
		days: 'օրից',
		hours: 'ժամից',
		minutes: 'րոպե',
		seconds: 'վայրկյան',
		startTitle: 'Սկիզբ',
	},
	toasts: {
		reciveComplaint: 'Մենք ստացել ենք Ձեր բողոքը',
		setSpeakerRoleForUser: 'Օգտատերը նշանակված է խոսնակ',
		unSpeakerRoleForUser: 'Օգտատերը հեռացված է խոսնակներից',
		messagesShown: 'Հաղորդագրությունները ցուցադրված են',
		messagesHidden: 'Հաղորդագրությունները թաքցված են',
		messageShown: 'Հաղորդագրությունը ցուցադրված է',
		messageHidden: 'Հաղորդագրությունը թաքցված է',
		userBlocked: 'Օգտատերը արգելափակված է',
		userUnBlocked: 'Օգտատերը ապաարգելափակված է',
		youAreSpeaker: 'Դուք խոսնա՜կ եք: Ասե՛ք ինչ-որ բան',
		youAreNotSpeaker: 'Ադմինիստրատորը հեռացրել է Ձեզ խոսնակներից:',
		userIsSpeaker: (name: string) => `${name} այժմ խոսնակ է`,
		userNotSpeaker: (name: string) => `${name} այլևս խոսնակ չէ`,
		yourUserNameInChat: (name: string) => `Ձեր անունը զրուցարանում՝ ${name}`,
		messageRemoved: 'Հաղորդագրությունը ջնջված է',
		messageCopied: 'Տեքստը պատճենված է',
		obscenities: 'Փորձե՛ք վերաձևակերպել',
		requestSent: 'Հարցումն ուղարկված է',
		requestDecline: 'Հարցումը հետ է կանչվել ադմինիստրատորի կողմից',
		youareblocked: (text: string) => `Ձեզ արգելափակել են: Դուք չեք կարող գրել զրուցարանում:`,
		youareunblocked: 'Ձեզ ապաարգելափակել են: Դուք կարող եք գրել հաղորդագրություններ զրուցարանում',
		youAreModerator: 'Դուք մոդերատոր եք',
		youAreNoModerator: 'Դուք այլևս մոդերատոր չեք',
		micMuted: 'Ձեր բարձրախոսը խլացված է',
		noInet: 'Բացակայում է ինտերնետ-միացումը:',
		messagePinned: 'Հաղորդագրությունը ամրացված է',
		messageUnpinned: 'Հաղորդագրությունը ապաամրացված է',
		pollPublished: 'Հարցումը հրապարակված է:',
		pollPublishedError: 'Սխալ: Փորձե՛ք քիչ անց',
		pollAddPic: 'Ավելացրե՛ք նկար',
		pollAddPicError: 'Ընտրե՛ք այլ նկար',
		pollDeleted: 'Հարցումը ջնջված է',
		pollEnded: 'Հարցումն ավարտված է',
		sendStickerWhenSlowModeIsOn: (time: string) => `Սպասե՛ք ${time} սթիքեր ուղարկելու համար`,
		linkProhibited: 'Դուք չեք կարող տեղադրել հղումներ չատում։',
		phoneProhibited: 'Դուք չեք կարող ուղարկել հեռախոսահամարը շատախոսային։',
		profileHidden: 'Օգտագործողը թաքցրել է իր պրոֆիլը',
		streamConnecting: 'Սթրիմի միացում...',
		imageSizeWarning: 'Կարող եք բեռնել մինչև 10 ՄԲ նկար',
		imageBlocked: `Մոդերատորը արգելափակել է նկարի ուղարկումը`,
		streamKeyCopied: 'Հեռարձակման բանալին պատճենվեց',
	},
	btns: {
		acceptandgo: 'Ընդունել և շարունակել',
		saveandgo: 'Պահպանել և շարունակել',
		save: 'Պահպանել',
		unblock: 'Ապաարգելափակել',
		block: 'Արգելափակել',
		restoreAccount: 'Վերականգնել անձնական էջը',
		close: 'Փակել',
	},
	coopyrights: 'Աշխատում է Watchers.io բազայի հիման վրա',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message' ? 'Բողոքել հաղորդագրությունից' : 'Հաղորդել խախտման մասին',
			subtitle: 'Բողոքներն ուղարկվում են անանուն',
			btns: {
				violence: 'Վիրավորանքներ, սպառնալիքներ, հայհոյանք',
				provocations: 'Սադրանքներ',
				personalDetails: 'Անձնական տվյալներ',
				flood: 'Հաղորդագրությունների տարափ',
				spam: 'Սփամ',
				fraudBegging: 'Խարդախություն, մուրացկանություն',
				other: 'Այլ',
			},
		},
		ban: {
			title: 'Արգելափակել օգտատիրոջը',
			subtitle: 'Մատնանշե՛ք արգելափակման պատճառը',
			btns: {
				insults: 'Վիրավորանքներ, սպառնալիքներ, հայհոյանք',
				personal: 'Անձնական տվյալների տարածում',
				flood: 'Հաղորդագրությունների տարափ',
				links: 'Հղումներ դեպի կողմնակի ծառայություններ',
				violence: 'Բռնության սպառնալիքներ',
				fraud: 'Խարդախության և մուրացկանության փորձեր',
				nickname: 'Անթույլատրելի կեղծանուն',
				politicial: 'Քաղաքական, սեքսուալիզացված և այլ սադրանքներ',
				repeated: 'Կրկնակի խախտում',
				auto: 'Զրուցարանի օգտատերերի բողոքները',
				spam: 'Սփամ',
			},
		},
		waitingSpeakers: {
			title: 'Խոսելու համար սպասողներ չկան',
			text: 'Դուք չունեք սպասող խոսնակներ',
			handsUp: 'Ձեռք են բարձրացրել',
			waiting: 'Սպասող խոսնակներ',
		},
		poll: {
			supTitles: {
				typeQuiz: 'Տվե՛ք ճիշտ պատասխան',
				typeQuizMultiple: 'Նշե՛ք ճիշտ տարբերակները',
				typePoll: 'Կիսվել կարծիքով',
				typePollMultiple: 'Կարելի է նշել մի քանի տարբերակ',
				default: 'Հանրային հարցում',
			},
			btns: {
				reply: 'Պատասխանել',
				vote: 'Քվեարկել',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: 'Վիկտորինա',
				typePoll: 'Մի քանի պատասխան',
				default: 'Հանրային հարցում',
			},
			btns: {
				edit: 'Խմբագրել հարցումը',
				end: 'Ավարտել',
			},
		},
		pollCreated: {
			titles: {
				created: 'Ստեղծել հարցում',
				typeQuiz: 'Վիկտորինա',
				typePoll: 'Մի քանի պատասխան',
				default: 'Հանրային հարցում',
			},
		},
		changeName: {
			title: 'Փոխե՛ք Ձեր անունը զրուցարանում',
			subtitle: 'Դուք արգելափակված եք անթույլատրելի կեղծանվան պատճառով',
		},
		temporaryAgreement: {
			title: 'Չատի կանոնները',
			rules: {
				bePolite: {
					title: 'Եղեք բարեհամբույր',
					text: 'Չատում արգելվում են թշնամանքի հանգեցնող և անպարկեշտ արտահայտություններ',
				},
				beAttentive: {
					title: 'Եղեք ուշադիր',
					text: 'Մի՛ կիսվեք անձնական տվյալներով և մի՛ խնդրեք դրանք ուրիշներից',
				},
				notAdvertise: {
					title: 'Գովազդ մի՛ արեք',
					text: 'Ավելի լավ է կիսվեք ձեր կարծիքով կամ զգացմունքներով',
				},
			},
			btns: {
				allRules: 'Բոլոր կանոնները',
				acceptAndContinue: 'Ընդունել և շարունակել',
			},
		},
		slowMode: {
			title: 'Դանդաղ ռեժիմ',
			text: 'Մասնակիցները չեն կարողանա զրուցարան հաղորդագրություններ ուղարկել ավելի հաճախ, քան ընտրված ժամանակահատվածն է',
			enable: 'Միացնել դանդաղ ռեժիմը',
			disable: 'Անջատել դանդաղ ռեժիմը',
			sendTimeout: 'Ուղարկման ընդմիջում',
		},
		slowModeTimeout: {
			title: 'Ուղարկման ընդմիջում',
		},
		chatBot: {
			title: 'Չատբոտի օգտատործում',
			rules: [
				{
					title: 'Արհեստական բանականություն',
					text: 'Բոտը նմանակում է մարդկանց հետ շփումը և տեղեկատվություն տրամադրում իր գիտելիքների շնորհիվ: Այնուամենայնիվ հիշեք, որ նրա պատասխանները կարող են սահմանափակ լինել և չեն փոխարինի մարդուն:',
				},
				{
					title: 'Բոտը կարող է սխալվել',
					text: 'Բոտը չի կարող երաշխավորել տեղեկատվության ճշգրտությունն ու հավաստիությունը',
				},
			],
			btns: {
				close: 'Փակել',
			},
		},
		shareBet: {
			title: 'Կիսվել խաղադրույքով',
			noBetsTitle: 'Չկա գրազ, որով կարող եք կիսվել',
			noBetsText:
				'Կատարեք խաղադրույք և գտեք այն այս ցուցակում՝ այլ խաղացողների հետ այն կիսելու համար',
			btns: {
				share: 'Կիսվել խաղադրույքով',
				copy: 'Կրկնել բեթը',
			},
			status: {
				null: 'Սպասում է խաղարկությանը',
				win: 'Շահում',
				lose: 'Պարտություն',
			},
			betTypeV1: {
				single: 'Օրդինար',
				express: 'Էքսպրես',
			},
			betTypeV2: {
				single: 'Օրդինար',
				multi: 'Էքսպրես',
				system: 'համայնք',
			},
			totalFactor: 'Գործակից',
			bet: 'Գրազ',
			showMore: 'Ցույց տալ ավելին',
		},
		record: {
			text: 'Եթերի ձայնագրությունը մշակվում է...',
		},
		translate: {
			title: 'Զննարկում թարգմանություններ',
			chooseLanguage: 'Ընտրեք Լեզուն',
			descr: 'Հիմնականում ընտրված լեզվով շարունակական թարգմանվող շաբաթական նամակներ',
			btn: 'Պահպանել և շարունակել',
		},
		streamSettings: {
			title: 'Հոսքի կարգավորումներ',
			description:
				'Ներբեռնեք և տեղադրեք վիդեոկոդեր։ Մուտքագրեք հոսքի բանալին դրա կարգավորումներում',
			shareScreen: {
				title: 'Էկրանի ցուցադրում',
				description: 'Հատուկ կարգավորումներ չեն պահանջվում',
			},
			rtmp: {
				title: 'Արտաքին հոսքի աղբյուր',
				description: 'Օգտագործեք հավելյալ ծրագիր հոսք իրականացնելու համար',
			},
			streamKey: 'Հոսքի բանալի',
			showStreamKey: 'Ցուցադրել',
			hideStreamKey: 'Թաքցնել',
			btns: {
				continue: 'Շարունակել',
				close: 'Փակել',
				ok: 'Պատրաստ է',
			},
		},
	},
	pinnedMessage: {
		title: 'Ամրացված հաղորդագրություն',
	},
	errorPage: {
		error: 'Սխալ',
		types: [
			// errorNumber 0
			{
				title: 'Մենք Ձեզ չենք ճանաչում',
				text: 'Փորձե՛ք վերամտնել զրուցարան',
			},
			// errorNumber 1
			{
				title: 'Չենք կարողանում գտնել զրուցարանը',
				text: 'Փորձե՛ք վերամտնել',
			},
			// errorNumber 2
			{
				title: 'Անհնար է ներբեռնել զրուցարանը',
				text: 'Փորձե՛ք վերաբեռնել',
			},
			// errorNumber 3
			{
				title: 'Մենք Ձեզ չենք ճանաչում',
				text: 'Գաղտնի ռեժիմով զրուցարանը հասանելի չէ',
			},
			// errorNumber 4
			{
				title: 'Անթույլատրելի կեղծանուն',
				text: 'Մու՛տք գործեք Ձեր անձնական էջ, փոխե՛ք կեղծանունը և կրկին մտե՛ք զրուցարան:',
			},
		],
	},
	tooltips: {
		emotions: 'Բռնած պահեք փոխելու համար',
		badInet: 'Վատ ցանցային կապ',
		blockedUsers: 'Նայել արգելափակված <br>օգտատերերի ցուցակը կարելի է այստեղ',
		waitingSpeakers: 'Մասնակիցներից ինչ-որ մեկն ուզում է արտահայտվել',
		wantSpeak: 'Սեղմե՛ք ձայնի իրավունք խնդրելու համար',
		slowMode: {
			header: (time: string) =>
				`Դանդաղ ռեժիմը միացված է: Օգտատերերը կարող են ուղարկել մեկ հաղորդագրություն ամեն ${time}: Այս կանոնը չի տարածվում մոդերատորների վրա:`,
			btnSendMessage: (time: string) => `Դուք կարող եք հաղորդագրություն ուղարկել ամեն ${time}.`,
		},
		shareBet: 'Կիսվել խաղադրույքով չատում',
		translate: 'Թարգմանեք անհրաժեշտ լեզվով',
	},
	poll: {
		toggleSubmenu: {
			create: 'Ստեղծել հարցում',
			edit: 'Խմբագրել հարցումը',
			show: 'Ցուցադրել հարցումը',
			end: 'Ավարտել հարցումը',
		},
		tooltip: {
			btn: 'Քվեարկել',
		},
		settings: {
			title: 'Կարգավորումներ',
			titleAdditionally: 'Լրացուցիչ',
			switches: {
				quizMode: 'Վիկտորինայի ռեժիմ',
				multipleChoice: 'Մի քանի պատասխանի ընտրություն',
				withPhoto: 'Լուսանկարով հարցում',
			},
		},
		question: {
			title: 'Հարց',
			inputPlaceholder: 'Մուտքագրեք հարցը ',
		},
		message: {
			title: 'Դուք քվեարկել եք',
		},
		answers: {
			title: 'Պատասխանի տարբերակներ',
			warning: 'Դուք կարող եք ավելացնել մինչև 5 պատասխան',
		},
		answer: {
			tooltip: 'Ընտրե՛ք ճիշտ պատասխանը',
			inputPlaceholder: 'Պատասխան',
		},
		results: {
			text: 'Քվեարկության արդյունքներ',
			// words: ['ձայն', 'ձայն', 'ձայն'],
			votes: 'Ձայն',
		},
		btns: {
			addQuestion: 'Ավելացնել հարց',
			addAnswers: 'Ավելացնել պատասխաններ',
			addAnswer: 'Ավելացնել պատասխան',
			publish: 'Հրապարակել',
			vote: 'Քվերակե՛ք',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: 'Գնահատել',
				continue: 'Շարունակել',
				checkConnection: 'Ստուգել միացումը',
				close: 'Փակել',
			},
			quality: {
				title: 'Գնահատել ձայնի որակը',
			},
			reason: {
				title: 'Ինչ-որ բան այն չգնա՞ց',
			},
			connection: {
				title: 'Փորձարկե՛ք միացման որակը',
				text: 'Ստուգումը կտևի մի րոպեից ոչ ավել և կօգնի գտնել խնդրի պատճառը',
			},
			statistics: {
				title: 'Հավաքում ենք վիճակագրություն',
			},
			done: {
				title: 'Շնորհակալություն!',
				text: 'Մենք կուսումնասիրենք՝ ինչը այն չգնաց, և անպայման կուղղենք',
			},
		},
	},
	common: {
		admin: 'ադմին',
		userName: 'օգտատերը ջնջված է',
		sticker: 'կպչուն',
		name: 'Իմյա',
	},
	chatBot: {
		message: 'Հարցրեք բոտին ինչ-որ բանի մասին...',
		warnings: {
			disabled: 'Ինձ անջատել են',
			overloaded: 'Մենք ծանրաբեռնված ենք',
		},
	},
	audioReason: {
		nothear: 'Ոչինչ չէր լսվում',
		connection: 'Ձայնն ընդհատվում էր',
		microphone: 'Չստացվեց օգտվել բարձրախոսից',
	},
	copybetting: {
		orderStatus: {
			won: 'Շահած',
			lost: 'Պարտված',
			new: 'Նոր',
			pending: 'Ընթացքում',
			cashedOut: 'Ելքագրված',
			boreDraw: 'Առանց գոլի ոչ ոքի',
			cashback: 'Քեշբեք',
			megaBetNew: 'ՄեգաԲեթ Նոր',
			megaBetCanceledByOperator: 'ՄեգաԲեթ Օպերատորի կողմից չեղարկված',
			megaBetCanceledByPlayer: 'ՄեգաԲեթ Խաղացողի կողմից չեղարկված',
			megaBetCanceledBySystem: 'ՄեգաԲեթ համակարգի կողմից չեղարկված',
			betGamesPending: 'ԲեթԳեյմս ընթացքում',
			betGamesWon: 'ԲեթԳեյմս շահած',
			betGamesLost: 'ԲեթԳեյմս պարտված',
			paid: 'Վճարված',
			initialWin: 'Նախնական հաղթանակ',
			autoBetCanceledBySystem: 'ԱվտոԲեթ համակարգի կողմից չեղարկված',
			autoBetCanceledByPlayer: 'ԱվտոԲեթ խաղացողի կողմից չեղարկված',
			autobetPending: 'ԱվտոԲեթ Ընթացքում',
			goalAhead: 'Կլինի գոլ',
			canceled: 'չեղարկված',
			return: 'Վերադարձ',
		},
		stakeStatus: {
			pending: 'Սպասվում է',
			won: 'շահումներ',
			lost: 'Կորցնելով',
			return: 'Վերադառնալ',
			lostReturn: 'Կորցնելով | Վերադառնալ',
			wonReturn: 'շահումներ | Վերադառնալ',
			canceled: 'չեղարկված',
			initialReturn: 'նախնական վերադարձ',
			pendingInitialReturn: 'Սպասվում է | նախնական վերադարձ',
			wonInitialReturn: 'շահումներ | նախնական վերադարձ',
		},
	},
	screenShare: {
		title: 'Կիսվեք էկրանով',
		stopSharing: 'Դադարեցնել էկրանը',
		modal: {
			title: 'Դուք կարող եք կիսել էկրանը միայն համակարգչից:',
			text: `Բացեք շատախոսը ձեր համակարգչում և սեղմեք 'Կիսվեք էկրանով' կոճակը վերևում աջ վերին անկյունում։`,
			btn: 'Պատճենել հրավերի հղումը։',
		},
	},
	threads: {
		title: 'Թեմա',
		back: 'Հետ',
		replies: ['Պատասխան', 'Պատասխաններ', 'Պատասխաններ'],
	},
	attach: {
		submenu: {
			sendImage: `Նկարը ուղարկել`,
			shareBet: 'Կիսվել խաղադրույքով',
			shareGamble: 'Կիսվել շահումով',
		},
		images: ['նկար', 'նկարներ', 'նկարների'],
		addCaption: 'Ավելացնել ստորագրություն',
	},
} as unknown as Dictionary;
